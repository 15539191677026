import React, { useState, useContext } from 'react'
import axios from 'axios'
import { LinearProgress, Box } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { apiRoute } from '../../../App';
import UserContext from '../../../context/UserContext';

const DisbursementViewTable = ({ disburseDates, setDisburseDates, loadingDates, setLoadingDates, getDisburseReconDates, from, setFrom, setOpenModal, retrievedData, setRetrievedData }) => {
    const [selectedDate, setSelectedDate] = useState(false);
    const formattedDefaultDate = new Date().toISOString().split('T')[0];
    const [checkDate, setCheckDate] = useState(formattedDefaultDate);
    const [checkNumber, setCheckNumber] = useState(0);

    const { entityId } = useContext(UserContext)


    const previousDisbursedColumns = [
        {
            field: "Time",
            width: 320
        },
        {
            field: "Date",
            headerName: "Date",
            width: 300
        },
        {
            field: "EnteredBy",
            headerName: "Disbursed By",
            width: 300
        }
    ];



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
                <div style={{ flex: 1 }} />
                <GridToolbarQuickFilter />
            </GridToolbarContainer>
        )
    }

    const retrieveDisburseDetails = async (value) => {
        setSelectedDate(value);
        await axios.get(`${apiRoute}/api/DisburseDtl/retrieveDisburseDetails/${value}`)
            .then(({ data }) => {
                data.forEach((d) => {
                    const disburseDate = new Date(d.disburseDate);
                    const formattedDate = (disburseDate.getMonth() + 1).toString().padStart(2, '0') + '/' + disburseDate.getDate().toString().padStart(2, '0') + '/' + disburseDate.getFullYear();
                    d.id = crypto.randomUUID();
                    d.totalDisburseAmount = (d.totalDisburseAmount).toFixed(2);
                    d.grandTotal = (parseFloat(d.totalDisburseAmount) + d.bankInterest + (d.allocations + d.deductions)).toFixed(2);
                    d.entityId = entityId;
                    d.bankInterest = d.bankInterest.toFixed(2)
                    d.disbursedDate = formattedDate;
                    d.allocatedAmount = (d.allocations + d.deductions).toFixed(2);
                })
                setRetrievedData(data)
            })
            .then((data) => { setFrom('retrieved'); setOpenModal(true) })
            .catch(e => console.error(e));
    }

    return (
        <Box sx={{ height: '55vh' }}>
            <DataGrid
                rows={disburseDates}
                columns={previousDisbursedColumns.map((column, index) => ({
                    ...column,
                    headerClassName: index === 0 ? 'first-column-header' : '',
                    cellClassName: index === 0 ? 'first-column-cell' : '',
                }))}
                loading={loadingDates}
                disableMultipleRowSelection
                onRowClick={(p) => retrieveDisburseDetails(p.row.Value)}
                density="compact"
                initialState={{
                    sorting: { sortModel: [{ field: 'date', sort: 'desc' }] },
                    pagination: { paginationModel: { pageSize: 25 } }
                }}
                slots={{
                    toolbar: CustomToolbar,
                    loadingOverlay: LinearProgress,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                localeText={{
                    noRowsLabel:
                        'No Disbursements Found',
                }}
                rowHeight={60}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    maxHeight: '55vh',
                    '.first-column-header': {
                        paddingLeft: '3em', // Adjust the padding value as needed
                    },
                    '.first-column-cell': {
                        paddingLeft: '3em', // Adjust the padding value as needed
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                }}
            />
        </Box>
    )
}

export default DisbursementViewTable;