import {
    Box,
    Button,
    Card,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from 'react-router-dom';

const OtpVerificationForm = ({
  otp,
  setOtp,
  confirmOtp,
  tryAgain,
  disableTryAgainButton,
  handleCancel
}) => {
  const [error, setError] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (otp.length === 6) {
      confirmOtp();
    } else {
      setError("Please enter valid 6-digit codes");
    }
  };

  return (
    <Card sx={{ padding: 4, textAlign: "center" }}>
      <Typography variant="h5" mb={2}>
        Enter Verification Codes
      </Typography>
      <Typography variant="body1" mb={4}>
        We have sent a verification code to your Email & Phone Number
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box mb={2}>
          <TextField
            label="Enter the 6-digit code that was sent"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            variant="outlined"
            fullWidth
            required
          />
        </Box>
        {error && (
          <Typography color="error" mb={2}>
            {error}
          </Typography>
        )}
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Verify
        </Button>
      </form>

      <Box mt={2}>
        {disableTryAgainButton ? (
          <Tooltip title={"Please wait for 1 minute before trying again"}>
            <Link
              disabled={true}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              Try again
            </Link>
          </Tooltip>
        ) : (
          <Link
            onClick={tryAgain}
            style={{ color: "inherit", textDecoration: "inherit" }}
          >
            Try again
          </Link>
        )}{" "}
        or{" "}
        <Link
          onClick={handleCancel}
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          Cancel
        </Link>
          </Box>
    </Card>
  );
};

export default OtpVerificationForm;
