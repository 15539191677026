import React, { useContext } from 'react';

import { MenuItem, Box, Button, TextField, Paper } from '@mui/material';
import { BsArrowCounterclockwise } from 'react-icons/bs';

import { BatchContext } from '../../../context/BatchMgmtContext.js';

const BatchSearch = (props) => {
    const { excludeDate,  resetSearch, ts, setRowSelected } = props;
    const {
        date,
        setDate,
        users,
        setUsers,
        batchStatus,
        setBatchStatus,
        batchNumber,
        setBatchNumber,
        onSubmitHandler,
        userOptions,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    } = useContext(BatchContext);

    const batchNumChange = (e) => {
        const value = e.target.value;
        if (/^[a-zA-Z0-9]*$/.test(value)) {
            setBatchNumber(value);
        }
    };

    return (
        <form>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', mb: '1vh' }}>
                <Paper elevation={10}>
                    <TextField
                        fullWidth
                        sx={ts}
                        type="date"
                        id="created-date"
                        label="Date Created"
                        value={date}
                        onChange={e => {
                            setDate(e.target.value);
                            //setDateValue(e.target.value);
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={excludeDate}
                    />
                </Paper>
                <Paper elevation={10}>
                    <TextField
                        fullWidth
                        sx={ts}
                        type="date"
                        id="start-date"
                        label="Start Date"
                        value={startDate}
                        onChange={e => {
                            setStartDate(e.target.value);
                            //setDateValue(e.target.value);
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={excludeDate}
                    />
                </Paper>
                <Paper elevation={10}>
                    <TextField
                        fullWidth
                        sx={ts}
                        type="date"
                        id="end-date"
                        label="End Date"
                        value={endDate}
                        onChange={e => {
                            setEndDate(e.target.value);
                            //setDateValue(e.target.value);
                        }}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        disabled={excludeDate}
                    />
                </Paper>
                <Paper elevation={10}>
                    <TextField
                        sx={{ ...ts, width: "11vw" }}
                        id="users"
                        label="Users"
                        name="users"
                        value={users}
                        onChange={(e) => setUsers(e.target.value)}
                        select
                        fullWidth
                        variant="outlined"
                    >
                        {
                            userOptions?.map(({ name }) => {
                                return <MenuItem key={name} value={name}>{name}</MenuItem>
                            })
                        }
                        <MenuItem value='allUsers'>All Users</MenuItem>
                    </TextField>
                </Paper>
                {/*<Paper elevation={10}>*/}
                {/*    <TextField*/}
                {/*        sx={{ ...ts, width: "11vw" }}*/}
                {/*        id="batchType"*/}
                {/*        label="Type"*/}
                {/*        name="batchType"*/}
                {/*        value={batchType}*/}
                {/*        onChange={(e) => setBatchType(e.target.value)}*/}
                {/*        select*/}
                {/*        fullWidth*/}
                {/*        variant="outlined"*/}
                {/*    >*/}
                {/*        <MenuItem value="ALL">All</MenuItem>*/}
                {/*        <MenuItem value="SIMPLE">Simple</MenuItem>*/}
                {/*        <MenuItem value="ONLINE">Online</MenuItem>*/}
                {/*        <MenuItem value="REFUND">Refund</MenuItem>*/}
                {/*    </TextField>*/}
                {/*</Paper>*/}
                <Paper elevation={10}>
                    <TextField
                        sx={{ ...ts, width: "11vw" }}
                        id="batchStatus"
                        label="Status"
                        name="batchStatus"
                        value={batchStatus}
                        onChange={(e) => setBatchStatus(e.target.value)}
                        select
                        fullWidth
                        variant="outlined"
                    >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="settled">Settled</MenuItem>
                        <MenuItem value="deposited">Deposited</MenuItem>
                    </TextField>
                </Paper>
                <Paper elevation={10}>
                    <TextField
                        fullWidth
                        sx={ts}
                        id="batchNumber"
                        label="Batch #"
                        value={batchNumber}
                        onChange={batchNumChange}
                        variant="outlined"
                    />
                </Paper>
                <Paper elevation={10}>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={(e) => { onSubmitHandler(e); setRowSelected(false); }}>Search</Button>
                </Paper>
                <Paper elevation={10}>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={resetSearch}>
                        <BsArrowCounterclockwise />&nbsp; Reset
                    </Button>
                </Paper>
            </Box>
        </form>
    )
}

export default BatchSearch;