import * as yup from 'yup';

export const SentencingValidations = {
    daysInLieu: yup
        .number()
        .min(0, 'Days In Lieu must be greater than or equal to 0'),
    timeServed: yup
        .number()
        .min(0, 'Time Served must be greater than or equal to 0')
        .max(yup.ref('daysInLieu'), 'Cannot serve more time than the allotted Days In Lieu'),
    sentenceDate: yup.date(),
    finalDisposition: yup.string(),
    yearsSentenced: yup.number().min(0),
    monthsSentenced: yup.number().min(0),
    daysSentenced: yup.number().min(0),
    hoursSentenced: yup.number().min(0),
    sentenceStartDate: yup.date().min(yup.ref('sentenceDate'), 'Sentence Start Date cannot be before Sentence Date'),
    fineAmount: yup.number().min(0),
    suspendedFineAmount: yup.number().min(0),
    costAmount: yup.number().min(0),
    suspendedCostAmount: yup.number().min(0),
    yearsSuspended: yup.number().min(0),
    monthsSuspended: yup.number().min(0),
    daysSuspended: yup.number().min(0),
    hoursSuspended: yup.number().min(0),
    yearsProbation: yup.number().min(0),
    monthsProbation: yup.number().min(0),
    daysProbation: yup.number().min(0),
    hoursProbation: yup.number().min(0),
    yearsCommService: yup.number().min(0),
    monthsCommService: yup.number().min(0),
    daysCommService: yup.number().min(0),
    hoursCommService: yup.number().min(0),
}