import React, { useState, useContext } from 'react';
import axios from 'axios';

import { Box, Button, Modal, Paper, Tooltip } from '@mui/material';

import { toast } from 'react-toastify';

import BondsModals from '../../components/modals/views/BondsModals';
import BondsTable from '../../components/tables/views/bondsTab/BondsTable';

import { apiRoute } from '../../App';
import { CaseContext } from '../../context/CaseContext';
import { BatchContext } from '../../context/BatchMgmtContext';


const BondsView = (props) => {
    const { updateCaseBalance, ts } = props;
    const { bondRows, setBondRows, autoBondNum, violationRows, balance } = useContext(CaseContext);
    const { openBatches, setPaymentBatchNumber } = useContext(BatchContext);

    const [selectedBond, setSelectedBond] = useState({});
    const [newBatch, setNewBatch] = useState({});

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [modalWidth, setModalWidth] = useState('');
    const [checkInfo, setCheckInfo] = useState({});
    const [bondNumber, setBondNumber] = useState('');

    const handleOpen = async (type, width) => {
        if (openBatches.length > 0) {
            setModalType(type);
            setModalWidth(width);
            setModal(true);
        } else {
            try {
                const newBatchRes = await axios.get(`${apiRoute}/api/batchtbl/newestbatch`);
                setNewBatch(newBatchRes.data);
                setModalType('openBatch');
                setModalWidth('25%');
                setModal(true);
            } catch (err) {
                console.log('error getting newBatch', err);
            }
        }
    };
    //const handleOpen = (type, width) => {
    //    setModalType(type);
    //    setModalWidth(width);
    //    setModal(true);
    //};

    const handleClose = () => {
        setModal(false);
        setModalType('');
        setModalWidth('');
    };

    const getCheckInfo = (e, id) => {
        e.preventDefault();
        axios.get(`${apiRoute}/api/ffchecktbls/${id}`)
            .then((res) => {
                setCheckInfo(res.data);
                handleOpen('checksView', '50%');
            })
            .catch((err) => {
                handleOpen('checks', '50%');
                toast.info(`No check was created for this bond yet. Please create a check for this bond using this form.`, {
                    position: "top-right",
                    autoClose: 7000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.log(err);
            });
    };

    const toolTipText = violationRows.length < 1 ? "Can't add a bond with no violations on the case" : balance === 0.00 ? 'No violations with outstanding balances' : '';
    const disableToolTip = balance === 0.00 ? false : violationRows.length > 0

    return (
        <Box sx={{ height: '100%', width: '99%', m: '1vh auto' }}>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                    <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Cash Bonds</h6>
                </Paper>
                <Box sx={{ mt: '1vh' }}>
                    <Paper
                        sx={{
                            width: '99%',
                            margin: '0 auto',
                            minHeight: '21.8vh',
                            maxHeight: "46.5vh",
                        }}
                        elevation={10}
                    >
                        <BondsTable
                            handleOpen={handleOpen}
                            setSelectedBond={setSelectedBond}
                            bondRows={bondRows}
                            getCheckInfo={getCheckInfo}
                            setNewBatch={setNewBatch}
                        />
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto', pb: '1vh' }}>
                        <Tooltip
                            title={toolTipText}
                            disableHoverListener={disableToolTip}
                            placement="top"
                        >
                            <span style={{ width: '100%' }}>
                                <Button
                                    onClick={async () => {
                                        if (autoBondNum) {
                                            axios.get(`${apiRoute}/api/cashbonds/newestbond`)
                                                .then(res => {
                                                    setBondNumber(res.data);
                                                    handleOpen('form', '45%')
                                                })
                                        } else {
                                            handleOpen('form', '45%')
                                        }
                                    }}
                                    variant='contained'
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                    disabled={violationRows.length < 1 || balance === 0.00}
                                >
                                    + Add Bond
                                </Button>
                            </span>
                        </Tooltip>
                        <Modal open={modal} sx={{ width: modalWidth, margin: "2vh auto" }}>
                            <>
                                <BondsModals
                                    handleClose={handleClose}
                                    modalType={modalType}
                                    ts={ts}
                                    selectedBond={selectedBond}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    updateCaseBalance={updateCaseBalance}
                                    handleOpen={handleOpen}
                                    checkInfo={checkInfo}
                                    bondNumber={bondNumber}
                                    newBatch={newBatch}
                                />
                            </>
                        </Modal>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default BondsView;