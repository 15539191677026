import React, { useContext, useEffect } from 'react';

import { Box, CircularProgress } from '@mui/material'

import NavMenu from './navbar/NavMenu';
import UserContext from '../context/UserContext';

import '../css/custom.css'

const Layout = ({ children, setUtilInfo }) => {
    const { loading, fetchUserData } = useContext(UserContext);

    useEffect(() => {
        fetchUserData();
    }, []);

    if (loading) {
        return (
            <Box sx={{ width: "5%", margin: "15vh auto" }}>
                <CircularProgress />
            </Box>
        )
    };

    return (
        <div>
            <NavMenu setUtilInfo={setUtilInfo} />
            <div className="page">
                {children}
            </div>
        </div>
    );
}

export default Layout;