import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Checkbox,
    LinearProgress,
    Modal,
} from '@mui/material';

import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarDensitySelector,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';

import { toast } from 'react-toastify';
import '../css/custom.css';
import { apiRoute } from '../App';
import DesotoPaymentForm from '../components/modals/views/DesotoPaymentModal';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DesotoHome = (props) => {
    const {
        ts,
    } = props;

    const [rows, setRows] = useState([])
    const [selectedRow, setSelectedRow] = useState({})
    const [modal, setModal] = useState(false)

    const [approvedPayments, setApprovedPayments] = useState([]);
    const [bulkAccepting, setBulkAccepting] = useState(false);

    const reviewPayment = (id) => {
        axios.get(`${apiRoute}/api/desotopayment/${id}`)
            .then(({ data }) => {
                setSelectedRow(data)
                setModal(true)
            })
            .catch(err => console.log(err))
    }
    const columns = [
        {
            field: 'process',
            headerName: 'Process',
            width: 120,
            renderCell: (params) =>
                <Button
                    onClick={() => reviewPayment(params.row.id)}
                >
                    Review
                </Button>
        },
        {
            field: 'paymentApproved',
            headerName: 'Approve Payment',
            type: 'checkbox',
            width: 120,
            renderHeader: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '-20px' }}>Approve</div>
                    <div>Payment</div>
                </div>
            ),
            renderCell: (params) => {
                return (
                    <Checkbox
                        checked={approvedPayments.find(payment => payment.id === params.row.id)?.isChecked === 'Y'}
                        onChange={(e) => { handleCheckboxChange(params.row.id, e.target.value, params); }}
                    />
                )
            },
        },
        {
            field: 'datePaid',
            headerName: 'Date Paid',
            type: 'datetime',
            width: 120,
            valueFormatter: ({ value }) => {
                if (value === undefined || value === null) return "";
                return new Date(value).toISOString().substring(0, 10);
            },
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 150
        },
        {
            field: 'fullName',
            headerName: 'Full Name',
            width: 150,
            valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 180
        },
        {
            field: 'channel',
            headerName: 'Channel',
            width: 120
        },
        {
            field: 'citNum',
            headerName: 'ID',
            width: 150
        },
        {
            field: 'courtDate',
            headerName: 'Court Date',
            type: 'datetime',
            width: 150,
            valueFormatter: ({ value }) => {
                if (value === undefined || value === null) return "";
                return new Date(value).toISOString().substring(0, 10);
            },
        },
        {
            field: 'status',
            headerName: 'Current Status',
            width: 120,
            renderHeader: (params) => (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '-20px' }}>Current</div>
                    <div>Status</div>
                </div>
            ),
        },
        {
            field: 'amount',
            headerName: 'Amount',
            type: 'number',
            align: 'right',
            width: 150,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            }
        },
    ];

    useEffect(() => {
        axios.get(`${apiRoute}/api/DesotoPayment`)
            .then(({ data }) => {
                data.forEach(d => d.isChecked === 'N');
                setRows(data);
            })
    }, [])

    const handleCheckboxChange = (id, val, p) => {
        const existingIndex = approvedPayments.findIndex(payment => payment.id === id);
        const payment = rows.find(r => r.id === id);

        if (existingIndex === -1) {
            // If payment is not found, add it to the approvedPayments array
            setApprovedPayments([...approvedPayments, payment]);
            payment.isChecked = 'Y';
        } else {
            // If payment is found, remove it from the approvedPayments array
            const updatedPayments = [...approvedPayments];
            updatedPayments.splice(existingIndex, 1);
            setApprovedPayments(updatedPayments);
            payment.isChecked = 'N';
        }
    };

    const bulkApprove = async () => {
        setBulkAccepting(true);
        try {
            const post = await axios.post(`${apiRoute}/api/desotoPayment/BulkApproval`, approvedPayments);
            setApprovedPayments([]);
            toast.success(`Bulk Acceptance Successful!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setBulkAccepting(false);
        } catch (err) {
            toast.error(`Error during the acceptance process: ${err.response.data}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setBulkAccepting(false);
        }
        await axios.get(`${apiRoute}/api/DesotoPayment`)
            .then(({ data }) => {
                setRows(data);
            })
    }

    const notProcessedPayments = rows.filter((row) => row.status === "New" || row.status === "Pending");
    const amountNotProcessedPayments = notProcessedPayments.reduce((total, row) => total + row.amount, 0);

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector
                    slotProps={
                        {
                            tooltip: {
                                title: 'Change density'
                            }
                        }
                    }
                />
                <GridToolbarExport
                    slotProps={
                        {
                            tooltip: {
                                title: 'Export data'
                            },
                            button: {
                                variant: 'outlined'
                            },
                        }
                    }
                />
                <Button
                    onClick={
                        () => {
                            setApprovedPayments(rows.filter(r => r.status === 'New').map((r => ({ ...r, isChecked: 'Y' }))))
                        }
                    }
                >
                    Select All Payments
                </Button>
                <Box
                    sx={
                        {
                            flexGrow: 1
                        }
                    }
                />
                <GridToolbarQuickFilter />
            </GridToolbarContainer >
        );
    }

    return (
        <div>
            <div
                style={
                    {
                        display: 'flex',
                        justifyContent: 'space-between'
                    }
                }
            >
                <div
                    style={
                        {
                            textAlign: 'left',
                            pl: '2vw'
                        }
                    }
                >
                    Selected Payments: <strong>{approvedPayments.length}</strong>
                    <br />
                    <Button
                        variant="contained"
                        sx={
                            {
                                background: 'steelblue',
                                color: 'white'
                            }
                        }
                        onClick={async () => {
                            await bulkApprove();
                        }}
                        disabled={bulkAccepting}
                    >
                        Approve Selected Payments</Button>
                </div>
                <div
                    style={
                        {
                            textAlign: 'right'
                        }
                    }
                >
                    <div style={
                        {
                            marginBottom: '10px'
                        }
                    }
                    >
                        <span>Amount of Payments Not Yet Processed: {notProcessedPayments.length}</span>
                    </div>
                    <div
                        style={
                            {
                                marginBottom: '10px'
                            }
                        }
                    >
                        <span>
                            Total Amount Not Yet Processed:&nbsp;
                            {
                                new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    maximumFractionDigits: 2
                                }).format(amountNotProcessedPayments)
                            }
                        </span>
                    </div>
                </div>
            </div>
            <div
                style={
                    {
                        height: '80vh',
                        width: '100%'
                    }
                }
            >
                <DataGrid
                    rows={rows}
                    loading={!rows || bulkAccepting}
                    columns={columns}
                    pageSize={5}
                    disableSelectionOnClick
                    density="compact"
                    initialState={
                        {
                            pagination: {
                                paginationModel: {
                                    pageSize: 25
                                }
                            },
                            filter: {
                                filterModel: {
                                    items: [
                                        {
                                            field: 'status',
                                            operator: 'contains',
                                            value: 'New'
                                        }
                                    ],
                                },
                            },
                        }
                    }
                    slots={
                        {
                            toolbar: CustomToolbar,
                            loadingOverlay: LinearProgress,
                        }
                    }
                    slotProps={
                        {
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: {
                                    debounceMs: 500
                                },
                                printOptions: {
                                    disableToolbarButton: true
                                },
                            },
                        }
                    }
                />
            </div>
            <Modal
                open={modal}
                onClose={() => setModal(false)}
                sx={
                    {
                        width: "65%",
                        height: "auto",
                        py: "4px",
                        m: "2vh auto",
                        "& > .MuiBackdrop-root": {
                            backdropFilter: 'blur(5px)'
                        }
                    }
                }
            >
                <>
                    <DesotoPaymentForm
                        rows={rows}
                        setRows={setRows}
                        handleClose={() => setModal(false)}
                        textFieldStyle={ts}
                        selectedPayment={selectedRow}
                    />
                </>
            </Modal>
            
        </div >
    );
};

export default DesotoHome;
