import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { apiRoute } from '../App.js';

const GeneralLedgerContext = createContext({
    glAccounts: [],
    setGlAccounts: () => { },
    loadingGlAccounts: Boolean,
    noResults: Boolean,
    getGlAccounts: () => { }
});

const GeneralLedgerProvider = ({ children }) => {
    const [glAccounts, setGlAccounts] = useState([]);
    const [loadingGlAccounts, setLoadingGlAccounts] = useState(false);
    const [noResults, setNoResults] = useState(false);


    const getGlAccounts = () => {
        setLoadingGlAccounts(true);
        setNoResults(false);

        axios.get(`${apiRoute}/api/GlAccountsTbls`)
            .then(({ data }) => {
                setGlAccounts(
                    data.map((account) => ({
                        ...account,
                        id: account.pkAccountId
                    }))
                );
                setLoadingGlAccounts(false)
            })
            .catch((err) => {
                setLoadingGlAccounts(false);
                setNoResults(true);
                console.log(err, 'err getting GlAccounts')
            })
    }

    useEffect(() => {
        getGlAccounts();
    }, []);

    const contextValue = {
        glAccounts,
        setGlAccounts,
        loadingGlAccounts,
        setLoadingGlAccounts,
        noResults,
        getGlAccounts
    }

    return <GeneralLedgerContext.Provider value={contextValue}>{children}</GeneralLedgerContext.Provider>
};

export default GeneralLedgerProvider;
export { GeneralLedgerContext }