import React, { useContext, useState } from 'react';
import axios from 'axios';

import { Button, Menu, MenuItem, Tooltip, Box } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DataGrid } from '@mui/x-data-grid';

import { formatDate } from '../../../../Utils';
import { apiRoute } from '../../../../App';

import { BatchContext } from '../../../../context/BatchMgmtContext';
import UserContext from '../../../../context/UserContext';
import { CaseContext } from '../../../../context/CaseContext';

const BondsTable = (props) => {
    const {
        handleOpen,
        setSelectedBond,
        bondRows,
        getCheckInfo,
        setNewBatch
    } = props;

    const { openBatches } = useContext(BatchContext);
    const { supervisorCheck,  } = useContext(UserContext);
    const { violationRows } = useContext(CaseContext)

    const [anchorEl, setAnchorEl] = useState({});

    const batchCheck = () => {
        if (openBatches.length < 1) {
            axios.get(`${apiRoute}/api/batchtbl/newestbatch`)
                .then((res) => {
                    setNewBatch(res.data);
                })
                .then(() => {
                    handleOpen('openBatch', '25%');
                })
                .catch(err => console.log(err));
        } else {
            handleOpen('apply', '45%');
        }
    };

    const handleClick = (e, rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: e.currentTarget
        }));
    };

    const handleClose = (rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: null
        }));
    };

    const getRow = (params) => {
        setSelectedBond(params.row)
    };

    const columns = [
        {
            field: 'bondType',
            headerName: 'Bond Type',
            headerAlign: 'center',
            align: 'center',
            width: 145,
        },
        {
            field: 'bondNumber',
            headerName: 'Bond #',
            headerAlign: 'center',
            align: 'center',
            width: 125,
        },
        {
            field: 'bondDate',
            headerName: 'Bond Date',
            headerAlign: 'center',
            align: 'center',
            width: 150,
            renderCell: (params) => {
                return (
                    <>{params.row.bondDate ? formatDate(params.value.split('T')[0]) : ''}</>
                )
            }
        },
        {
            field: 'bondAmount',
            headerName: 'Bond Amount',
            headerAlign: 'right',
            align: 'right',
            type: 'number',
            width: 150,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            },
        },
        {
            field: 'receiptNum',
            headerName: 'Receipt #',
            headerAlign: 'center',
            align: 'center',
            width: 160,
            editable: true
        },
        {
            field: 'postedBy',
            headerName: 'Posted By',
            headerAlign: 'center',
            align: 'center',
            width: 200,
            renderCell: (params) => {
                return (
                    <>{params.row.postedLast}, {params.row.postedFirst}</>
                )
            }
        },
        {
            field: 'statute',
            headerName: 'Statute',
            headerAlign: 'center',
            align: 'center',
            type: 'number',
            width: 125,
            renderCell: (params) => {
                return (<>{violationRows.filter(v => v.pkViolationId === params?.row?.violationIdFk)[0]?.currentStatute}</>)
            }
        },
        {
            field: 'appliedToCaseDate',
            headerName: 'Apply Date',
            headerAlign: 'center',
            align: 'center',
            width: 175,
            renderCell: (params) => {
                return (
                    <>{params.row.appliedToCaseDate ? formatDate(params.row.appliedToCaseDate) : ''}</>
                )
            }
        },
        {
            field: 'reimbursementDate',
            headerName: 'Refund Date',
            headerAlign: 'center',
            align: 'center',
            width: 175,
            renderCell: (params) => {
                return (
                    <>{params.row.reimbursementDate ? formatDate(params.row.reimbursementDate) : ''}</>
                )
            }
        },
        { 
            field: 'bondCheck',
            headerName: 'Bond Checks',
            headerAlign: 'center',
            align: 'center',
            width: 135,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                return (
                    params.row.bondType !== 'REFUND' && (
                        <>
                            <Tooltip
                                title='Checks only available for refunded bonds'
                                disableHoverListener={!!params.row.reimbursementDate}
                                placement="left-start"
                            >
                                <span style={{ width: '100%' }}>
                                    <Button
                                        fullWidth
                                        onClick={(e) => getCheckInfo(e, params.row.id)}
                                        variant="contained"
                                        sx={{ backgroundColor: "steelblue", color: 'white' }}
                                        disabled={!params.row.reimbursementDate}
                                    >
                                        View Check
                                    </Button>
                                </span>
                            </Tooltip>
                        </>
                    )
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Bond Actions',
            headerAlign: 'center',
            align: 'center',
            width: 135,
            disableColumnFilter: 'true',
            disableColumnMenu: 'true',
            disableSort: 'true',
            sortComparator: () => {
                return 0;
            },
            renderCell: (params) => {
                const disable = params.row.appliedToCaseDate || params.row.reimbursementDate ? true : false;
                return (
                    params.row.bondType !== 'REFUND' && (
                        <>
                            <Button
                                id="bond-actions"
                                aria-controls={anchorEl[params.row.id] ? 'bond-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={anchorEl[params.row.id] ? 'true' : undefined}
                                onClick={(e) => handleClick(e, params.row.id)}
                                variant="contained"
                                sx={{ fontSize: '20px', p: '15px', backgroundColor: "steelblue", color: 'white'}}
                                fullWidth
                                disabled={!!params.row.reimbursementDate}
                            >
                                <SettingsIcon />
                            </Button>
                            <Menu
                                id='bond-menu'
                                anchorEl={anchorEl[params.row.id]}
                                open={Boolean(anchorEl[params.row.id])}
                                onClose={() => handleClose(params.row.id)}
                                MenuListProps={{
                                    'aria-labelledby': 'bond-actions',
                                }}
                            >
                                <MenuItem>
                                    <Tooltip
                                        title={<h6>Bond already applied</h6>}
                                        disableHoverListener={!params.row.appliedToCaseDate}
                                        placement="left"
                                    >
                                        <span style={{ width: '100%' }}>
                                            <Button
                                                fullWidth
                                                onClick={(e) => {
                                                    batchCheck();
                                                    handleClose(params.row.id);
                                                }}
                                                variant="contained"
                                                sx={{ backgroundColor: "steelblue", color: 'white' }}
                                                disabled={disable}
                                            >
                                                Apply Bond
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem>
                                    <Tooltip
                                        title={
                                            <>
                                                {
                                                    !!params.row.appliedToCaseDate && (
                                                        <h6>Bond Already Applied</h6>
                                                    )
                                                }
                                                {
                                                    !supervisorCheck && (
                                                        <h6>Supervisor or Admin only</h6>
                                                    )
                                                }
                                                {
                                                    !!params.row.reimbursementDate && (
                                                        <h6>Bond Already Refunded</h6>
                                                    )
                                                }
                                            </>
                                        }
                                        placement="left"
                                    >
                                        <span style={{ width: '100%' }}>
                                            <Button
                                                fullWidth
                                                onClick={(e) => {
                                                    handleOpen('refund', '35%');
                                                    handleClose(params.row.id);
                                                }}
                                                variant="contained"
                                                sx={{ backgroundColor: "steelblue", color: 'white' }}
                                                disabled={!supervisorCheck || disable}
                                            >
                                                Refund Bond
                                            </Button>
                                        </span>
                                    </Tooltip>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        fullWidth
                                        onClick={(e) => {
                                            handleOpen('edit', '50%');
                                            handleClose(params.row.id);
                                        }}
                                        variant="contained"
                                        sx={{ backgroundColor: "steelblue", color: 'white' }}
                                    >
                                        Edit Bond
                                    </Button>
                                </MenuItem>
                                <MenuItem>
                                    <Button
                                        fullWidth
                                        onClick={(e) => {
                                            handleOpen('delete', '45%');
                                            handleClose(params.row.id);
                                        }}
                                        variant="contained"
                                        sx={{ backgroundColor: "steelblue", color: 'white' }}
                                        disabled={disable}
                                    >
                                        Delete Bond
                                    </Button>
                                </MenuItem>
                            </Menu>
                        </>
                    )
                )
            }
        },
    ];

    const height = bondRows.length === 0 ? '21.8vh' : '';

    return (
        <Box sx={{ height: height }}>
            <DataGrid
                rows={bondRows}
                columns={columns}
                disableMultipleRowSelection
                onRowClick={getRow}
                density="compact"
                initialState={{
                    pagination: { paginationModel: { pageSize: 25 } },
                }}
                pageSizeOptions={[25, 50, 100]}
                localeText={{
                    noRowsLabel:
                        'No bonds, click Add Bond to add a new bond',
                }}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    minHeight: '21.8vh',
                    maxHeight: '46.5vh',
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                }}
            />
        </Box>
    )
}

export default BondsTable;