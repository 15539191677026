import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

import { Box, LinearProgress, Icon, Card, Button, TextField, IconButton, CardActions, InputAdornment } from '@mui/material';

import Mail from '@mui/icons-material/Mail';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import * as yup from 'yup';
import { withFormik } from 'formik';
import loginValidations from '../../schemas/LoginSchema';

import Logo from "../../images/iss.png";
import BG from "../../images/LoginPng.png";

import { ThemeModeContext, apiRoute, frontendRoute } from '../../App';
import OtpVerificationForm from './OtpVerificationForm';

const Login = (props) => {
    const { mode } = useContext(ThemeModeContext);

    const [error, setError] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loadingLogin, setLoadingLogin] = useState(false);
    const [showOtp, setShowOtp] = useState(false);
    const [userId, setUserId] = useState(0);
    const [otp, setOtp] = useState("");
    const [disableTryAgainButton, setDisableTryAgainButton] = useState(false);
    const tryAgainButtonTime = 60000;

    const navigate = useNavigate();

    const forgotPasswordRoute = `${frontendRoute}/forgotpassword`;

    const handleCancel = () => {
        setError("")
        setShowOtp(false);
      };

    const login = async (e) => {
        e.preventDefault();
        try {
            setLoadingLogin(true);
            const res = await axios.post(`${apiRoute}/api/auth/login`, values);
            if(!res.data.isMFAEnabled){
                const token = res?.data?.token
                localStorage.setItem('token', token);
                // Token is valid, set it as a default Authorization header for axios
                axios.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                };
                setLoadingLogin(false);
                navigate('/');
            }else{
                setUserId(res?.data.userId);
                setLoadingLogin(false);
                setShowOtp(true);
            }
        } catch (err) {
            console.log('Login Failed', err);
            setLoadingLogin(false);
            setError(err?.response?.data);
        }
    };

    const confirmOtp = async () => {
        try {
            setLoadingLogin(true);
            const body = {
                userId : userId,
                otpCode : otp
            }
            const res = await axios.post(`${apiRoute}/api/auth/confirmotp`, body);
            if(res.status === 200 && res.data.isValid){
                const token = res?.data?.token
                localStorage.setItem('token', res?.data?.token);
                // Token is valid, set it as a default Authorization header for axios
                axios.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                };
                setLoadingLogin(false);
                setShowOtp(false);
                navigate('/');
            }else{
                toast.error(`${res.data.message}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                if (res.data.isLocked) {
                    navigate('/');
                }
            }
        } catch (err) {
            console.log('Login Failed', err);
            setLoadingLogin(false);
            setError(err?.response?.data);
        }
    };

    const regenerateOtp = async () => {
        try {
            setLoadingLogin(true);
            const res = await axios.post(`${apiRoute}/api/auth/regenerateOTP?userId=${userId}`);
            if(res.status === 200 && res.data.isValid){
                setLoadingLogin(false);
                setDisableTryAgainButton(true);
                setTimeout(() => setDisableTryAgainButton(false), tryAgainButtonTime);
                toast.success(`${res.data.message}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{
                setLoadingLogin(false);
                toast.error(`${res.data.message}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
        } catch (err) {
            console.log('Login Failed', err);
            setLoadingLogin(false);
            setError(err?.response?.data);
        }
    };

    

    const handleClickShowPassword = (e) => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
    } = props;

    return (<>
        <Box sx={{
            backgroundImage: `url(${BG})`,
            backgroundSize: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% -1vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            {/* loginContainer */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center',
                backgroundSize: 'contain'
            }}>
                {/* loginBox */}
                <Box sx={{
                    position: 'relative',
                    zIndex: '0',
                    borderRadius: '20px',
                    filter: 'drop-shadow(0px 1px 0.5px black)',
                    minHeight: '38vh',
                    minWidth: '50vh',
                    backgroundColor: mode === 'dark' ? 'darkgrey' : 'white'
                }}>
                    {/* logoBox */}
                    <Box sx={{
                        width: '45vh',
                        margin: '0 auto',
                        position: 'relative',
                        zIndex: '1',
                        textAlign: 'center',
                        bottom: '40px',
                        borderRadius: '10px',
                        filter: 'drop-shadow(0px 1px .5px gray)',
                        backgroundColor: mode === 'dark' ? 'grey' : 'black'
                    }}>
                        <img
                            src={Logo}
                            alt="Interactive Software Solutions Logo"
                            width='115rem'
                        />
                    </Box>
                    {!showOtp ?  <form onSubmit={login}>
                        <Card>
                            <Box px={15} sx={{
                                textAlign: 'center',
                                backgroundColor: mode === 'dark' ? 'darkgrey' : 'white'
                            }}>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Box mb={5}>
                                        <TextField
                                            id='email'
                                            label='Email'
                                            type='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.email ? errors.email : ''}
                                            error={touched.email && Boolean(errors.email)}
                                            margin="dense"
                                            variant="standard"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <Icon edge='end'>
                                                            <Mail />
                                                        </Icon>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                        <TextField
                                            id='password'
                                            label='Password'
                                            type={showPassword ? 'text' : 'password'}
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            helperText={touched.password ? errors.password : ''}
                                            error={touched.password && Boolean(errors.password)}
                                            margin="dense"
                                            variant="standard"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <IconButton
                                                            aria-label='toggle password visibility'
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            edge='end'
                                                        >
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box sx={{ maxWidth: '40vh', margin: '0 auto', textAlign: 'center' }}>
                                    {error && <Box sx={{ color: 'red', mt: '1vh' }}>{error}</Box>}
                                </Box>
                                <Box sx={{
                                    padding: '4vh 0',
                                    filter: 'drop-shadow(0px 1px .5px gray)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <CardActions sx={{ width: '100%' }}>
                                        <Button type='submit' variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }}>
                                            Login
                                        </Button>
                                    </CardActions>
                                </Box>
                                <Box pb={5}>
                                    <Link to="/forgotpassword" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                                        Forgot Password?
                                    </Link>
                                </Box>
                            </Box>
                            {
                                loadingLogin && (
                                    <LinearProgress color="error" sx={{ width: '99.5%', m: '0 auto' }} />
                                )
                            }
                        </Card>
                    </form> :
                     <OtpVerificationForm 
                    otp={otp} 
                    setOtp={setOtp}
                    setShowOtp={setShowOtp}
                    confirmOtp={confirmOtp}
                    tryAgain={regenerateOtp}
                    disableTryAgainButton={disableTryAgainButton}
                    setDisableTryAgainButton={setDisableTryAgainButton}
                    handleCancel={handleCancel}
                    />} 
                    
                </Box>
            </Box>
        </Box>
        </>
    )
};

const Form = withFormik({
    mapPropsToValues: ({
        email,
        password
    }) => {
        return {
            email: email || '',
            password: password || ''
        }
    },

    validationSchema: yup.object().shape(loginValidations),

    handleSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
            toast.info(JSON.stringify(values, null, 2), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setSubmitting(false);
        }, 1000);
    }
})(Login);

export default Form;