import * as Yup from 'yup';

const loginValidations = {
    email: Yup
        .string()
        .email("incorrect email format")
        .required("Required"),
    password: Yup
        .string()
        .required('Required')
};

export default loginValidations;