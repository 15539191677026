import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Paper, Button, CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { SearchContext } from '../../context/SearchContext';

const SearchResults = (props) => {
    const { handleOpen } = props;

    const {
        setCaseId,
        searchResults,
        loadingSearch,
        noResults,
    } = useContext(SearchContext);

    const navigate = useNavigate();

    const quickViewCase = (e, params) => {
        e.stopPropagation();
        setCaseId(params.row.id);
    }

    const selectCase = (params) => {
        navigate(`/view/${params.row.id}`);
    }

    if (loadingSearch && !noResults) {
        return (
            <Box>
                <Box sx={{ width: "5%", margin: "15vh auto" }}>
                    <CircularProgress />
                </Box>
            </Box>
        )
    };

    const columns = [
        {
            id: 'caseStatus',
            field: 'caseStatus',
            headerName: 'Case Status',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'clerkCaseNumber',
            field: 'clerkCaseNumber',
            headerName: 'Case #',
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'citationNumber',
            field: 'citationNumber',
            headerName: "Citation #",
            width: 150,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'fullCaseNumber',
            field: 'fullCaseNumber',
            headerName: 'Full Case Number',
            width: 140,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'lastName',
            field: 'lastName',
            headerName: 'Last Name',
            width: 180,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'firstName',
            field: 'firstName',
            headerName: 'First Name',
            width: 180,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'driverLicenseNumber',
            field: 'driverLicenseNumber',
            headerName: 'License #',
            width: 140,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'dateOfBirth',
            field: 'dateOfBirth',
            headerName: 'D.O.B',
            width: 110,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'violationDate',
            field: 'violationDate',
            headerName: 'Violation Date',
            width: 160,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'courtDatetime',
            field: 'courtDatetime',
            headerName: 'Court Date',
            width: 140,
            align: 'left',
            headerAlign: 'left',
        },
        {
            id: 'quickView',
            field: 'quickView',
            headerName: 'Quick View Case',
            width: 150,
            align: 'left',
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <Paper elevation={10}>
                        <Button
                            onClick={async (e) => {
                                await quickViewCase(e, params);
                                handleOpen('quickCaseView');
                            }}
                            variant="contained"
                            sx={{ backgroundColor: "steelblue", color: 'white' }}
                        >
                            Quick View
                        </Button>
                    </Paper>
                )
            }
        },
    ];

    return (
        <Box>
            {
                noResults ? <h2 style={{ width: '99%', margin: '10vh auto', textAlign: 'center' }}>No Results</h2>
                    :
                    <Paper sx={{ width: "100%", mt: '5vh', height: "45vh", overFlow: "auto" }} elevation={10}>
                        <DataGrid
                            rows={searchResults}
                            columns={columns.map((column, index) => ({
                                ...column,
                                headerClassName: index === 0 ? 'first-column-header' : '',
                                cellClassName: index === 0 ? 'first-column-cell' : '',
                            }))}
                            disableMultipleRowSelection
                            onRowClick={selectCase}
                            density="compact"
                            sx={{
                                ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                                    marginTop: "1em",
                                    marginBottom: "1em"
                                },
                                '.first-column-header': {
                                    paddingLeft: '2em', // Adjust the padding value as needed
                                },
                                '.first-column-cell': {
                                    paddingLeft: '2em', // Adjust the padding value as needed
                                },
                                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                                    outline: "none !important",
                                }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                                    outline: "none !important",
                                },
                            }}
                        />
                    </Paper>
            }
        </Box>
    )
}

export default SearchResults;