import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';

import { LinearProgress, Button, Box, Checkbox, Tooltip, Modal } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { BatchContext } from '../../../context/BatchMgmtContext';
import { apiRoute } from '../../../App.js';
import BatchReports from '../utils/batchTable/BatchReports';
import BatchCollectionFilterModal from '../../navbar/ReportsModals/BatchCollectionFilterModal';
import { toTitleCase } from '../../../Utils';

const BatchTable = (props) => {
    const { setSelectedRow, setRowSelected, setBatchList, setReportLoading, batchReportSubmitHandler } = props;

    const { batchRows, loadingBatches, noResults, searchedBatches } = useContext(BatchContext);

    const [tableText, setTableText] = useState("");

    const [anchorEl, setAnchorEl] = useState({});

    const [batchCollectionFilterModal, setBatchCollectionFilterModal] = useState(false);

    const [batchNum4Filter, setBatchNum4Filter] = useState("");

    const handleClick = (e, rowId) => {
        e.stopPropagation();
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: e.currentTarget
        }));
    };

    const handleCloseDropdown = (rowId) => {
        setAnchorEl(prevState => ({
            ...prevState,
            [rowId]: null
        }));
    };

    const handleBatchCollectionFilterOpen = () => setBatchCollectionFilterModal(true);

    const getRow = (params) => {
        setSelectedRow(params.row);
        setRowSelected(true);
    };

    useEffect(() => {
        if (loadingBatches) {
            setTableText("Loading Batches...")
        } else if (noResults) {
            setTableText("No batches found for that search. Either search again or click reset to get all open batches")
        } else {
            setTableText("No Batches Found. Click reset to load all open batches or open a new batch.")
        }
    }, [loadingBatches, noResults]);

    const collectionReport = (batchNum) => {
        setBatchNum4Filter(batchNum);
        handleBatchCollectionFilterOpen()
    };

    const depositReport = (batchNum) => {
        setReportLoading(true);
        axios.get(`${apiRoute}/api/BatchTbl/existingDeposit/${batchNum}`, { responseType: 'arraybuffer' })
            .then((res) => {
                const arrayBufferView = new Uint8Array(res.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const url = URL.createObjectURL(blob);
                window.open(url);
            })
            .catch((error) => {
                console.error('Error retrieving data:', error);
            })
            .finally(() => setReportLoading(false));
    };

    const columns = [
        {
            id: 'select',
            field: 'select',
            headerName: '',
            width: 70,
            renderCell: (params) => (
                params.row.batchStatus === 'Settled' && (
                    <Checkbox
                        checked={params.row.selected}
                        onChange={(e) => {
                            const selected = e.target.checked;
                            // Use the functional form of setState to ensure you have the updated batchList
                            setBatchList((prevBatchList) => {
                                const updatedBatchList = [...prevBatchList];
                                if (selected) {
                                    updatedBatchList.push(params.row);
                                } else {
                                    const indexToRemove = updatedBatchList.findIndex((batch) => batch.id === params.row.id);
                                    if (indexToRemove !== -1) {
                                        updatedBatchList.splice(indexToRemove, 1);
                                    }
                                }
                                return updatedBatchList; // Return the updated state
                            });
                        }}
                    />
                )
            ),
        },
        {
            id: 'batchNumber',
            field: 'batchNumber',
            headerName: 'Batch ID #',
            width: 150
        },{
            id: 'batchName',
            field: 'batchName',
            headerName: 'Batch Name',
            width: 150
        },
        {
            id: 'batchType',
            field: 'batchType',
            headerName: 'Batch Type',
            width: 180,
            valueFormatter: ({ value }) => {
                if (value === undefined || value === null) return "";
                return toTitleCase(value);
            },
        },
        {
            id: 'openDatetime',
            field: 'openDatetime',
            headerName: "Date Created",
            width: 180
        },
        {
            id: 'userName',
            field: 'userName',
            headerName: 'User',
            width: 150
        },
        {
            id: 'batchStatus',
            field: 'batchStatus',
            headerName: 'Status',
            width: 125,
            renderCell: (params) => (
                <span style={{ color: params.value === 'Open' ? 'mediumseagreen' : params.value === 'Closed' ? 'indianred' : params.value === 'Settled' ? 'steelblue' : 'mediumpurple' }}>
                    <strong>{params.value}</strong>
                </span>
            )
        },
        {
            id: 'check',
            field: 'check',
            headerName: 'Check ($)',
            align: 'right',
            headerAlign: 'right',
            width: 145,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            }
        },
        {
            id: 'cash',
            field: 'cash',
            headerName: 'Cash ($)',
            align: 'right',
            headerAlign: 'right',
            width: 145,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            }
        },
        {
            id: 'creditCard',
            field: 'creditCard',
            headerName: 'Credit Card ($)',
            align: 'right',
            headerAlign: 'right',
            width: 165,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            }
        },
        {
            id: 'total',
            field: 'total',
            headerName: 'Total ($)',
            align: 'right',
            headerAlign: 'right',
            width: 145,
            renderCell: (params) => {
                return (
                    <>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(params.value)}</>
                )
            }
        },
        {
            field: 'reports',
            headerName: 'Reports',
            width: 200,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {
                const { row } = params;
                return (
                    <>
                        <Tooltip title={'Click here to view a dropdown of all reports that are avaiable to print for this batch'} placement="right">
                            <span>
                                <Button
                                    id="batch-reports"
                                    aria-controls={anchorEl[params.row.id] ? 'batch-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={anchorEl[params.row.id] ? 'true' : undefined}
                                    onClick={(e) => {
                                        setSelectedRow(row);
                                        handleClick(e, params.row.id)
                                    }}
                                    variant="contained"
                                    sx={{ backgroundColor: "steelblue", color: 'white', p: '.5rem' }}
                                    fullWidth
                                >
                                    Batch Reports
                                    <ArrowDropDownIcon />
                                </Button>
                            </span>
                        </Tooltip>
                        <>
                            <BatchReports
                                anchorEl={anchorEl}
                                row={row}
                                handleCloseDropdown={handleCloseDropdown}
                                collectionReport={collectionReport}
                                depositReport={depositReport}
                                batchReportSubmitHandler={batchReportSubmitHandler}
                            />
                        </>
                        <>
                            <Modal
                                open={batchCollectionFilterModal}
                                onClose={() => { setBatchCollectionFilterModal(false) }}
                                aria-labelledby="batch-collection-filter-modal"
                                sx={{ width: '35%', m: '5vh auto' }}
                            >
                                <>
                                    <BatchCollectionFilterModal
                                        handleClose={() => { setBatchCollectionFilterModal(false) }}
                                        batchNum={batchNum4Filter}
                                    />
                                </>
                            </Modal>
                        </>
                    </>
                )

            }
        }
    ];

    const minHeight = searchedBatches.length < 1 ? '25vh' : '';

    return (
        <Box sx={{ height: minHeight }}>
            <DataGrid
                rows={searchedBatches}
                columns={columns}
                loading={loadingBatches}
                disableMultipleRowSelection
                onRowClick={getRow}
                density="compact"
                initialState={{
                    pagination: { paginationModel: { pageSize: 10 } },
                    sortModel: [
                        {
                            field: 'openDatetime', // Field you want to sort
                            sort: 'desc', // Sort in descending order
                        },
                    ],
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                localeText={{ noRowsLabel: tableText }}
                rowHeight={60}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        marginTop: "1em",
                        marginBottom: "1em"
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                    maxHeight: '55vh',
                }}
            />
        </Box>
    )
}

export default BatchTable;