import React, { useReducer, useState, useEffect } from 'react';
import axios from 'axios';

import { Box, Grid, Button, MenuItem, TextField, Paper, LinearProgress, CircularProgress, RadioGroup, FormControlLabel, Radio, Checkbox, Modal, Backdrop } from '@mui/material';

import { toast } from 'react-toastify';

import { stateOptions } from '../../../Utils';
import { apiRoute } from '../../../App';

const DesotoCcPayment = ({ ts, payState, setModal, totalPayment, convenienceFee }) => {

    const [cardSwipeModal, setCardSwipeModal] = useState(false);
    const [swiper, setSwiper] = useState(true);
    const [loading, setLoading] = useState(false);
    const [sameInfo, setSameInfo] = useState(false);
    const [swipeText, setSwipeText] = useState('Please Swipe The Card Now');
    const [swiped, setSwiped] = useState(false);
    const [hidData, setHidData] = useState();

    const initialState = {
        firstName: '',
        lastName: '',
        email: '',
        creditCardNumber: '',
        month: '',
        year: '',
        securityCode: '',
        billingAddress: '',
        city: '',
        state: '',
        zipCode: '',
        errors: {},
    };

    const [billingState, setBillingState] = useState(initialState);

    const fieldValidations = {
        amountReceived: {
            errorRequired: 'Amount Received Required',
            validation: (value) => !value || value === '0.00',
        },
        paymentAmount: {
            errorRequired: 'Payment Amount Required',
            validation: (value) => !value || value === '0.00',
        },
        firstName: {
            errorRequired: 'First Name Required',
            validation: (value) => !value,
        },
        lastName: {
            errorRequired: 'Last Name Required',
            validation: (value) => !value,
        },
        billingAddress: {
            errorRequired: 'Billing Address Required',
            validation: (value) => !value || !/^[0-9A-Za-z\s.,'-]+$/.test(value),
        },
        state: {
            errorRequired: 'State Required',
            errorFormat: 'Value must be 2 characters long',
            validation: (value) => !value || ((typeof value === 'string') && value.length === 2),
        },
        city: {
            errorRequired: 'City Required',
            validation: (value) => !value || (typeof value !== 'string'),
        },
        email: {
            errorRequired: 'Email Required',
            errorFormat: 'Please enter a valid email address',
            validation: (value) => !value || /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
        },
        securityCode: {
            errorRequired: 'Security Code Required',
            errorFormat: 'Please enter a valid security code',
            validation: (value) => !value || /^\d{3,4}$/.test(value.replace(/\D/g, '')),
        },
        zipCode: {
            errorRequired: 'Zip Code Required',
            errorFormat: 'Please enter a valid zip code',
            validation: (value) => !value || /^\d{5}(-\d{4})?$/.test(value),
        },
        month: {
            errorRequired: 'Month Required',
            errorFormat: 'Please enter a valid two-digit number',
            validation: (value) => {
                if (!value) return false; // Value is required
                const isNumeric = /^\d+$/.test(value); // Check if it's numeric
                const isCorrectLength = value.length === 2; // Check if the length is 2
                return isNumeric && isCorrectLength; // Both conditions must be true
            },
        },
        year: {
            errorRequired: 'Year Required',
            errorFormat: 'Please enter a valid four-digit number',
            validation: (value) => {
                if (!value) return false; // Value is required
                const isNumeric = /^\d+$/.test(value); // Check if it's numeric
                const isCorrectLength = value.length === 4; // Check if the length is 4
                return isNumeric && isCorrectLength; // Both conditions must be true
            },
        },
        creditCardNumber: {
            errorRequired: 'Credit Card Number Required',
            errorFormat: 'Please enter a valid Credit Card number',
            validation: (value) => !value || /^\d+$/.test(value.replace(/\s/g, '')),
        },
    };

    const handleBlur = (field, value) => {
        // Perform validation and set error messages based on field type
        const fieldInfo = fieldValidations[field];
        let error = null;

        if (fieldInfo) {
            if (!value) {
                error = fieldInfo.errorRequired;
            } else if (fieldInfo.errorFormat && !fieldInfo.validation(value)) {
                error = fieldInfo.errorFormat;
            }
        }

        // Dispatch the UPDATE_FIELD action with the error information
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            case 'RESET_STATE':
                return action.initialState;
            default:
                return state;
        }
    };

    const resetState = () => {
        dispatch({ type: 'RESET_STATE', initialState });
    };

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleAutoFill = () => {
        setSameInfo(prevSameInfo => !prevSameInfo);
        const value = !sameInfo;
        if (!value) {
            setBillingState(state);
        } else {
            setBillingState(payState);
        }
    };

    const submitCCPayment = async (payment) => {
        const ccPaymentData = {
            CardHolderEmail: sameInfo ? billingState.email : state.email,
            CardHolderPhone: '',
            CardHolderName: sameInfo ? `${billingState.firstName} ${billingState.lastName}` : `${state.firstName} ${state.lastName}`,
            CardHolderAddressLine1: sameInfo ? billingState.billingAddress : state.billingAddress,
            CardHolderCity: sameInfo ? billingState.city : state.city,
            CardHolderState: sameInfo ? billingState.state : state.state,
            CardHolderZip: sameInfo ? billingState.zipCode : state.zipCode,
            PaymentAmount: (parseFloat(payment.Amount)).toFixed(2),
            firstName: sameInfo ? billingState.firstName : state.firstName,
            lastName: sameInfo ? billingState.lastName : state.lastName,
            CreditCardNumber: state.creditCardNumber,
            month: state.month,
            year: state.year,
            securityCode: state.securityCode,
            ConvenienceFee: convenienceFee,
        };
        const desoto = {
            CitNum: payState.citNum,
            CourtDate: payState.courtDate,
            Amount: parseFloat(ccPaymentData.PaymentAmount - ccPaymentData.ConvenienceFee).toFixed(2),
            FirstName: payState.firstName,
            LastName: payState.lastName,
            Address1: payState.address1,
            Address2: payState.address2,
            ZipCode: payState.zipCode,
            City: payState.city,
            State: payState.state,
            PhoneNumber: payState.phone,
            DOB: payState.birthDate || "",
            Type: 'Counter Payments',
            Description: 'Citation Payment',
            Channel: 'Web',
            Status: 'New',
            Email: payState.email,
            PaymentType: "CREDIT CARD"
        }
        try {
            await axios.post(`${apiRoute}/api/CcPayments/auth/${ccPaymentData.CreditCardNumber}/${ccPaymentData.month}/${ccPaymentData.year}/${ccPaymentData.securityCode}/${ccPaymentData.firstName}/${ccPaymentData.lastName}/13`, ccPaymentData)
                .then(({ data }) => {
                    if (data.responseObject.responseCode !== "100") {
                        toast.error(`${data.responseText}`, {
                            position: "top-right",
                            autoClose: 10000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        console.error('Failed to create payment', data);
                        return false;
                    }
                    if (data.responseObject.responseCode === "100") {
                        payment.ConvenienceFee = convenienceFee;
                        desoto.FkCCPaymentId = data.ccId;
                        axios.post(`${apiRoute}/api/DesotoPayment/addPayment`, desoto)
                            .then(({ data }) => {
                                console.log(data);
                            })
                            .catch((err) => console.error(err))

                        setCardSwipeModal(false);
                        resetState();
                        toast.success('Payments added', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                        return true;
                    }
                })
        } catch (error) {
            toast.error(`Error occurred while creating payment. ${error?.response?.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('Error occurred while creating payment', error);
            return false;
        }
    };

    const createPayment = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const paymentData = {
                Amount: totalPayment,
                PaymentType: "CREDIT CARD",
                FirstName: sameInfo ? billingState.firstName : state.firstName,
                LastName: sameInfo ? billingState.lastName : state.lastName,
                Address1: sameInfo ? billingState.address1 : state.billingAddress,
                City: sameInfo ? billingState.city : state.city,
                State: sameInfo ? billingState.state : state.state,
                ZipCode: sameInfo ? billingState.zipCode : state.zipCode,
                PhoneNumber: payState.phone,
                DOB: payState.birthDate,
                Type: 'Counter Payments',
                Description: 'Citation Payment',
                Channel: 'Web',
                Status: 'New',
                Email: billingState.email
            };

            try {
                await submitCCPayment(paymentData);
                resetState();
                setModal(false);
                setCardSwipeModal(false);
            } catch (submitError) {
                setLoading(false);
                console.log('Error occurred while submitting payment', submitError);
                toast.error(`Error occurred while submitting payment. ${submitError}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return;
            }
        } catch (err) {
            toast.error(`Payment failed. ${err?.response?.data}`, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSwipe = () => {
        const swiperBody = {
            DeviceID: '',
            WaitTime: '120',
            ConnectionType: '4',
            FieldSeparator: '|',
            AdditionalRequestData: null,
        };

        const options = {
            headers: null,
        }
        const value = axios.post('http://localhost:9002/api/mtscrahost/RequestCardSwipe', swiperBody, options)
        value.then(val => setHidData(val.data.CardSwipeOutput));
        return value?.response;
    }

    const handleCardData = () => {

        const tempFirstName = hidData.CardName.split('/')[1]
        const tempLastName = hidData.CardName.split('/')[0]

        if (hidData !== null) {
            setSwipeText("Contacting Merchant Services...")
            try {
                sendEncryptedCardDataToBackend(tempFirstName, tempLastName);
            } catch (err) {
                setSwipeText('Payment Failed');
                setHidData(null)
            }
        } else {
            setSwipeText('Card Read Error');
        }
    };

    async function sendEncryptedCardDataToBackend(first, last) {
        setSwiped(true)
        const ccPaymentData = {
            CardHolderEmail: state.email,
            CardHolderPhone: '',
            CardHolderName: !!first && !!last ? `${first} ${last}` : '',
            CardHolderAddressLine1: state.billingAddress,
            CardHolderCity: state.city,
            CardHolderState: state.state,
            CardHolderZip: state.zipCode,
            PaymentAmount: totalPayment,
            EncryptedTrack1: hidData.Track1,
            EncryptedTrack2: hidData.Track2,
            SecurityCode: state.securityCode,
            CardName: `${first} ${last}`,
            KSN: hidData.KSN,
            FirstName: !!first ? first : state.firstName,
            LastName: !!last ? last : state.lastName,
            ConvenienceFee: convenienceFee,
        };

        axios.post(`${apiRoute}/api/CcPayments/auth/${state.securityCode}/13`, ccPaymentData)
            .then(response => {
                if (!response.data.responseCode === "100") {
                    toast.error(`${response.data.responseText}`, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSwipeText('Payment Failed');
                } else {
                    return response.data;
                }
            })
            .then(data => {
                const desoto = {
                    CitNum: payState.citNum,
                    CourtDate: payState.courtDate,
                    Amount: parseFloat(ccPaymentData.PaymentAmount).toFixed(2),
                    FirstName: payState.firstName,
                    LastName: payState.lastName,
                    Address1: payState.address1,
                    Address2: payState.address2,
                    ZipCode: payState.zipCode,
                    City: payState.city,
                    State: payState.state,
                    PhoneNumber: payState.phone,
                    DOB: payState.birthDate,
                    Type: 'Counter Payments',
                    Description: 'Citation Payment',
                    Channel: 'Web',
                    Status: 'New',
                    Email: payState.email,
                    PaymentType: "CREDIT CARD"
                }
                axios.post(`${apiRoute}/api/DesotoPayment`, desoto)
                    .then(({ data }) => {
                        console.log(data);
                    })
                    .catch((err) => console.error(err))
                toast.success('Payments added', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setSwiped(false)
                setSwipeText('Transaction Complete');
                resetState();
                setCardSwipeModal(false);
                setModal(false);
            })
            .catch(error => {
                // Handle errors
                setSwipeText('Payment Failed');
                setSwiped(false);
                setHidData(null)
                toast.error(`Error occurred while creating payment: ${error?.response?.data}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

            });
    }

    useEffect(() => {
        if (hidData) handleCardData()
    }, [hidData])

    return (
        <form onSubmit={createPayment}>
            <Paper mb={1} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '1vh', textAlign: 'center' }} elevation={10}>
                    <h2 style={{ fontWeight: 'bold', marginTop: '.5vh' }}>Enter Credit Card Info</h2>
                </Paper>
                <>
                    <RadioGroup
                        row
                        name='searchFilter'
                        value={swiper}
                        onChange={() => setSwiper(!swiper)}
                        sx={{ ml: '1vh' }}
                    >
                        <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Swipe Card'
                            checked={swiper}
                        />
                        <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Manually Enter Card Info'
                            checked={!swiper}
                        />
                    </RadioGroup>
                    <FormControlLabel control={<Checkbox checked={sameInfo} onChange={handleAutoFill} sx={{ ml: '1vh' }} />} label="Same As Previous Information" />
                    <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1vh', maxHeight: '50vh', overflowY: 'auto' }}>
                        {
                            !swiper && (
                                <>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='firstName'
                                                label="First Name"
                                                variant="outlined"
                                                fullWidth
                                                value={sameInfo ? payState.firstName : state.firstName}
                                                onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                                onBlur={(e) => handleBlur('firstName', e.target.value)}
                                                helperText={state?.errors?.firstName}
                                                error={!!state?.errors?.firstName}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='lastName'
                                                label="Last Name"
                                                variant="outlined"
                                                fullWidth
                                                value={sameInfo ? payState.lastName : state.lastName}
                                                onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                                onBlur={(e) => handleBlur('lastName', e.target.value)}
                                                helperText={state?.errors?.lastName}
                                                error={!!state?.errors?.lastName}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='email'
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={sameInfo ? payState.email : state.email}
                                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                                onBlur={(e) => handleBlur('email', e.target.value)}
                                                helperText={state?.errors?.email}
                                                error={!!state?.errors?.email}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={9.25}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='creditCardNumber'
                                                label="Credit Card Number"
                                                variant="outlined"
                                                fullWidth
                                                value={state.creditCardNumber}
                                                onChange={(e) => handleFieldChange('creditCardNumber', e.target.value)}
                                                onBlur={(e) => handleBlur('creditCardNumber', e.target.value)}
                                                helperText={state?.errors?.creditCardNumber}
                                                error={!!state?.errors?.creditCardNumber}
                                                inputProps={{ maxLength: '19' }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='month'
                                                label="Exp Month"
                                                variant="outlined"
                                                fullWidth
                                                value={state.month}
                                                onChange={(e) => handleFieldChange('month', e.target.value)}
                                                onBlur={(e) => handleBlur('month', e.target.value)}
                                                helperText={state?.errors?.month}
                                                error={!!state?.errors?.month}
                                                inputProps={{ maxLength: '2' }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='year'
                                                label="Exp Year"
                                                variant="outlined"
                                                fullWidth
                                                value={state.year}
                                                onChange={(e) => handleFieldChange('year', e.target.value)}
                                                onBlur={(e) => handleBlur('year', e.target.value)}
                                                helperText={state?.errors?.year}
                                                error={!!state?.errors?.year}
                                                inputProps={{ maxLength: '4' }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='securityCode'
                                                label="CVV/CVC"
                                                variant="outlined"
                                                fullWidth
                                                value={state.securityCode}
                                                onChange={(e) => handleFieldChange('securityCode', e.target.value)}
                                                onBlur={(e) => handleBlur('securityCode', e.target.value)}
                                                helperText={state?.errors?.securityCode}
                                                error={!!state?.errors?.securityCode}
                                                inputProps={{ maxLength: '4' }}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }
                        {
                            swiper && (
                                <>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='email'
                                                label="Email"
                                                variant="outlined"
                                                fullWidth
                                                value={sameInfo ? payState.email : state.email}
                                                onChange={(e) => handleFieldChange('email', e.target.value)}
                                                onBlur={(e) => handleBlur('email', e.target.value)}
                                                helperText={state?.errors?.email}
                                                error={!!state?.errors?.email}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='securityCode'
                                                label="CVV/CVC"
                                                variant="outlined"
                                                fullWidth
                                                value={state.securityCode}
                                                onChange={(e) => handleFieldChange('securityCode', e.target.value)}
                                                onBlur={(e) => handleBlur('securityCode', e.target.value)}
                                                helperText={state?.errors?.securityCode}
                                                error={!!state?.errors?.securityCode}
                                                inputProps={{ maxLength: '4' }}
                                            />
                                        </Box>
                                    </Grid>
                                </>
                            )
                        }
                        <Grid item sm={9.25}>
                            <Box m={.5}>
                                <TextField
                                    required
                                    sx={ts}
                                    id='billingAddress'
                                    label="Billing Address"
                                    variant="outlined"
                                    fullWidth
                                    value={sameInfo ? state.billingAddress : state.billingAddress}
                                    onChange={(e) => handleFieldChange('billingAddress', e.target.value)}
                                    onBlur={(e) => handleBlur('billingAddress', e.target.value)}
                                    helperText={state?.errors?.billingAddress}
                                    error={!!state?.errors?.billingAddress}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box m={.5}>
                                <TextField
                                    required
                                    sx={ts}
                                    id='city'
                                    label="City"
                                    variant="outlined"
                                    fullWidth
                                    value={sameInfo ? state.city : state.city}
                                    onChange={(e) => handleFieldChange('city', e.target.value)}
                                    onBlur={(e) => handleBlur('city', e.target.value)}
                                    helperText={state?.errors?.city}
                                    error={!!state?.errors?.city}
                                />
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box m={.5}>
                                <TextField
                                    required
                                    select
                                    sx={ts}
                                    id='state'
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    value={sameInfo ? state.state : state.state}
                                    onChange={(e) => handleFieldChange('state', e.target.value)}
                                    onBlur={(e) => handleBlur('state', e.target.value)}
                                    helperText={state?.errors?.state}
                                    error={!!state?.errors?.state}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '20em',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value=''>N/A</MenuItem>
                                    {
                                        stateOptions?.map(({ value, label }) => {
                                            return <MenuItem key={label} value={value}>{label}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item sm={3}>
                            <Box m={.5}>
                                <TextField
                                    required
                                    sx={ts}
                                    id='zipCode'
                                    label="Zip Code"
                                    variant="outlined"
                                    fullWidth
                                    value={sameInfo ? state.zipCode : state.zipCode}
                                    onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                                    onBlur={(e) => handleBlur('zipCode', e.target.value)}
                                    helperText={state?.errors?.zipCode}
                                    error={!!state?.errors?.zipCode}
                                    inputProps={{ maxLength: '10' }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                        !swiper ? (
                            <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} type="submit" disabled={loading}>
                                Submit CC Payment
                            </Button>
                        ) : (
                                <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} onClick={() => { setCardSwipeModal(true); handleSwipe(); }}>
                                Swipe Card
                            </Button>
                        )
                    }
                    <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} onClick={() => setModal(false)} disabled={loading}>Cancel</Button>
                </Box>
            </Paper>
            {
                loading && (
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                        <CircularProgress />
                    </Backdrop>
                )
            }
            <Modal open={cardSwipeModal} sx={{ width: '35%', margin: "10vh auto" }}>
                <>
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Swipe Card</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ textAlign: 'center', mt: '5vh' }}>
                            {
                                swiped
                                    ? <Box sx={{ width: '90%', m: '1vh auto' }}><LinearProgress /></Box>
                                    : <h2>{swipeText}</h2>
                            }
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {swipeText !== "Payment Failed" ?
                                <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => { setCardSwipeModal(false); setHidData(null) }} disabled={swiped === true}>Cancel</Button>
                                :
                                <>
                                    <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => { setSwipeText('Please Swipe The Card Now'); setHidData(null); handleSwipe(); }}>Retry</Button>
                                    <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => { setCardSwipeModal(false); setHidData(null) }} disabled={swiped === true}>Cancel</Button>
                                </>
                            }
                        </Box>
                    </Paper>
                </>
            </Modal>
        </form>
    )
}

export default DesotoCcPayment;