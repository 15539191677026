import React, { useEffect, useState } from 'react';
import axios from 'axios';


import { Box, Grid, Button, TextField, Paper, InputAdornment, Backdrop, CircularProgress, ButtonGroup, Modal } from '@mui/material';

import { MdEditOff } from "react-icons/md";
import { toast } from 'react-toastify';

import { apiRoute } from '../../../App.js';

const DesotoPaymentForm = (props) => {
    const {
        handleClose,
        ts,
        selectedPayment,
        rows,
        setRows,
    } = props;

    const [note, setNote] = useState(selectedPayment.note);
    const [temp, setTemp] = useState(note);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [status, setStatus] = useState('');
    const [openOverPaid, setOpenOverPaid] = useState(false);
    const [amountOverpaid, setAmountOverpaid] = useState('0.00');

    // boolean to render the textField to capture the other reasons
    const [otherReason, setOtherReason] = useState(false);

    // actual reason saved to database
    const [reason, setReason] = useState('');

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();

        // Add leading zero if month or day is a single digit
        month = month.length === 1 ? '0' + month : month;
        day = day.length === 1 ? '0' + day : day;

        return `${year}-${month}-${day}`;
    };

    const handlePending = async (id) => {
        setLoading(true);
        const status = 'Pending'
        try {
            await axios.post(`${apiRoute}/api/DesotoPayment/UpdatePaymentStatus/${id}/${status}/${'Pending'}/${0.00}`)
            toast.success(`Payment is now ${status}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose()
            axios.get(`${apiRoute}/api/DesotoPayment`)
                .then(({ data }) => {
                    setRows(data);
                })
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                toast.error(`${error.response.data}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        } finally {
            setLoading(false);
        }
    };

    const handleAcceptedRejected = (action) => {
        switch (action) {
            case 'accept':
                setStatus('Accepted');
                setOpen(true);
                break;
            case 'reject':
                setStatus('Rejected');
                setOpen(true);
                break;
            case 'refund':
                setStatus('Refunded');
                setOpen(true);
                break;
            default:
                return;
        }
    };

    const acceptOrRejectPayment = async (status) => {
        const id = selectedPayment.id;
        try {
            await axios.post(`${apiRoute}/api/DesotoPayment/UpdatePaymentStatus/${id}/${status}/${reason}/${amountOverpaid.slice(1,)}`)

            toast.success(`Payment is now ${status}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose()
            axios.get(`${apiRoute}/api/DesotoPayment`)
                .then(({ data }) => {
                    setRows(data);
                })
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                toast.error(`${error.response.data}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        } finally {
            setLoading(false);
        }
    }

    const saveNote = (e, id, note) => {
        e.preventDefault()
        try {
            axios.post(`${apiRoute}/api/DesotoPayment/UpdateNote/${id}/${note}`)
            toast.success(`Note has been successfully saved.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setTemp(note)
        } catch (error) {
            toast.error(`${error}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const handleRefund = async (id) => {
        try {
            await axios.post(`${apiRoute}/api/DesotoPayment/UpdatePaymentStatus/${id}/${"Refunded"}/${"refund"}/${amountOverpaid}`)
            toast.success(`Payment is now Refunded!`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose()
            axios.get(`${apiRoute}/api/DesotoPayment`)
                .then(({ data }) => {
                    setRows(data);
                })
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
                toast.error(`${error.response.data}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser
                console.log(error.request);
                toast.error(`${error.request}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
                toast.error(`${error.message}`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            console.log(error.config);
        } finally {
            setLoading(false);
        };
    };


    useEffect(() => {
        openOverPaid && setReason(`This transaction will be marked OVERPAID. You are ACCEPTING this payment. This transaction has been overpaid by:  ${amountOverpaid}. The overpayment amount entered will be refunded back to the payer.`)
    }, [openOverPaid, amountOverpaid, reason, setReason]);

    return (
        <form>
            <Paper
                sx={
                    {
                        mb: '1vh'
                    }
                }
                elevation={10}
            >
                <Paper className="border-radius-bottom"
                    sx={
                        {
                            backgroundColor: 'steelblue',
                            color: 'white',
                            p: '.5vh',
                            height: '3vh'
                        }
                    }
                    elevation={10
                    }>
                    <h6
                        style={
                            {
                                fontWeight: 'bold',
                                fontSize: 'inherit'
                            }
                        }
                    >
                        General Information
                    </h6>
                </Paper>
                <Grid
                    container
                    direction="row"
                    sx={
                        {
                            display: 'flex',
                            gap: '1rem',
                            flexWrap: 'wrap',
                            p: '1vh',
                            mt: '1vh'
                        }
                    }
                >
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            type="date"
                            name="datePaid"
                            id="datePaid"
                            label="Date Paid"
                            value={selectedPayment.datePaid ? formatDate(selectedPayment.datePaid) : ''}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )

                                }}
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.5}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="invoiceNumber"
                            id="invoiceNumber"
                            label="Invoice Number"
                            value={selectedPayment.invoiceNumber}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="organization"
                            id="organization"
                            label="Organization"
                            value={selectedPayment.organization}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="firstName"
                            id="firstName"
                            label="First Name"
                            value={selectedPayment.firstName}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={4}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="lastName"
                            id="lastName"
                            label="Last Name"
                            value={selectedPayment.lastName}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            type="date"
                            name="dob"
                            id="dob"
                            label="DOB"
                            value={selectedPayment.dob ? formatDate(selectedPayment.dob) : ''}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="address1"
                            id="address1"
                            label="Address Line 1"
                            value={selectedPayment.address1}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="address2"
                            id="address2"
                            label="Address Line 2"
                            value={selectedPayment.address2}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="email"
                            id="email"
                            label="Email"
                            value={selectedPayment.email}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="city"
                            id="city"
                            label="City"
                            value={selectedPayment.city}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="state"
                            id="state"
                            label="State"
                            value={selectedPayment.state}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2
                        }>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="zip"
                            id="zip"
                            label="Zip"
                            value={selectedPayment.zipCode}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="phoneNumber"
                            id="phoneNumber"
                            label="Phone"
                            value={selectedPayment.phoneNumber}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="mobileNumber"
                            id="mobileNumber"
                            label="Mobile"
                            value={selectedPayment.phoneNumber}
                            variant="outlined"
                            inputProps={{ readOnly: true }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="paidName"
                            id="paidName"
                            label="Paid Name"
                            value={selectedPayment.firstName + " " + selectedPayment.lastName}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2
                        }>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="paidPhoneNumber"
                            id="paidPhoneNumber"
                            label="Paid Phone Number"
                            value={selectedPayment.phoneNumber}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper
                sx={
                    {
                        mb: '1vh'
                    }
                }
                elevation={10}
            >
                <Paper className="border-radius-bottom" sx={
                    {
                        backgroundColor: 'steelblue',
                        color: 'white',
                        p: '.5vh',
                        height: '3vh'
                    }
                }
                    elevation={10}
                >
                    <h6
                        style={
                            {
                                fontWeight: 'bold',
                                fontSize: 'inherit'
                            }
                        }
                    >
                        Transaction Information
                    </h6>
                </Paper>
                <Grid
                    container
                    direction="row"
                    sx={
                        {
                            display: 'flex',
                            gap: '1rem',
                            flexWrap: 'wrap',
                            p: '1vh',
                            mt: '1vh'
                        }
                    }
                >
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="status"
                            id="status"
                            label="Status"
                            value={selectedPayment.status}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="statusLastUpdate"
                            id="statusLastUpdate"
                            label="Status Last Updated"
                            type="date"
                            value={selectedPayment.statusUpdate ? formatDate(selectedPayment.statusUpdate) : ''}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="paymentType"
                            id="paymentType"
                            label="Payment Type"
                            value={''}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="citNumber"
                            id="citNumber"
                            label="Citation/Item Number"
                            value={selectedPayment.citNum}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="courtDate"
                            id="courtDate"
                            label="Court Date"
                            type="date"
                            value={selectedPayment.courtDate ? formatDate(selectedPayment.courtDate) : ''}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="amount"
                            id="amount"
                            label="Amount"
                            value={`$${selectedPayment.amount}`}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment
                                            position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sm={2.75}
                    >
                        <TextField
                            fullWidth
                            sx={ts}
                            name="description"
                            id="description"
                            label="Description"
                            value={selectedPayment.description}
                            variant="outlined"
                            inputProps={
                                {
                                    readOnly: true
                                }
                            }
                            InputProps={
                                {
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <MdEditOff />
                                        </InputAdornment>
                                    )
                                }
                            }
                            InputLabelProps={
                                {
                                    shrink: true
                                }
                            }
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper
                sx={
                    {
                        mb: '1vh'
                    }
                }
                elevation={10}
            >
                <Paper className="border-radius-bottom"
                    sx={
                        {
                            backgroundColor: 'steelblue',
                            color: 'white',
                            p: '.5vh',
                            height: '3vh',
                            mb: '1vh'
                        }
                    }
                    elevation={10}
                >
                    <h6
                        style={
                            {
                                fontWeight: 'bold',
                                fontSize: 'inherit'
                            }
                        }
                    >
                        Notes
                    </h6>
                </Paper>
                <Grid
                    item
                    sm={2.75}
                    sx={
                        {
                            m: '1vh .6rem',
                            mt: '1vh'
                        }
                    }
                >
                    <TextField
                        fullWidth
                        multiline
                        rows={3}
                        sx={ts}
                        name="note"
                        id="note"
                        label="Note"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Button
                    variant='contained'
                    sx={
                        {
                            backgroundColor: 'steelblue',
                            color: 'white'
                            , ml: '1vw',
                            mt: '1vh'
                        }
                    }
                    type="submit"
                    onClick={(e) => saveNote(e, selectedPayment.id, note)}
                    disabled={note === temp}
                >
                    Save Note
                </Button>
                <Box
                    sx={
                        {
                            display: 'flex',
                            gap: '1rem',
                            justifyContent: 'end',
                            pb: '1vh',
                            pr: '1vw'
                        }
                    }
                >
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        type="button"
                        onClick={
                            () => handleAcceptedRejected('accept')
                        }
                        disabled={selectedPayment.status.toLowerCase() === 'accepted' || selectedPayment.status.toLowerCase() === 'rejected'}
                    >
                        Accept
                    </Button>
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        type="button"
                        onClick={
                            () => handleAcceptedRejected('reject')
                        }
                        disabled={selectedPayment.status.toLowerCase() === 'accepted' || selectedPayment.status.toLowerCase() === 'rejected'}
                    >
                        Reject
                    </Button>
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        type="button"
                        onClick={
                            () => handlePending(selectedPayment.id)
                        }
                        disabled={selectedPayment.status.toLowerCase() === 'accepted' || selectedPayment.status.toLowerCase() === 'rejected'}
                    >
                        Set Pending
                    </Button>
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        type="button"
                        onClick={
                            () => {
                                handleAcceptedRejected('refund');
                                //handleRefund(selectedPayment.id)
                            }
                        }
                        disabled={selectedPayment.status.toLowerCase() !== 'accepted'}
                    >
                        Refund Payment
                    </Button>
                    <Button
                        variant='contained'
                        sx={
                            {
                                backgroundColor: 'steelblue',
                                color: 'white'
                            }
                        }
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                </Box>
                <Modal
                    open={open}
                >
                    <Paper
                        sx={
                            {
                                width:
                                    status === 'Accepted' ? '45%' : '50%',
                                height:
                                    otherReason ? '36vh'
                                        : openOverPaid ? '37vh'
                                            : status === 'Rejected' ? '30vh'
                                                : status === 'Refunded' ? '28vh'
                                                    : '25vh',
                                m: '15vh auto',
                                p: 'auto'
                            }}>
                        <Paper
                            sx={
                                {
                                    backgroundColor: 'steelblue',
                                    p: '.5vh'
                                }
                            }
                        >
                            <h3>
                                {status.toUpperCase()}
                            </h3>
                        </Paper>
                        <Box
                            sx={
                                {
                                    width: '99%'
                                }
                            }
                        >
                            <Box
                                sx={
                                    {
                                        display: 'flex',
                                        m: '1vh 1vw'
                                    }
                                }
                            >
                                <h4>
                                    You Have Elected To {status.slice(0, status.length - 2)} This Payment
                                </h4>
                            </Box>
                            {status !== 'Refunded' && <Box
                                sx={
                                    {
                                        display: 'flex',
                                        marginLeft: '1vw',
                                        width: '100%'
                                    }
                                }
                            >
                                <Box
                                    sx={
                                        {
                                            mt: status === 'Accepted' ? '2vh' : '3.5vh'
                                        }
                                    }
                                >
                                    <h6>
                                        Please Select The Appropriate Reason:
                                    </h6>
                                </Box>
                                <ButtonGroup
                                    variant="contained"
                                    sx={
                                        {
                                            display: 'flex',
                                            flex: '0',
                                            float: 'right',
                                            mr: '1vw',
                                            ml: '1vw',
                                            background: 'steelblue'
                                        }
                                    }
                                >
                                    <Button
                                        onClick={
                                            () => {
                                                setOtherReason(true);
                                                setOpenOverPaid(false);
                                                status === 'Accepted' ? setReason("Transaction OK")
                                                    : setReason("Must Appear On Court Date")
                                            }
                                        }
                                        sx={
                                            {
                                                '&:focus': {
                                                    background: 'steelblue'
                                                },
                                                width: '12vw'
                                            }
                                        }
                                    >
                                        {status === 'Accepted' ? "Transaction OK" : "Must Appear On Court Date"}
                                    </Button>
                                    <Button onClick={
                                        () => {
                                            setOtherReason(true);
                                            setOpenOverPaid(false);
                                            status === 'Accepted' ? setReason("Underpaid")
                                                : setReason("Record Not Found");
                                        }
                                    }
                                        sx={
                                            {
                                                '&:focus': {
                                                    background: 'steelblue'
                                                }
                                            }
                                        }
                                    >
                                        {status === 'Accepted' ? "Underpaid" : "Record Not Found"}
                                    </Button>
                                    <Button onClick={
                                        () => {
                                            setOtherReason(false);
                                            status === "Accepted" && setOpenOverPaid(true);
                                            status === "Rejected" && setOtherReason(true);
                                            status === 'Accepted' ? setReason("")
                                                : setReason("Past Due")
                                        }
                                    }
                                        sx={
                                            {
                                                '&:focus': {
                                                    background: 'steelblue'
                                                }
                                            }
                                        }
                                    >
                                        {status === 'Accepted' ? "Overpaid" : "Past Due"}
                                    </Button>
                                    <Button
                                        onClick={
                                            () => {
                                                setOtherReason(true);
                                                setOpenOverPaid(false);
                                                status === 'Accepted' ? setReason('')
                                                    : setReason("Incorrect Amount Paid");
                                            }
                                        }
                                        sx={
                                            {
                                                '&:focus': {
                                                    background: 'steelblue'
                                                }
                                            }
                                        }
                                    >
                                        {status === 'Accepted' ? "Other Reason" : "Incorrect Amount Paid"}
                                    </Button>
                                    {status === 'Rejected' &&
                                        <Button
                                            onClick={
                                                () => setOtherReason(true) & setReason('')
                                            }
                                            sx={
                                                {
                                                    '&:focus': {
                                                        background: 'steelblue'
                                                    }
                                                }
                                            }
                                        >
                                            Other Rejection Reason
                                        </Button>
                                    }
                                </ButtonGroup>
                            </Box>}
                            <Box>
                                {
                                    otherReason && <TextField
                                        multiline
                                        rows={2}
                                        sx={
                                            {
                                                ...ts,
                                                width: '85%',
                                                m: '1vh auto',
                                                ml: '3vw'
                                            }
                                        }
                                        name={`${status.toLowerCase()}reason`}
                                        id={`${status.toLowerCase()}reason`}
                                        label={`${status} Reason`}
                                        value={reason}
                                        onChange={
                                            (e) => setReason(e.target.value)
                                        }
                                        variant="outlined"
                                        InputLabelProps={
                                            {
                                                shrink: true
                                            }
                                        }
                                    />
                                }
                                {
                                    status === 'Refunded' && <TextField
                                        multiline
                                        rows={2}
                                        sx={
                                            {
                                                ...ts,
                                                width: '85%',
                                                m: '1vh auto',
                                                ml: '3vw'
                                            }
                                        }
                                        name={`${status.toLowerCase()}reason`}
                                        id={`${status.toLowerCase()}reason`}
                                        label={`${status} Disclaimer`}
                                        value={"By refunding this payment, you acknowledge the full amount of this payment will be refunded back to the defendant. Click Refund if you are satisified with that result, if not click Cancel."}
                                        onChange={
                                            (e) => setReason(e.target.value)
                                        }
                                        variant="outlined"
                                        InputLabelProps={
                                            {
                                                shrink: true
                                            }
                                        }
                                    />
                                }
                                {
                                    openOverPaid
                                    &&
                                    (
                                        <Box
                                            sx={
                                                {
                                                    width: 'auto',
                                                    mt: '.7vh'
                                                }
                                            }
                                        >
                                            <TextField
                                                sx={
                                                    {
                                                        ...ts,
                                                        width: '25%',
                                                        m: '1vh auto',
                                                        ml: '3vw'
                                                    }
                                                }
                                                name='overpaidAmount'
                                                id='overpaidAmount'
                                                label='Amount Overpaid'
                                                value={amountOverpaid}
                                                onClick={
                                                    () => setAmountOverpaid(parseFloat(amountOverpaid.slice(1,)))
                                                }
                                                onChange={
                                                    (e) => setAmountOverpaid(e.target.value)
                                                }
                                                onBlur={
                                                    (e) => setAmountOverpaid(new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: 'USD',
                                                        maximumFractionDigits: 2
                                                    }
                                                    ).format(e.target.value))}
                                                variant="outlined"
                                                InputLabelProps={
                                                    {
                                                        shrink: true
                                                    }
                                                }
                                            />
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={3}
                                                sx={
                                                    {
                                                        ...ts,
                                                        width: '60%',
                                                        m: '1vh auto',
                                                        ml: '3vw'
                                                    }
                                                }
                                                name='overpaidDisclaimer'
                                                id='overpaidDisclaimer'
                                                label='Overpaid Disclaimer'
                                                value={
                                                    `This transaction will be marked OVERPAID. You are ACCEPTING this payment. This transaction has been overpaid by:  ${amountOverpaid}. The overpayment amount entered will be refunded back to the payer.`
                                                }
                                                onChange={
                                                    (e) => setReason(e.target.value)
                                                }
                                                variant="outlined"
                                                InputLabelProps={
                                                    {
                                                        shrink: true
                                                    }
                                                }
                                            />
                                        </Box>
                                    )
                                }
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        display: 'flex',
                                        flex: '1',
                                        mt: '1vh',
                                        m: '1vh',
                                        mr: status === 'Accepted' ? '2vh' : '3.5vh',
                                        float: 'right'
                                    }
                                }
                                onClick={
                                    () => {
                                        setOpen(false);
                                        setReason('');
                                        setAmountOverpaid('');
                                        setOtherReason(false);
                                        setOpenOverPaid(false);
                                    }
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        display: 'flex',
                                        flex: '1',
                                        mt: '1vh',
                                        m: '1vh',
                                        float: 'right'
                                    }
                                }
                                onClick={
                                    () => {
                                        status !== 'Refunded' ? acceptOrRejectPayment(status) : handleRefund(selectedPayment.id);
                                    }
                                }
                            >
                                {status.slice(0, status.length - 2)}
                            </Button>
                        </Box>
                    </Paper>
                </Modal>
            </Paper>
            {
                loading &&
                (
                    <Backdrop
                        open
                        sx={
                            {
                                zIndex: 1
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)'
                                }
                            }
                        >
                            <CircularProgress />
                        </div>
                    </Backdrop>
                )
            }
        </form>
    )
};

export default DesotoPaymentForm;