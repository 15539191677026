import React, { useReducer } from 'react';
import axios from 'axios';

import { Box, Button, TextField, MenuItem, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';
import { stateOptions } from '../../../../../Utils';

const PayeeForm = (props) => {
    const { handleClose, data, modalType, loading, setLoading, ts, rows, setRows, getRows } = props;

    // Vendor Types Dropdown List
    const vendorTypeOptions = [
        {
            label: '',
            value: null,
        },
        {
            label: 'DISBURSE',
            value: 'DISBURSE',
        },
        {
            label: 'REFUND',
            value: 'REFUND',
        },
        {
            label: 'COMPENSATE',
            value: 'COMPENSATE',
        },
    ];

    console.log('modalType: ', modalType)
    // Add Payee Form Initial Values
    const initialState = {
        vendorType: modalType === 'new' ? null : data.vendorType,
        paytoName: modalType === 'new' ? null : data.paytoName,
        paytoAddress1: modalType === 'new' ? null : data.paytoAddress1,
        paytoAddress2: modalType === 'new' ? null : data.paytoAddress2,
        paytoCity: modalType === 'new' ? null : data.paytoCity,
        paytoState: modalType === 'new' ? null : data.paytoState,
        paytoZip: modalType === 'new' ? null : data.paytoZip,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'payToName':
                if (!value) {
                    error = 'Payee name is required';
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    console.log('state:', state)

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const payee = {
                VendorType: state.vendorType,
                PaytoName: state.paytoName,
                PaytoAddress1: state.paytoAddress1,
                PaytoAddress2: state.paytoAddress2,
                PaytoCity: state.paytoCity,
                PaytoState: state.paytoState,
                PaytoZip: state.paytoZip,
            }
            axios.post(`${apiRoute}/api/PayeeTbls`, payee)
                .then((res) => {
                    setRows([...rows, { ...res, data, id: res.data.pkPayeeId }])
                    toast.success('Payee added succefully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error('Error while adding new Payee!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding payee!');
                })
                .finally(() => { setLoading(false); getRows(); })
        } else {
            const payee = {
                ...data,
                VendorType: state.vendorType,
                PaytoName: state.paytoName,
                PaytoAddress1: state.paytoAddress1,
                PaytoAddress2: state.paytoAddress2,
                PaytoCity: state.paytoCity,
                PaytoState: state.paytoState,
                PaytoZip: state.paytoZip,
            }
            axios.put(`${apiRoute}/api/PayeeTbls/${data.id}`, payee)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Payee updated succefully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error('Error while updating Payee!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                })
                .finally(() => { setLoading(false); getRows() })
        }
    };

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>
                            {modalType === 'new' ? 'Add Payee' : 'Edit Payee' }
                        </strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1 / 3} mt={3}>
                            <TextField
                                fullWidth
                                select
                                id="vendorType"
                                name="vendorType"
                                label="Vendor Type"
                                variant="outlined"
                                value={state.vendorType}
                                displayEmpty
                                sx={ts}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                onChange={(e) => {
                                    handleFieldChange('vendorType', e.target.value);
                                }}
                            >
                                {vendorTypeOptions?.map(({value, label}) => (
                                        <MenuItem
                                            key={value}
                                            value={value}
                                            style={{ height: '40px' }}
                                        >
                                            {label}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Box>

                        <Box width={1 / 2} mt={3} pr={3}>
                            <TextField
                                fullWidth
                                required
                                sx={ts}
                                id="paytoName"
                                label="Pay To Name"
                                name="paytoName"
                                value={state.paytoName}
                                onChange={(e) => {
                                    handleFieldChange('paytoName', e.target.value);
                                }}
                                onBlur={(e) => { handleBlur('paytoName', e.target.value) }}
                                helperText={state?.errors?.paytoName}
                                error={!!state?.errors?.paytoName}
                                variant="outlined"
                                InputLabelProps={{ shrink: true }}
                            />
                        </Box>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={1 / 2} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="paytoAddress1"
                                    label="Pay To Address 1"
                                    name="paytoAddress1"
                                    value={state.paytoAddress1}
                                    onChange={(e) => {
                                        handleFieldChange('paytoAddress1', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={1 / 2} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="paytoAddress2"
                                    label="Pay To Address 2"
                                    name="paytoAddress2"
                                    value={state.paytoAddress2}
                                    onChange={(e) => {
                                        handleFieldChange('paytoAddress2', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={1 / 3} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="paytoCity"
                                    label="City"
                                    name="paytoCity"
                                    value={state.paytoCity}
                                    onChange={(e) => {
                                        handleFieldChange('paytoCity', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>

                            <Box width={1 / 3} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    select
                                    sx={ts}
                                    id="paytoState"
                                    label="State"
                                    name="paytoState"
                                    value={state.paytoState}
                                    onChange={(e) => {
                                        handleFieldChange('paytoState', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {
                                                    maxHeight: '30em',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    {
                                        stateOptions?.map(({ value, label }) => (
                                            <MenuItem key={label} value={value}>{label}</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Box>

                            <Box width={1 / 3} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="paytoZip"
                                    label="Zip"
                                    name="paytoZip"
                                    value={state.paytoZip}
                                    onChange={(e) => {
                                        handleFieldChange('paytoZip', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default PayeeForm;
