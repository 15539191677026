import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, MenuItem, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';
import { apiRoute } from '../../../../../App';

const CourtForm = (props) => {
    const { handleClose, data, modalType, setLoading, loading, ts, rows, setRows, getRows } = props;

    const [states, setStates] = useState([]);
    useEffect(() => {
        loadStates();
    }, []);

    const loadStates = () => {
        axios.get(`${apiRoute}/api/LuCodes/default/STAT`)
            .then(({ data }) => {
                const sortedStates = data.sort((a, b) => a.codeTitle.localeCompare(b.codeTitle))
                setStates(sortedStates)
            })
            .catch(err => {
                toast.error("Something Went Wrong", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
    }

    // County Types Dropdown List
    const parishCountyTypeOptions = [
        {
            label: '',
            value: null,
        },
        {
            label: 'FED',
            value: 'FED',
        },
        {
            label: 'PARB',
            value: 'PARB',
        },
    ];

    const initialState = {
        courtName: modalType === 'add' ? null : data.courtName,
        courtNum: modalType === 'add' ? null : data.courtNum,
        displayCourtName: modalType === 'add' ? null : data.displayCourtName,
        address1: modalType === 'add' ? null : data.address1,
        address2: modalType === 'add' ? null : data.address2,
        city: modalType === 'add' ? null : data.city,
        state: modalType === 'add' ? null : data.state,
        zipcode: modalType === 'add' ? null : data.zipcode,
        parishCountyType: modalType === 'add' ? null : data.parishCountyType,
        parishCountyName: modalType === 'add' ? null : data.parishCountyName,
        districtName: modalType === 'add' ? null : data.districtName,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'courtName':
                if (!value) {
                    error = 'Description is required';
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const court = {
                CourtName: state.courtName,
                CourtNum: state.courtNum,
                DisplayCourtName: state.displayCourtName,
                Address1: state.address1,
                Address2: state.address2,
                City: state.city,
                State: state.state,
                Zipcode: state.zipcode,
                ParishCountyType: state.parishCountyType,
                ParishCountyName: state.parishCountyName,
                DistrictName: state.districtName,
            }
            axios.post(`${apiRoute}/api/CourtTbls`, court)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.pkCourtId }])
                    toast.success('Court added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while creating court!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding court!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const court = {
                ...data,
                CourtName: state.courtName,
                CourtNum: state.courtNum,
                DisplayCourtName: state.displayCourtName,
                Address1: state.address1,
                Address2: state.address2,
                City: state.city,
                State: state.state,
                Zipcode: state.zipcode,
                ParishCountyType: state.parishCountyType,
                ParishCountyName: state.parishCountyName,
                DistrictName: state.districtName,
            }
            axios.put(`${apiRoute}/api/CourtTbls/${data.id}`, court)
                .then((res) => {
                    const updatedRows = rows.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Court updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating court!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating court');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>
                            {modalType === 'add' ? 'Add Court' : 'Edit Court'}
                        </strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="courtName"
                                    label="Court Name"
                                    name="courtName"
                                    value={state.courtName}
                                    onChange={(e) => {
                                        handleFieldChange('courtName', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('courtName', e.target.value)}
                                    helperText={state?.errors?.courtName}
                                    error={!!state?.errors?.courtName}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="displayCourtName"
                                    label="Display Court Name"
                                    name="displayCourtName"
                                    value={state.displayCourtName}
                                    onChange={(e) => {
                                        handleFieldChange('displayCourtName', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="courtNum"
                                    label="Court Number"
                                    name="courtNum"
                                    value={state.courtNum}
                                    onChange={(e) => {
                                        handleFieldChange('courtNum', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="address1"
                                    label="Address 1"
                                    name="address1"
                                    value={state.address1}
                                    onChange={(e) => {
                                        handleFieldChange('address1', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 2]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="address2"
                                    label="Address 2"
                                    name="address2"
                                    value={state.address2}
                                    onChange={(e) => {
                                        handleFieldChange('address2', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="city"
                                    label="City"
                                    name="city"
                                    value={state.city}
                                    onChange={(e) => {
                                        handleFieldChange('city', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>                              
                                <TextField
                                    required
                                    fullWidth
                                    sx={ts}
                                    select
                                    displayEmpty
                                    id="state"
                                    label="State"
                                    name="state"
                                    value={state?.state}
                                    onChange={(e) => handleFieldChange('state', e.target.value)}
                                    onBlur={(e) => handleBlur('state', e.target.value)}
                                    helperText={state?.errors?.state}
                                    error={!!state?.errors?.state}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    SelectProps={{
                                        MenuProps: {
                                            PaperProps: {
                                                style: {

                                                    height: '40vh',
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <MenuItem value={''}>N/A</MenuItem>
                                    {
                                        states?.map(({ codeValue, codeTitle },) => {
                                            return <MenuItem key={codeTitle} value={codeValue}>{codeTitle}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="zipcode"
                                    label="Zip"
                                    name="zipcode"
                                    value={state.zipcode}
                                    onChange={(e) => {
                                        handleFieldChange('zipcode', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    sx={ts}
                                    id="parishCountyType"
                                    label="Parish County Type"
                                    name="parishCountyType"
                                    value={state.parishCountyType}
                                    onChange={(e) => handleFieldChange('parishCountyType', e.target.value)}
                                    select
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                >
                                    {parishCountyTypeOptions?.map(({ value, label }) => (
                                        <MenuItem key={value} value={value} style={{ height: '40px' }}>
                                            {label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="parishCountyName"
                                    label="Parish Name"
                                    name="Parish County Name"
                                    value={state.parishCountyName}
                                    onChange={(e) => {
                                        handleFieldChange('parishCountyName', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box width={[1, 1, 1 / 3]} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="districtName"
                                    label="District Name"
                                    name="districtName"
                                    value={state.districtName}
                                    onChange={(e) => {
                                        handleFieldChange('districtName', e.target.value);
                                    }}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default CourtForm;