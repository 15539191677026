import { Box, Button, LinearProgress, Paper, TextField, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';


const OpenBatchModal = (props) => {
    const {
        ts,
        newBatch,
        batchDate,
        setBatchDate,
        handleBatchOpen,
        handleClose,
        modalType,
        selectedRow,
        loading,
        batchName,
        setBatchName,
        isPaymentExists,
    } = props;
    const modalTypes = {
        openBatch: 'openBatch',
        reopenBatch: 'reopenBatch'
    }
    const [batchNameError, setBatchNameError] = useState(false);

    const handleBatchNameError = (value) => {
        const regex = /^[a-zA-Z0-9/_ ]*$/;
        if (!regex.test(value))
            setBatchNameError(true);
        else
            setBatchNameError(false);
    }
    
    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                <h1>
                    <strong>{modalType === modalTypes.openBatch ? 'Open Batch' : 'Reopen Batch'}</strong>
                </h1>
            </Paper>
            <Box sx={{ p: '1vh' }}>
                <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                    <h3>
                        {
                            modalType === 'openBatch'
                                ? `Enter batch details`
                                : `Do you want to Reopen batch ${selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})` : selectedRow?.batchNumber} ?`
                        }
                    </h3>
                </Box>
                <hr />
                <Box>
                    <TextField
                        required
                        fullWidth
                        onBlur={(e) => handleBatchNameError(e.target.value)}
                        error={batchNameError}
                        sx={ts}
                        maxLength={32}
                        id="batchName"
                        label="Batch Name"
                        variant="outlined"
                        value={modalType === 'openBatch' ? batchName : selectedRow?.batchName}
                        disabled={modalType !== modalTypes.openBatch}
                        onChange={(e) => setBatchName(e.target.value)}
                    />
                    <hr />
                    {
                     (modalType === modalTypes.reopenBatch && isPaymentExists) ? 
                     <Tooltip title={"The batch date cannot be changed due to associated payment records."}> 
                    <TextField
                        fullWidth
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                        label="Select Date"
                        type="date"
                        value={modalType === 'openBatch'   ? batchDate : isPaymentExists ? dayjs(selectedRow?.openDatetime).format("YYYY-MM-DD") :batchDate }
                        disabled={(modalType !== 'openBatch' &&  isPaymentExists)}
                        onChange={(e) => setBatchDate(e.target.value)}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                    />
                        </Tooltip> : 
                    <TextField
                    fullWidth
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    label="Select Date"
                    type="date"
                    value={modalType === 'openBatch'   ? batchDate : isPaymentExists ? dayjs(selectedRow?.openDatetime).format("YYYY-MM-DD") :batchDate }
                    disabled={(modalType !== 'openBatch' &&  isPaymentExists)}
                    onChange={(e) => setBatchDate(e.target.value)}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                />
                    }
                </Box>
                <hr />
                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                    <Button
                        onClick={handleBatchOpen}
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        disabled={ modalType === modalTypes.openBatch ?  (batchName?.length === 0 || batchNameError) || isNaN(dayjs(batchDate).$D) : isNaN(dayjs(batchDate).$D || isPaymentExists )}
                    >
                        Open Batch
                    </Button>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
            {
                loading && <LinearProgress />
            }
        </Paper>
    );
};

export default OpenBatchModal;