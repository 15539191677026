import { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, Routes, Route } from "react-router-dom";

import Login from "./components/users/Login";
import DesotoLayout from "./components/DesotoLayout";
import ResetPassword from "./components/users/ResetPassword";
import ForgotPassword from "./components/users/ForgotPassword";
import UserContext from "./context/UserContext";
import UtilitiesView from "./components/utilities/views/UtilitiesView";
import DesotoHome from "./pages/DesotoHome";
import DesotoPayment from "./pages/DesotoPayment";

 
const DesotoRoutes = () => {
    const hasToken = localStorage.getItem('token') !== null;

    const resetToken = localStorage.getItem('passwordToken') !== null;

    const { userId, adminCheck } = useContext(UserContext);

    const [utilInfo, setUtilInfo] = useState([]);


    const routes = [
        {
            path: "/login",
            element: !hasToken ? <Login /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "/forgotpassword",
            element: !hasToken ? <ForgotPassword /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "/resetpassword/:token",
            element: resetToken ? <ResetPassword /> : <Navigate to="/" />,
            layout: false,
        },
        {
            path: "/",
            element: hasToken ? <DesotoHome /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "/addpayment",
            element: hasToken ? <DesotoPayment /> : <Navigate to="/login" />,
            layout: true,
        },
        {
            path: "*",
            element: !hasToken ? <Navigate to="/" /> : <Navigate to="/login" />,
        },
        {
            path: `/util/:utilRoute`,
            element: hasToken && adminCheck ? <UtilitiesView utilInfo={utilInfo} /> : <Navigate to="/login" />,
            layout: true,
        },
    ];

    return (
        <Routes>
            {routes.map((route, index) => {
                const { path, element, layout } = route;
                const RouteComponent = layout ? (
                    <Route key={index} path={path} element={<DesotoLayout setUtilInfo={setUtilInfo}>{element}</DesotoLayout>} />
                ) : (
                    <Route key={index} path={path} element={element} />
                );
                return RouteComponent;
            })}
        </Routes>
    );
};

export default DesotoRoutes;