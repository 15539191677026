import React, { useState, useContext, useReducer, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Box, Grid, Button, TextField, FormControlLabel, Radio, RadioGroup, Paper, Modal, InputAdornment } from '@mui/material';
import { toast } from 'react-toastify';
import { MdEditOff } from "react-icons/md";
import { BatchContext } from '../../../../context/BatchMgmtContext';
import { apiRoute } from '../../../../App';
import { CaseContext } from '../../../../context/CaseContext';
import CardSwipeModal from './CardSwipeModal';
import UserContext from '../../../../context/UserContext';
import { commaMoneyFormat } from '../../../../Utils';

const CreditCardModal = (props) => {
    const {
        ts,
        violation,
        setViolation,
        setLoading,
        loading,
        quickCheck,
        updateCaseBalance,
        handleClose,
        setNoViolation,
        violationBalance,
        convenienceFee,
        setConvenienceFee,
        convenienceRate,
        grandTotal,
        setGrandTotal,
        setCcPaymentAmount,
        setReceiptNumber,
        handleOpen,
        setMasterReceipt,
        isCCPayment,
        selectedViolations
    } = props;

    const { citationView, setCaseHistoryRows } = useContext(CaseContext);

    const { paymentBatchNumber, setPaymentBatchNumber, openBatches } = useContext(BatchContext);

    const { paymentEmail, shiftDate, userId } = useContext(UserContext);

    const [hidData, setHidData] = useState();

    const { caseNumber } = useParams();

    const initialPaymentState = {
        firstName: '',
        lastName: '',
        email: '',
        creditCardNumber: '',
        month: '',
        year: '',
        securityCode: '',
        billingAddress: '',
        city: '',
        state: '',
        zipCode: '',
        amountReceived: '$0.00',
        paymentAmount: '$0.00',
        errors: {},
    };

    const fieldValidations = {
        amountReceived: {
            errorRequired: 'Amount Received Required',
            validation: (value) => !value || value === '0.00',
        },
        paymentAmount: {
            errorRequired: 'Payment Amount Required',
            validation: (value) => !value || value === '0.00',
        },
        firstName: {
            errorRequired: 'First Name Required',
            validation: (value) => !value,
        },
        lastName: {
            errorRequired: 'Last Name Required',
            validation: (value) => !value,
        },
        billingAddress: {
            errorRequired: 'Billing Address Required',
            validation: (value) => !value || !/^[0-9A-Za-z\s.,'-]+$/.test(value),
        },
        state: {
            errorRequired: 'State Required',
            errorFormat: 'Value must be 2 characters long',
            validation: (value) => !value || ((typeof value === 'string') && value.length === 2),
        },
        city: {
            errorRequired: 'City Required',
            validation: (value) => !value || (typeof value !== 'string'),
        },
        email: {
            errorFormat: 'Please enter a valid email address',
            validation: (value) => /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value),
        },
        securityCode: {
            errorRequired: 'Security Code Required',
            errorFormat: 'Please enter a valid security code',
            validation: (value) => !value || /^\d{3,4}$/.test(value.replace(/\D/g, '')),
        },
        zipCode: {
            errorRequired: 'Zip Code Required',
            errorFormat: 'Please enter a valid zip code',
            validation: (value) => !value || /^\d{5}(-\d{4})?$/.test(value),
        },
        month: {
            errorRequired: 'Month Required',
            errorFormat: 'Please enter a valid two-digit number',
            validation: (value) => {
                if (!value) return false; // Value is required
                const isNumeric = /^\d+$/.test(value); // Check if it's numeric
                const isCorrectLength = value.length === 2; // Check if the length is 2
                return isNumeric && isCorrectLength; // Both conditions must be true
            },
        },
        year: {
            errorRequired: 'Year Required',
            errorFormat: 'Please enter a valid four-digit number',
            validation: (value) => {
                if (!value) return false; // Value is required
                const isNumeric = /^\d+$/.test(value); // Check if it's numeric
                const isCorrectLength = value.length === 4; // Check if the length is 4
                return isNumeric && isCorrectLength; // Both conditions must be true
            },
        },
        creditCardNumber: {
            errorRequired: 'Credit Card Number Required',
            errorFormat: 'Please enter a valid Credit Card number',
            validation: (value) => !value || /^\d+$/.test(value.replace(/\s/g, '')),
        },
    };

    const handleBlur = (field, value) => {
        // Perform validation and set error messages based on field type
        const fieldInfo = fieldValidations[field];
        let error = null;

        if (fieldInfo) {
            if (!value) {
                error = fieldInfo.errorRequired;
            } else if (fieldInfo.errorFormat && !fieldInfo.validation(value)) {
                error = fieldInfo.errorFormat;
            }
        }

        // Dispatch the UPDATE_FIELD action with the error information
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const [state, dispatch] = useReducer(reducer, initialPaymentState);

    const [displayReceived, setDisplayReceived] = useState('$0.00');
    const [displayPayment, setDisplayPayment] = useState('$0.00');

    // COMPONENT STATES
    const [page, setPage] = useState('paymentInfo');
    const [swiper, setSwiper] = useState(false);

    // CARD SWIPE MODAL
    const [cardSwipeModal, setCardSwipeModal] = useState(false);
    const [paymentRef, setPaymentRef] = useState([]);

    const submitCCPayment = async (payment, cardData) => {
        const creditCardNumber = !!cardData ? cardData.creditCardNumber.replace(/\s/g, '') : state.creditCardNumber.replace(/\s/g, '');
        const ccPaymentData = {
            CardHolderEmail: state?.email ?? "",
            CardHolderPhone: '',
            CardHolderName: !!cardData ? `${cardData.firstName} ${cardData.lastName}` : `${state.firstName} ${state.lastName}`,
            CardHolderAddressLine1: state.billingAddress,
            CardHolderCity: state.city,
            CardHolderState: state.state,
            CardHolderZip: state.zipCode,
            PaymentAmount: payment.PaymentAmount,
            firstName: !!cardData ? cardData.firstName : state.firstName,
            lastName: !!cardData ? cardData.lastName : state.lastName,
            CreditCardNumber: creditCardNumber,
            month: !!cardData ? cardData.month : state.month,
            year: !!cardData ? cardData.year : state.year,
            securityCode: state.securityCode,
            IsPartialPayment: payment.paymentAmount < violationBalance ? 'Y' : 'N',
            FkCaseId: caseNumber,
            ConvenienceFee: convenienceFee,
            PaymentOffset: 0.00,
        };

        const paymentDataArray = [{
            PaymentAmount: payment.PaymentAmount - ccPaymentData.ConvenienceFee,
            IsPartialPayment: payment.paymentAmount < violationBalance ? 'Y' : 'N',
            PaymentType: "CREDIT CARD",
            ShiftDate: shiftDate,
            FkCaseId: caseNumber,
            BatchNumber: paymentBatchNumber,
            PayToName: `${ccPaymentData.firstName} ${ccPaymentData.lastName}`,
            PayToAddress1: state.billingAddress,
            PayToCity: state.city,
            PayToState: state.state,
            PayToZip: state.zipCode,
        }];

        const ccPaymentObj = {
            ccPaymentData: ccPaymentData,
            violations: selectedViolations,
            ffPaymentVm: paymentDataArray
        };

        paymentDataArray[0]["MasterReceipt"] = 0;


        payment.PayToName = `${ccPaymentData.firstName} ${ccPaymentData.lastName}`

        try {

            const res = await axios.post(`${apiRoute}/api/CcPayments/${ccPaymentData.CreditCardNumber}/${ccPaymentData.month}/${ccPaymentData.year}/${ccPaymentData.securityCode}/${ccPaymentData.firstName}/${ccPaymentData.lastName}/${citationView.entityId}`, ccPaymentObj);

            if (res.data.responseObject.responseCode !== "100") {
                toast.error(`${res.data.responseText}`, {
                    position: "top-right",
                    autoClose: 10000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // The POST request/payment failed
                console.error('Failed to create payment', res.data);
                return false;
            }
            if (res.data.responseObject.responseCode === "100") {

                const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
                const paymentDataWithId = paymentRes.data.map((row) => ({
                    ...row,
                    id: row.pkFfPaymentId,
                    paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                    dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
                }));
                setCardSwipeModal(false);
                setCaseHistoryRows(paymentDataWithId);
                setMasterReceipt(res.data.masterReceipt);
                setReceiptNumber(paymentDataWithId[paymentDataWithId.length - 1].receiptNum);
                await updateCaseBalance();
                handleOpen('printReceipt', '30%');
                toast.success('Payments added', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });

                return true;
            }
        } catch (error) {
            toast.error(`Error occurred while creating payment. ${error?.response?.data}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.error('Error occurred while creating payment', error);
            return false;
        };
    };

    const createPayment = async (e, cardData, first, last) => {
        e.preventDefault();
        if (!paymentBatchNumber) {
            toast.error('Please select a batch for these payments', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoading(true);

        try {
            // Prepare payment data array
            const paymentData = {
                FkCaseId: caseNumber,
                FkViolationId: violation.pkViolationId,
                PaymentRef: violation.currentStatute,
                PaymentAmount: grandTotal,
                ShiftDate: shiftDate,
                PaymentType: "CREDIT CARD",
                BatchNumber: paymentBatchNumber,
                IsPartialPayment: parseFloat(state.paymentAmount) < parseFloat(violation.remainingBalance) ? 'Y' : 'N',
                PayToName: !!first && !!last ? `${cardData.firstName} ${cardData.lastName}` : `${first} ${last}`,
                PayToAddress1: state.billingAddress,
                PayToCity: state.city,
                PayToState: state.state,
                PayToZip: state.zipCode,
                MasterReceipt: ''
            };

            if (parseFloat(paymentData.PaymentAmount) < 0.01) {
                toast.error('Please enter a valid payment amount', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return;
            };

            //Wrap the submitCCPayment call in a try-catch block
            try {
                await submitCCPayment(paymentData, cardData);
            } catch (submitError) {
                // Handle the error from submitCCPayment
                setLoading(false);
                toast.error(`Error occurred while submitting payment. ${submitError}`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                console.error('Error occurred while submitting payment', submitError); // Propagate the error up to createPayment
                return;
            }
        } catch (err) {
            if (err?.response?.data === 'Payment with the same details already exists.') {
                toast.error('Payment failed. Duplicate payment', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else if (err?.response?.data === 'Partial Payment is not allowed on this violation.') {
                toast.error('Partial Payment is not allowed on this violation.', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else {
                toast.error(`Payment failed. ${err?.response?.data}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const createSwipePayment = async (cardData, first, last) => {
        if (!paymentBatchNumber) {
            toast.error('Please select a batch for these payments', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoading(true);

        try {
            // Prepare payment data array
            const paymentData = {
                FkCaseId: caseNumber,
                FkViolationId: violation.pkViolationId,
                PaymentRef: violation.currentStatute,
                PaymentAmount: grandTotal - convenienceFee,
                ShiftDate: shiftDate,
                PaymentType: "CREDIT CARD",
                BatchNumber: paymentBatchNumber,
                IsPartialPayment: parseFloat(state.paymentAmount) < parseFloat(violation.remainingBalance) ? 'Y' : 'N',
                PayToName: !!cardData ? `${cardData.firstName} ${cardData.lastName}` : `${first} ${last}`,
                PayToAddress1: state.billingAddress,
                PayToCity: state.city,
                PayToState: state.state,
                PayToZip: state.zipCode,
            };

            if (parseFloat(paymentData.PaymentAmount) < 0.01) {
                toast.error('Please enter a valid payment amount', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                return;
            };
            paymentData.ConvenienceFee = convenienceFee;
            let ccPaymentObj = {};
            if (isCCPayment) {
                ccPaymentObj = {
                    violations: selectedViolations,
                    ffPaymentVm: [paymentData],
                }
            }
            const res = !quickCheck
                ? isCCPayment ? await axios.post(`${apiRoute}/api/FfPaymentTbls/ccpayment`, ccPaymentObj) :
                    await axios.post(`${apiRoute}/api/FfPaymentTbls`, paymentData)
                : await axios.post(`${apiRoute}/api/ffpaymenttbls/quick/${paymentData.PaymentAmount}`, paymentData)

            if (quickCheck) {
                if (res.data > 0) {
                    const newPayment = {
                        ...paymentData,
                        paymentAmount: parseFloat(res.data)
                    };

                    await axios.post(`${apiRoute}/api/ffpaymenttbls/quick/${newPayment.PaymentAmount}`, newPayment)
                }
            }

            const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
            const paymentDataWithId = paymentRes.data.map((row) => ({
                ...row,
                id: row.pkFfPaymentId,
                paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
            }));
            setCardSwipeModal(false)
            setCaseHistoryRows(paymentDataWithId);
            await updateCaseBalance();
            handleClose();
            toast.success('Payments added', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            if (err?.response?.data === 'Payment with the same details already exists.') {
                toast.error('Payment failed. Duplicate payment', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else if (err?.response?.data === 'Partial Payment is not allowed on this violation.') {
                toast.error('Partial Payment is not allowed on this violation.', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } else {
                toast.error(`Payment failed. ${err?.response?.data}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            }
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSwipe = () => {
        const swiperBody = {
            DeviceID: '',
            WaitTime: '120',
            ConnectionType: '4',
            FieldSeparator: '|',
            AdditionalRequestData: null,
        };

        const options = {
            headers: null,
        }

        const value = axios.post('http://localhost:9002/api/mtscrahost/RequestCardSwipe', swiperBody, options)
        value.then(val => setHidData(val.data.CardSwipeOutput));
        return value?.response;
    }

    useEffect(() => {
        state.paymentAmount > 0 ? setConvenienceFee((state.paymentAmount * (convenienceRate / 100))) : setConvenienceFee(0.00)
    }, [state.paymentAmount]);

    useEffect(() => {
        state.paymentAmount > 0 ? setGrandTotal((+state.paymentAmount + +convenienceFee.toFixed(2))) : setGrandTotal(0.00)
    }, [state.paymentAmount, convenienceFee]);

    useEffect(() => {
        if (violation) {
            handleFieldChange('paymentRef', violation.currentStatute);
        }
    }, [violation]);

    const changePage = (button) => {
        if (button === 'forms') {
            if (page === 'paymentInfo') {
                if (!state.paymentAmount || !state.amountReceived || parseFloat(state.paymentAmount) < .01 || parseFloat(state.amountReceived) < .01 || violation === 'none' || paymentBatchNumber === '') {
                    if (selectedViolations.length === 0) {
                        setNoViolation('Select which violation this payment is for above');
                    }
                    if (paymentBatchNumber === '' && !!state.paymentAmount && !!state.amountReceived && parseFloat(state.paymentAmount) >= .01 && parseFloat(state.amountReceived) >= .01 && violation !== 'none') {
                        toast.error(`Please select a batch in order to continue`, {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: 'colored',
                        });
                    }
                    handleBlur('amountReceived', state.amountReceived);
                    handleBlur('paymentAmount', state.paymentAmount);
                    handleBlur('paymentRef', violation.currentStatue);
                    setPage('creditCardInfo');
                } else {
                    setPage('creditCardInfo');
                }
            }
            if (page === 'creditCardInfo') {
                setPage('paymentInfo');
            }
        } else if (button === 'swipe') {
            if (!state.billingAddress || !state.state || !state.city || !state.zipCode || state.securityCode) {
                handleBlur('billingAddress', state.billingAddress);
                handleBlur('city', state.city);
                handleBlur('state', state.state);
                handleBlur('zipCode', state.zipCode);
                handleBlur('securityCode', state.securityCode)
            } else {
                setCardSwipeModal(true);
            }
        }
    };

    // checks the openBatches array to see if there is only 1 batch, if there is set the paymentBatchNumber to that batch number
    useEffect(() => {
        if (openBatches.length === 1) {
            setPaymentBatchNumber(openBatches[0]?.batchNumber);
        }
    }, [openBatches, setPaymentBatchNumber]);

    useEffect(() => {
        setPaymentRef(selectedViolations.map((sv) => sv.currentStatute))
    }, [selectedViolations]);

    return (
        <form onSubmit={createPayment} style={{ padding: '.5vh' }}>
            <Paper elevation={10} mb={'1vh'}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: 'auto', display: 'flex' }} elevation={10}>
                    {
                        page === 'paymentInfo'
                            ? <h6 style={{ marginBottom: '0px' }}>Payment Info</h6>
                            : <h6 style={{ marginBottom: '0px' }}>Credit Card Info</h6>
                    }
                </Paper>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <h5 style={{ marginTop: '.75vh', marginLeft: '1rem' }}>Batches:</h5>
                        <RadioGroup
                            row
                            name='searchFilter'
                            value={paymentBatchNumber}
                            onChange={(e) => setPaymentBatchNumber(e.target.value)}
                            sx={{ ml: '1vh' }}
                        >
                            {
                                // destructured batchNumber out of batch object to make this easier to read
                                openBatches.sort((a, b) => a.batchNumber < b.batchNumber ? -1 : 1)
                                    .filter((ob) => ob.fkUserId.toString() === userId)
                                    .map(({ batchName, batchNumber }) =>
                                        <FormControlLabel
                                            key={batchNumber}
                                            value={batchNumber}
                                            control={<Radio />}
                                            label={batchName ? `${batchName}(${batchNumber})` : batchNumber}
                                        />
                                    )
                            }
                        </RadioGroup>
                    </Box>
                    <Button
                        variant="contained"
                        sx={{ m: ".5vh", backgroundColor: 'steelblue', color: 'white', mt: '.75vh' }}
                        onClick={() => changePage('forms')}
                    >
                        {
                            page === 'paymentInfo' ? 'Go to credit card info -->' : '<-- Go back to payment info'
                        }
                    </Button>
                </Box>
                <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1vh' }}>
                    {
                        page === 'paymentInfo' && (
                            <>
                                <Grid item sm={2}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={ts}
                                            id="paymentType"
                                            label="Type"
                                            variant="outlined"
                                            fullWidth
                                            value="CREDIT CARD"
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ readOnly: true }}
                                        />
                                    </Box>
                                </Grid>
                                {
                                    quickCheck === false &&
                                    <Grid item sm={1.25}>
                                        <Box m={.5}>
                                            <TextField
                                                sx={ts}
                                                fullWidth
                                                id="paymentRef"
                                                label="Reference"
                                                variant="outlined"
                                                value={isCCPayment ? paymentRef.join(', ') : violation.currentStatute}
                                                InputLabelProps={{ shrink: true }}
                                                inputProps={{ readOnly: true }}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <MdEditOff />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                }
                                <Grid item sm={1.75}>
                                    <Box m={0.5}>
                                        <TextField
                                            required
                                            sx={ts}
                                            id='amountReceived'
                                            label="Amount Received"
                                            variant="outlined"
                                            fullWidth
                                            value={displayReceived === '$NaN' ? '$0.00' : displayReceived}
                                            onClick={(e) => setDisplayReceived('')}
                                            onChange={(e) => {
                                                const value = e.target.value.replace('$', ''); // Remove the '$' if it's present
                                                //handleFieldChange('amountReceived', isNaN(value) ? '0.00' : value);
                                                setDisplayReceived(e.target.value);
                                                setDisplayPayment(commaMoneyFormat(e.target.value));
                                                setCcPaymentAmount(isNaN(parseFloat(value).toFixed(2)) ? '0.00' : parseFloat(value).toFixed(2));
                                            }}
                                            onBlur={(e) => {
                                                //const value = e.target.value.replace('$', '');
                                                handleBlur('amountReceived', e.target.value);
                                                handleFieldChange('amountReceived', e.target.value)
                                                setDisplayReceived(commaMoneyFormat(e.target.value))
                                                handleFieldChange('paymentAmount', e.target.value)
                                                setDisplayPayment(commaMoneyFormat(e.target.value))
                                            }}
                                            helperText={state?.errors?.amountReceived}
                                            error={!!state?.errors?.amountReceived}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                style: { textAlign: 'right' },
                                                inputMode: 'numeric',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={1.75}>
                                    <Box m={.5}>
                                        <TextField
                                            required
                                            sx={ts}
                                            id='paymentAmount'
                                            label="Amount To Apply"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            value={(displayReceived === '$NaN' && displayPayment === '$NaN') ? '$0.00' : displayPayment === '$NaN' ? displayReceived : displayPayment}
                                            onClick={(e) => {
                                                setDisplayPayment('')
                                                setCcPaymentAmount(0);
                                            }}
                                            onChange={(e) => {
                                                const value = e.target.value; // Remove the '$' if it's present
                                                //handleFieldChange('paymentAmount', isNaN(value) ? '0.00' : value)
                                                setDisplayPayment(value)
                                                setCcPaymentAmount(isNaN(parseFloat(value).toFixed(2)) ? '0.00' : parseFloat(value).toFixed(2));
                                                setConvenienceFee(convenienceFee);
                                            }}
                                            onBlur={(e) => {
                                                const value = e.target.value;
                                                setDisplayPayment(commaMoneyFormat(value));
                                                handleBlur('paymentAmount', value);
                                                handleFieldChange('paymentAmount', value);
                                            }}
                                            helperText={state?.errors?.paymentAmount}
                                            error={!!state?.errors?.paymentAmount}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                style: { textAlign: 'right' },
                                                inputMode: 'numeric',
                                            }}

                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={ts}
                                            id='convenienceFee'
                                            label="Convenience Fee"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            value={commaMoneyFormat(convenienceFee)}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                style: { textAlign: 'right' },
                                                readOnly: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MdEditOff />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item sm={2}>
                                    <Box m={.5}>
                                        <TextField
                                            sx={ts}
                                            id='grandTotal'
                                            label="Grand Total"
                                            variant="outlined"
                                            fullWidth
                                            disabled
                                            value={commaMoneyFormat(grandTotal)}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{
                                                style: { textAlign: 'right' },
                                                readOnly: true
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MdEditOff />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            </>
                        )
                    }
                    {
                        page === 'creditCardInfo' && (
                            <>
                                <RadioGroup
                                    row
                                    name='searchFilter'
                                    value={swiper}
                                    onChange={() => setSwiper(!swiper)}
                                    sx={{ ml: '1vh' }}
                                >
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label='Manually Enter Card Info'
                                        checked={!swiper}
                                    />
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label='Swipe Card'
                                        checked={swiper}
                                    />
                                </RadioGroup>
                                <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1vh', maxHeight: '35vh', overflowY: 'auto' }}>
                                    {
                                        !swiper && (
                                            <>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='firstName'
                                                            label="First Name"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.firstName}
                                                            onChange={(e) => handleFieldChange('firstName', e.target.value)}
                                                            onBlur={(e) => handleBlur('firstName', e.target.value)}
                                                            helperText={state?.errors?.firstName}
                                                            error={!!state?.errors?.firstName}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='lastName'
                                                            label="Last Name"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.lastName}
                                                            onChange={(e) => handleFieldChange('lastName', e.target.value)}
                                                            onBlur={(e) => handleBlur('lastName', e.target.value)}
                                                            helperText={state?.errors?.lastName}
                                                            error={!!state?.errors?.lastName}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            sx={ts}
                                                            id='email'
                                                            label="Email"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state?.email}
                                                            onChange={(e) => handleFieldChange('email', e.target.value)}
                                                            onBlur={(e) => handleBlur('email', e.target.value)}
                                                            helperText={state?.errors?.email}
                                                            error={!!state?.errors?.email}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={9.25}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='creditCardNumber'
                                                            label="Credit Card Number"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.creditCardNumber}
                                                            onChange={(e) => handleFieldChange('creditCardNumber', e.target.value)}
                                                            onBlur={(e) => handleBlur('creditCardNumber', e.target.value)}
                                                            helperText={state?.errors?.creditCardNumber}
                                                            error={!!state?.errors?.creditCardNumber}
                                                            inputProps={{ maxLength: '19' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='month'
                                                            label="Month"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.month}
                                                            onChange={(e) => handleFieldChange('month', e.target.value)}
                                                            onBlur={(e) => handleBlur('month', e.target.value)}
                                                            helperText={state?.errors?.month}
                                                            error={!!state?.errors?.month}
                                                            inputProps={{ maxLength: '2' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='year'
                                                            label="Year"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.year}
                                                            onChange={(e) => handleFieldChange('year', e.target.value)}
                                                            onBlur={(e) => handleBlur('year', e.target.value)}
                                                            helperText={state?.errors?.year}
                                                            error={!!state?.errors?.year}
                                                            inputProps={{ maxLength: '4' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='securityCode'
                                                            label="CVV/CVC"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.securityCode}
                                                            onChange={(e) => handleFieldChange('securityCode', e.target.value)}
                                                            onBlur={(e) => handleBlur('securityCode', e.target.value)}
                                                            helperText={state?.errors?.securityCode}
                                                            error={!!state?.errors?.securityCode}
                                                            inputProps={{ maxLength: '4' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    {
                                        swiper && (
                                            <>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            sx={ts}
                                                            id='email'
                                                            label="Email"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state?.email}
                                                            onChange={(e) => handleFieldChange('email', e.target.value)}
                                                            onBlur={(e) => handleBlur('email', e.target.value)}
                                                            helperText={state?.errors?.email}
                                                            error={!!state?.errors?.email}
                                                        />
                                                    </Box>
                                                </Grid>
                                                <Grid item sm={3}>
                                                    <Box m={.5}>
                                                        <TextField
                                                            required
                                                            sx={ts}
                                                            id='securityCode'
                                                            label="CVV/CVC"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={state.securityCode}
                                                            onChange={(e) => handleFieldChange('securityCode', e.target.value)}
                                                            onBlur={(e) => handleBlur('securityCode', e.target.value)}
                                                            helperText={state?.errors?.securityCode}
                                                            error={!!state?.errors?.securityCode}
                                                            inputProps={{ maxLength: '4' }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>
                                        )
                                    }
                                    <Grid item sm={9.25}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='billingAddress'
                                                label="Billing Address"
                                                variant="outlined"
                                                fullWidth
                                                value={state.billingAddress}
                                                onChange={(e) => handleFieldChange('billingAddress', e.target.value)}
                                                onBlur={(e) => handleBlur('billingAddress', e.target.value)}
                                                helperText={state?.errors?.billingAddress}
                                                error={!!state?.errors?.billingAddress}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='city'
                                                label="City"
                                                variant="outlined"
                                                fullWidth
                                                value={state.city}
                                                onChange={(e) => handleFieldChange('city', e.target.value)}
                                                onBlur={(e) => handleBlur('city', e.target.value)}
                                                helperText={state?.errors?.city}
                                                error={!!state?.errors?.city}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='state'
                                                label="State"
                                                variant="outlined"
                                                fullWidth
                                                value={state.state}
                                                onChange={(e) => handleFieldChange('state', e.target.value)}
                                                onBlur={(e) => handleBlur('state', e.target.value)}
                                                helperText={state?.errors?.state}
                                                error={!!state?.errors?.state}
                                                inputProps={{ maxlength: "2" }}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <Box m={.5}>
                                            <TextField
                                                required
                                                sx={ts}
                                                id='zipCode'
                                                label="Zip Code"
                                                variant="outlined"
                                                fullWidth
                                                value={state.zipCode}
                                                onChange={(e) => handleFieldChange('zipCode', e.target.value)}
                                                onBlur={(e) => handleBlur('zipCode', e.target.value)}
                                                helperText={state?.errors?.zipCode}
                                                error={!!state?.errors?.zipCode}
                                                inputProps={{ maxLength: '10' }}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )
                    }
                </Grid>
            </Paper>
            <Modal open={cardSwipeModal} onClose={() => { setCardSwipeModal(false); }} sx={{ width: '35%', margin: '20vh auto' }}>
                <>
                    <CardSwipeModal
                        setCardSwipeModal={setCardSwipeModal}
                        handleFieldChange={handleFieldChange}
                        state={state}
                        createPayment={createSwipePayment}
                        entityId={citationView.entityId}
                        violationBalance={violationBalance}
                        caseNumber={caseNumber}
                        convenienceFee={convenienceFee}
                        toast={toast}
                        hidData={hidData}
                        handleSwipe={handleSwipe}
                        setHidData={setHidData}
                    />
                </>
            </Modal>
            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'flex-end', mt: '2vh' }}>

                <p style={{ marginRight: 'auto', display: 'inline-block' }}><i>Thank you for your payment. Should you have any billing questions or concerns regarding this payment and/or the amount debited from your account,  please contact {paymentEmail || 'support@interactiveus.com'} and/or 225-308-2800.</i></p>
                {
                    !swiper
                        ? page === 'creditCardInfo' && <Button variant="contained" sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white' }} type="submit" disabled={loading}>Submit Payment</Button>
                        : page === 'creditCardInfo' && (
                            <Button
                                variant="contained"
                                sx={{ m: ".5vh", mt: "0", backgroundColor: 'green', color: 'white' }}
                                onClick={() => {
                                    changePage('swipe');
                                    handleSwipe();
                                }}
                            >
                                Swipe Card
                            </Button>
                        )

                }
                <Button
                    variant="contained"
                    sx={{ m: ".5vh", mt: "0", backgroundColor: 'steelblue', color: 'white', marginLeft: 'auto' }}
                    onClick={() => {
                        handleClose();
                        setViolation({});
                        setGrandTotal(0);
                        setConvenienceFee(0.00);
                        setPaymentBatchNumber('');
                        setHidData(null)
                    }}
                >
                    Cancel
                </Button>
            </Box>
        </form>
    )
}

export default CreditCardModal;
