import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

import { Box, Button, Modal, LinearProgress } from '@mui/material';

import { RiDeleteBinLine } from "react-icons/ri";

import {
    PiDownloadBold,
    PiFileCsvDuotone,
    PiFileDocDuotone,
    PiFilePdfDuotone,
    PiMicrosoftExcelLogoDuotone,
    PiMicrosoftWordLogoDuotone,
} from "react-icons/pi";

import { toast } from 'react-toastify';

import AddFileBox from '../../components/tables/utils/filesTab/AddFileBox';
import FilesTable from '../../components/tables/views/filesTab/FilesTable';
import { ThemeModeContext, apiRoute } from '../../App.js';
import { CaseContext } from '../../context/CaseContext';
import { UserContext } from '../../context/UserContext';
import FilesModals from '../../components/modals/views/FilesModals';

const FilesView = () => {
    const { citationView, setFiles, files } = useContext(CaseContext);
    const { userName, entityId } = useContext(UserContext);
    const { mode } = useContext(ThemeModeContext);

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [binaryData, setBinaryData] = useState([])
    const [fileLimit, setFileLimit] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [file, setFile] = useState({});
    const [loading, setLoading] = useState(false);
    const [deleteReason, setDeleteReason] = useState("");

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState("");

    const handleOpen = (type, file) => {
        setModalType(type);
        setModal(true);
        setFile(file);
    };

    const handleClose = () => {
        setModalType("");
        setModal(false);
    };

    const { caseNumber } = useParams();


    const maxCount = 10;
    const maxFileSizeMB = 15;
    const handleFileSelection = (files) => {
        setBinaryData([]);
        const selected = [...selectedFiles];
        let limitExceeded = false;
        files.some((file) => {
            const { lastModified, lastModifiedDate, name, size, type, webkitRelativePath } = file;
            const fileSizeInMB = size / (1024 * 1024); // Convert bytes to MB
            const fileSizeInKB = size / 1024; // Convert bytes to KB
            if (fileSizeInMB > maxFileSizeMB) {
                toast.error(`File "${name}" exceeds the maximum size of ${maxFileSizeMB}MB. Please choose a smaller file.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                return true; // Stop the loop since we encountered an oversized file
            }
            if (selected.findIndex((f) => f.name === name) === -1) {
                // generating id for list keys in AddFileBox.js
                const fileWithId = {
                    lastModified: lastModified,
                    lastModifiedDate: lastModifiedDate,
                    name: name,
                    size: fileSizeInKB,
                    type: type,
                    webkitRelativePath: webkitRelativePath,
                    id: crypto.randomUUID(),
                };
                selected.push(fileWithId);
                if (selected.length === maxCount) setFileLimit(true);
                if (selected.length > maxCount) {
                    toast.error(`You can only add a maximum of ${maxCount} files`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setFileLimit(false);
                    limitExceeded = true;
                    return true;
                }
            }
        });
        if (!limitExceeded) setSelectedFiles(selected);
    };

    const handleFileEvent = async (e, scans) => {
        const chosenFiles = Array.from(scans || e?.target?.files);
        handleFileSelection(chosenFiles);
        for (const file of chosenFiles) {
            try {
                const binaryData = await readFileAsBlob(file);
                setBinaryData((prevValue) => [...prevValue, binaryData]);

                // Continue with your logic, e.g., append binaryData to FormData or process it further
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }
        if (e) {
            e.target.value = null;
            e.target.files = null;
        }
    };

    const readFileAsBlob = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const binaryData = reader.result;
                resolve(binaryData);
            };

            reader.onerror = (error) => {
                reject(error);
            };

            reader.onloadend = () => {
                reader.onloadend = null;
                reader.onload = null;
                reader.onerror = null;
            };

            reader.readAsArrayBuffer(file);
        });
    };

    const uploadToAzureStorage = async () => {
        const formData = new FormData();

        for (const [index, data] of binaryData.entries()) {
            try {
                // Create a Blob from the binary data
                const blob = new Blob([data], { type: selectedFiles[index]?.type ? selectedFiles[index]?.type : 'application/octet-stream' });
                formData.append('files', blob, `${selectedFiles[index].name}`);
            } catch (error) {
                console.error('Error processing file:', error);
            }
        }

        try {
            const response = await axios.post(`${apiRoute}/api/FilesTbl/UploadToAzureStorageBlob`, formData);
            return response.data;
        } catch (error) {
            console.error('Error uploading files:', error);
            throw error;
        }
    };

    const saveFileMetadata = async (fileMetadata) => {
        const response = await axios.post(`${apiRoute}/api/FilesTbl/SaveFileMetadata`, fileMetadata);
        return response.data;
    };

    const addFile = async (e) => {
        try {
            const promises = selectedFiles?.map(async (file) => {
                const { name, size } = file;
                // Upload file to Azure Storage Blob
                await uploadToAzureStorage();

                // Generate new File to be added with Azure Blob URL
                const newFile = {
                    FkCaseId: caseNumber,
                    FkCitationId: citationView.pkCitationId,
                    FileName: name,
                    CreatedBy: userName,
                    DateEnter: new Date().toISOString(),
                    EntityId: entityId,
                    FileSizeKB: Math.round(size),
                };
                // Save file metadata to the database
                return await saveFileMetadata(newFile);
            });

            const addedFiles = await Promise.all(promises);

            const newFiles = addedFiles?.map((file) => ({
                ...file,
                id: file.pkFilesId,
            }));

            // Update the files state with the new files
            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            setSelectedFiles([]);
            setFileLimit(false);
            toast.success('Files added', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            console.error('add file error', err);
            toast.error('Failed to add files.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
    };

    const removeSelectedFile = (e) => {
        // set event target and files to be null to allow for readding same file
        e.target.value = null;
        e.target.files = null;
        setSelectedFiles([]);
        setFileLimit(false);
    };

    const uploadScannedFilesToAzureStorage = async (scannedFiles, file) => {
        const formData = new FormData();
        try {
            const blob = new Blob([scannedFiles], { type: file.type || 'application/octet-stream' });
            formData.append('files', blob, file.fileName);
            const response = await axios.post(`${apiRoute}/api/FilesTbl/UploadToAzureStorageBlob`, formData);
            return response.data;
        } catch (error) {
            console.error('Error uploading scanned files:', error);
            throw error;
        }
    };

    async function initiateScan() {
        try {
            setClicked(!clicked)
            const response = await fetch(`${apiRoute}/api/Scanner/PostScan/${caseNumber}/${citationView.pkCitationId}/${entityId}/${userName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({}),
            });

            if (!response.ok) {
                let errorMessage;

                try {
                    const errorText = await response.text();
                    errorMessage = `${errorText}`;
                } catch (parseError) {
                    console.error('Error parsing non-JSON response:', parseError);
                }

                throw new Error(errorMessage);
            }


            const result = await response.json();
            console.log('Post request successful:', result);
            return result;
        } catch (error) {

            toast.error(error.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return null;
        }
    };

    const processScannedData = async (scannedData) => {
        try {
            if (!scannedData || scannedData.length === 0) {
                console.error('No valid scanned data found.');
                return;
            }

            // Collect binary data and file metadata for all scanned files
            const uploads = scannedData.map(async (data) => {
                const binaryData = new Uint8Array(atob(data.binaryData).split('').map((char) => char.charCodeAt(0)));
                return uploadScannedFilesToAzureStorage(binaryData, data.filesTbl);
            });

            // Wait for all uploads to complete
            const uploadResults = await Promise.all(uploads);

            // Update the files state with the new files
            const newFiles = uploadResults.map((result, index) => {
                const scannedFile = scannedData[index];
                scannedFile.filesTbl.id = scannedFile.filesTbl.pkFilesId;
                return scannedFile.filesTbl;
            });

            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            setSelectedFiles([]);
            setFileLimit(false);

            toast.success('Scanned files added', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
        } catch (error) {
            console.error('Error processing scanned data:', error);
        } finally {
            setClicked(false);
        }
    };

    async function performScannerOperation() {
        try {
            const scannedData = await initiateScan();
            processScannedData(scannedData);
        } catch (error) {
            console.error('Error performing scanner operation:', error);
            setClicked(false);
        }
    };

    const deleteFile = async (file) => {
        const fileId = file.id;
        const deletionInfo = {
            DeletedBy: userName,
            DeletedReason: deleteReason,
        };
        setLoading(true);
        axios.delete(`${apiRoute}/api/FilesTbl/${fileId}`, { data: deletionInfo })
            .then((res) => {
                const updatedFiles = files.filter(file => file.id !== fileId);
                setFiles(updatedFiles);
                handleClose();
                toast.success('File deleted successfully', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .catch((error) => {
                console.error('Delete file error', error);
                toast.error(`Error ${error.reponse.status} while trying to delete the file`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoading(false));
    };

    const columns = [
        {
            field: 'fileName',
            headerName: 'File Name',
            flex: 1,
        },
        {
            field: 'fileNameImage',
            headerName: 'Image',
            flex: 1,
            renderCell: (params) => {
                const iconStyle = {
                    width: 50,
                    height: 40,
                    color: mode !== 'light' ? 'frostwhite' : 'steelblue',
                };
                return params.row.fileName.includes('.pdf') ? (
                    <PiFilePdfDuotone
                        style={iconStyle}
                        onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}
                    />
                ) : (params.row.fileName.includes('.docx')) ? (
                    <PiMicrosoftWordLogoDuotone
                        style={iconStyle}
                        onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}
                    />
                ) : (params.row.fileName.includes('.txt') || params.row.fileName.includes('.doc')) ? (
                    <PiFileDocDuotone
                        style={iconStyle}
                        onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}
                    />
                ) : (params.row.fileName.includes('.csv')) ? (
                    <PiFileCsvDuotone
                        style={iconStyle}
                        onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}
                    />
                ) :
                    (params.row.fileName.includes('.xlsx') || params.row.fileName.includes('.xls') || params.row.fileName.includes('.xlsb')) ? (
                        <PiMicrosoftExcelLogoDuotone
                            style={iconStyle}
                            onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}
                        />
                    ) : (
                        <img
                            src={`https://issfiles.blob.core.windows.net/securecollect/${params.row.fileName}`}
                            alt="Thumbnail"
                            style={{ width: 50, height: 50 }}
                            onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)} />
                    )
            },
        },
        {
            field: 'fileSizeKB',
            headerName: 'File Size',
            flex: 1,
            renderCell: (params) => {
                const fileSizeInKB = params.value;
                const sizeToShow = !fileSizeInKB
                    ? '0.00 KB'
                    : fileSizeInKB >= 1024
                        ? `${(fileSizeInKB / 1024)?.toFixed(2)} MB`
                        : `${fileSizeInKB?.toFixed(2)} KB`;
                return <span>{sizeToShow}</span>;
            },
        },
        {
            field: 'createdBy',
            headerName: 'Uploaded By',
            flex: 1,
        },
        {
            field: 'downloadFile',
            headerName: 'Download File',
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            renderCell: (params) => {
                const iconStyle = {
                    width: 50,
                    height: 40,
                    color: mode !== 'light' ? 'frostwhite' : 'steelblue',
                };
                return <Button variant="contained" sx={{ backgroundColor: 'steelblue', ml: '.5rem', color: 'white', fontSize: '20px', padding: '.7vh', width: '50%' }} onClick={() => window.open(`https://issfiles.blob.core.windows.net/securecollect/${encodeURIComponent(params.row.fileName)}`)}><PiDownloadBold style={{fontSize: '25px'} } /></Button>
            }
        },
        {
            field: 'delete',
            headerName: 'Delete File',
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (params) => {

                return <Button variant="contained" sx={{ backgroundColor: 'steelblue', ml: '.5rem', color: 'white', fontSize: '20px', padding: '.7vh', width: '50%' }} onClick={() => handleOpen('delete', params.row)}><RiDeleteBinLine style={{ fontSize: '25px' }}/></Button>
            }
        },
    ];

    return (
        <>
            <Box sx={{ width: '99%', m: '1vh auto', textAlign: 'center' }}>
                <h3 style={{ textAlign: 'center' }}>Upload Files</h3>
                <hr />
                <AddFileBox
                    fileLimit={fileLimit}
                    handleFileEvent={handleFileEvent}
                    selectedFiles={selectedFiles}
                    removeSelectedFile={removeSelectedFile}
                    addFile={addFile}
                    scanHandler={performScannerOperation}
                    clicked={clicked}
                    setClicked={setClicked}
                />
                <Box sx={{ height: "35vh", width: '75%', m: '2vh auto' }}>
                    <FilesTable columns={columns} />
                </Box>
            </Box>
            <Modal open={modal} sx={{ width: '35%', m: '5vh auto' }}>
                <>
                    <FilesModals
                        modalType={modalType}
                        loading={loading}
                        deleteFile={deleteFile}
                        handleClose={handleClose}
                        file={file}
                        deleteReason={deleteReason}
                        setDeleteReason={setDeleteReason}
                    />
                </>
            </Modal>
            {clicked && <LinearProgress />}
        </>
    )
}

export default FilesView;