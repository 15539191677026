import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Box, Paper, Button, LinearProgress } from '@mui/material';
import { apiRoute } from '../../../../App';

const CardSwipeModal = (props) => {
    const { setCardSwipeModal, state, createPayment, entityId, violationBalance, caseNumber, convenienceFee, toast, hidData, handleSwipe, setHidData } = props;

    const [swipeText, setSwipeText] = useState('Please Swipe The Card Now');
    const [swiped, setSwiped] = useState(false);

    const handleCardData = () => {

        const tempFirstName = hidData.CardName.split('/')[1]
        const tempLastName = hidData.CardName.split('/')[0]

        if (hidData !== null) {
            setSwipeText("Contacting Merchant Services...")
            try {
                sendEncryptedCardDataToBackend(tempFirstName, tempLastName);
            } catch (err) {
                setSwipeText('Payment Failed');
                setHidData(null)
            }
        } else {
            setSwipeText('Card Read Error');
        }
    };

    async function sendEncryptedCardDataToBackend(first, last) {
        setSwiped(true)
        const ccPaymentData = {
            CardHolderEmail: state.email,
            CardHolderPhone: '',
            CardHolderName: !!first && !!last ? `${first} ${last}` : '',
            CardHolderAddressLine1: state.billingAddress,
            CardHolderCity: state.city,
            CardHolderState: state.state,
            CardHolderZip: state.zipCode,
            PaymentAmount: state.paymentAmount,
            EncryptedTrack1: hidData.Track1,
            EncryptedTrack2: hidData.Track2,
            SecurityCode: state.securityCode,
            CardName: `${first} ${last}`,
            KSN: hidData.KSN,
            FirstName: !!first ? first : state.firstName,
            LastName: !!last ? last : state.lastName,
            IsPartialPayment: state.paymentAmount < violationBalance.toFixed(2) ? 'Y' : 'N',
            FkCaseId: caseNumber,
            ConvenienceFee: convenienceFee,
        };

        axios.post(`${apiRoute}/api/CcPayments/${state.securityCode}/${entityId}`, ccPaymentData)
            .then(response => {
                if (!response.responseCode === "100") {
                    toast.error(`${response.data.responseText}`, {
                        position: "top-right",
                        autoClose: 10000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSwipeText('Payment Failed');
                } else {
                    return response.data;
                }
            })
            .then(data => {
                setSwiped(false)
                setSwipeText('Transaction Complete');
                createPayment(null, first, last);
            })
            .catch(error => {
                // Handle errors
                setSwipeText('Payment Failed');
                setSwiped(false);
                setHidData(null)
                if (error?.response?.data === 'Partial Payment is not allowed on this violation.') {
                    toast.error('Partial Payment is not allowed on this violation.', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                } else {
                    toast.error(`Error occurred while creating payment: ${error?.response?.data}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            });
    }

    useEffect(() => {
        if (hidData) handleCardData()
    }, [hidData])

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', textAlign: 'center' }} elevation={10}>
                <h1>
                    <strong>Swipe Card</strong>
                </h1>
            </Paper>
            <Box sx={{ textAlign: 'center', mt: '5vh' }}>
                {
                    swiped
                        ? <Box sx={{ width: '90%', m: '1vh auto' }}><LinearProgress /></Box>
                        : <h2>{swipeText}</h2>
                }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {swipeText !== "Payment Failed" ?
                    <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => { setCardSwipeModal(false); setHidData(null) }} disabled={swiped === true}>Cancel</Button>
                    :
                    <>
                        <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => {  setSwipeText('Please Swipe The Card Now'); setHidData(null); handleSwipe() }}>Retry</Button>
                        <Button variant="contained" sx={{ m: ".5vh", mt: "1vh", backgroundColor: 'steelblue', color: 'white' }} onClick={() => {  setCardSwipeModal(false); setHidData(null) }} disabled={swiped === true}>Cancel</Button>
                    </>
                }
            </Box>
        </Paper>
    )
}

export default CardSwipeModal