import * as Yup from 'yup';

const passwordValidations = {
    password: Yup.string()
        .required('Password is required'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
};

export default passwordValidations;