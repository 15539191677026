import React, { useContext } from 'react';

import { Box, Button, Paper, Tooltip } from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';
import { BatchContext } from '../../../context/BatchMgmtContext.js';

const BatchButtons = (props) => {
    const {
        rowSelected,
        batchList,
        handleOpen,
        isPaymentExists
    } = props;

    const { statusCheck } = useContext(BatchContext);

    return (
        <>
        <Box sx={{ display: 'flex', gap: '1rem', mt: '2vh', mb: '5vh' }}>
            <Paper elevation={10}>
                <Button sx={{ backgroundColor: 'steelblue', color: 'white' }} variant="contained" onClick={() => handleOpen('openBatch', '25%')}>
                    <AiOutlinePlus />
                    &nbsp; Add New Batch
                </Button>
            </Paper>
            {
                statusCheck === "Closed" &&
                <Paper elevation={10}>
                    <Button
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        variant="contained"
                        disabled={!rowSelected}
                        onClick={() => handleOpen('reopenBatch', '25%')}
                    >
                        Reopen Batch
                    </Button>
                </Paper>
            }
            {
                statusCheck === 'Open' &&
                <Paper elevation={10}>
                    <Button
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        variant="contained"
                        disabled={!rowSelected}
                        onClick={() => handleOpen('closeBatch', '25%')}
                    >
                        Close Batch
                    </Button>
                </Paper>
            }
            {
                statusCheck === 'Closed' &&
                <Paper elevation={10}>
                    <Button
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        variant="contained"
                        disabled={!rowSelected}
                        onClick={() => handleOpen('settleBatch', '25%')}
                    >
                        Settle Batch
                    </Button>
                </Paper>
            }
            {
                statusCheck === 'Open' &&
                <Paper elevation={10}>
                    <Button
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        variant="contained"
                        disabled={!rowSelected || isPaymentExists }
                        onClick={() => handleOpen('changeDate', '25%')}
                    >
                        Change Date
                    </Button>
                </Paper>
            }
            {
                statusCheck === 'Settled' &&
                <Paper elevation={10}>
                    <Tooltip title={batchList.length < 1 && 'Please select a batch / batches to create a deposit report'}>
                        <span>
                            <Button
                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                variant="contained"
                                disabled={batchList.length < 1}
                                onClick={() => handleOpen('depositBatch', '30%')}
                            >
                                Create Deposit
                            </Button>
                        </span>
                    </Tooltip>
                </Paper>
            }
            </Box>
        </>
    );
}

export default BatchButtons;