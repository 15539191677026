import React, { useState, useReducer, useContext, useEffect } from 'react';
import axios from 'axios';

import { Box, Grid, Button, MenuItem, TextField, Paper, Tooltip, CircularProgress, Modal, FormControlLabel, Checkbox, InputAdornment } from '@mui/material';
import { RiDeleteBinLine } from "react-icons/ri";
import { MdEditOff } from "react-icons/md";
import { toast } from 'react-toastify';

import { ThemeModeContext, apiRoute } from '../App';
import { stateOptions } from '../Utils';
import DesotoCcPayment from '../components/modals/views/DesotoCcPayment';

const DesotoPayment = () => {

    const { mode } = useContext(ThemeModeContext);

    const [totalPayment, setTotalPayment] = useState(0.00);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const [ccPayment, setCcPayment] = useState(false);

    const [convenienceFee, setConvenienceFee] = useState(0.00)
    const ccFee = 0.05;

    const paymentOptions = [
        {
            code: 'CASH',
            description: 'CASH'
        },
        {
            code: 'CHECK',
            description: 'CHECK'
        },
        //{
        //    code: 'CREDIT CARD',
        //    description: 'CREDIT CARD'
        //},
    ];

    const initialState = {
        firstName: '',
        lastName: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipCode: '',
        phone: '',
        birthDate: '',
        citNum: '',
        courtDate: '',
        email: '',

    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            case 'RESET_STATE':
                return action.initialState;
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const resetState = () => {
        dispatch({ type: 'RESET_STATE', initialState });
    };

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        let f = "";
        const fields = ['firstName', 'lastName', 'email'];
        if (field === 'firstName') {
            f = 'First Name';
        }
        if (field === 'lastName') {
            f = 'Last Name';
        }
        if (field === 'email') {
            f = 'Email';
        }
        switch (field) {
            default:
                if (!value && fields.find(f => f === field)) {
                    error = `${f} is required`;
                }
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const newPaymentRow = {
        uuid: crypto.randomUUID(),
        paymentType: '',
        amountRecived: 0,
        displayedAmountRecived: '$0.00',
        paymentAmount: 0,
        displayedPaymentAmount: '$0.00',
        change: '$0.00',
        checkNumber: '',
    };

    const [paymentRows, setPaymentRows] = useState([
        {
            uuid: crypto.randomUUID(),
            paymentType: paymentOptions[0].code,
            amountRecived: 0,
            displayedAmountRecived: '$0.00',
            paymentAmount: 0,
            displayedPaymentAmount: '$0.00',
            change: '$0.00',
            checkNumber: '',
            convenienceFee: 0,
        },
    ]);

    const addPaymentRow = () => {
        // temp value for adding in a new payment to access values immediately
        const payments = [...paymentRows];

        // Create a new payment row with the calculated balance
        const newPaymentRow = {
            uuid: crypto.randomUUID(),
            paymentType: '',
            amountRecived: 0,
            displayedAmountRecived: '$0.00',
            paymentAmount: 0,
            displayedPaymentAmount: '$0.00',
            change: '$0.00',
            checkNumber: '',
        };

        // Add the new payment row to the state
        payments.push(newPaymentRow);
        setPaymentRows([...payments]);
    };

    const handleDeleteRow = (index) => {
        const deletedPaymentAmount = paymentRows[index].paymentAmount;

        const updatedRows = [...paymentRows];
        updatedRows.splice(index, 1);

        const newTotal = parseFloat(totalPayment) - parseFloat(deletedPaymentAmount);

        setTotalPayment(newTotal);

        setPaymentRows(updatedRows);
    };

    const createPayment = async (e) => {
        e.preventDefault();
        // Check for errors manually before submitting
        const newErrors = {};
        let ccPayment = false;
        let toastError;
        paymentRows.forEach((payment, index) => {
            if ((payment.paymentType === 'CHECK' || payment.paymentType === 'MONEY ORDER') && !payment.checkNumber) {
                newErrors[index] = "Check Number Required"
                toastError = "Check Number Required"
            }
            if (payment.paymentType === 'CREDIT CARD') {
                ccPayment = true;
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            toast.error(toastError, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return;
        }

        if (parseFloat(totalPayment) < 0.01) {
            toast.error('Please enter a valid payment amount', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
            });
            return;
        };

        if (!ccPayment) {
            setLoading(true);
            try {
                // Prepare payment data array
                const paymentDataArray = paymentRows.map((payment) => ({
                    uuid: payment.uuid,
                    CitNum: state.citNum,
                    CourtDate: state.courtDate,
                    Amount: payment.paymentAmount,
                    FirstName: state.firstName,
                    LastName: state.lastName,
                    Address1: state.address1,
                    Address2: state.address2,
                    ZipCode: state.zipCode,
                    City: state.city,
                    State: state.state,
                    PhoneNumber: state.phone,
                    DOB: state.birthDate,
                    Type: 'Counter Payments',
                    Description: 'Citation Payment',
                    Channel: 'Web',
                    Status: 'New',
                    Email: state.email,
                    PaymentType: payment.paymentType
                }));

                const totalPaymentAmount = paymentDataArray.reduce((total, payment) => total + payment.PaymentAmount, 0);

                if (parseFloat(totalPaymentAmount) < 0.01) {
                    toast.error('Please enter a valid payment amount', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    return;
                }

                for (const payment of paymentDataArray) {
                    axios.post(`${apiRoute}/api/DesotoPayment`, payment)
                        .then(({ data }) => {
                            console.log(data);
                        })
                        .catch((err) => console.error(err))
                }
                toast.success('Payments added', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                resetState();
                setPaymentRows([newPaymentRow]);
            } catch (err) {
                toast.error(`Payment failed. ${err?.response?.data}`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            } finally {
                setLoading(false);
            }
        } else {
            setModal(true);
        }
    };

    useEffect(() => {
        const total = paymentRows.reduce((acc, payment) => {
            return acc + parseFloat(payment.paymentAmount);
        }, 0);

        setTotalPayment(parseFloat(total).toFixed(2));
        if (ccPayment) {
            const fee = (total * ccFee).toFixed(2);
            setConvenienceFee(fee);
            setTotalPayment((total + parseFloat(fee)).toFixed(2));
        }
    }, [paymentRows])

    const ts = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };
    return (
        <>
            <Paper
                sx={
                    {
                        width: '75%',
                        m: '1vh auto',
                        pb: '2vh'
                    }
                }
                elevation={10}
            >
                <Paper
                    sx={
                        {
                            backgroundColor: 'steelblue',
                            color: 'white',
                            p: '.5vh',
                            mb: '2vh'
                        }
                    }
                    elevation={10}
                >
                    <Box
                        sx={
                            {
                                px: '2rem',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }
                        }
                    >
                        <Box>
                            <h2 style={
                                {
                                    fontWeight: 'bold',
                                    marginTop: '.5vh'
                                }
                            }
                            >
                                New Payment
                            </h2>
                        </Box>
                        <Box>
                            <strong>
                                Total Payment Amount:
                                <span
                                    style={
                                        {
                                            marginLeft: '1rem',
                                            color: 'inherit'
                                        }
                                    }
                                >
                                    ${parseFloat(totalPayment).toFixed(2)}
                                </span>
                            </strong>
                        </Box>
                    </Box>
                </Paper>
                <form
                    onSubmit={createPayment}
                >
                    <Box
                        sx={
                            {
                                width: '95%',
                                m: '0 auto'
                            }
                        }
                    >
                        <Paper
                            sx={
                                {
                                    mb: '1vh'
                                }
                            }
                            elevation={10}
                        >
                            <Paper
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        p: '.5vh',
                                        height: '3vh'
                                    }
                                }
                                elevation={10}
                            >
                                <h6>
                                    Citation Info
                                </h6>
                            </Paper>
                            <Grid
                                container
                                direction="row"
                                sx={
                                    {
                                        display: 'flex',
                                        gap: '1rem',
                                        flexWrap: 'wrap',
                                        p: '1vh',
                                        ml: '.35vw'
                                    }
                                }
                            >
                                <Grid
                                    item
                                    sm={5}
                                >
                                    <TextField
                                        required
                                        sx={ts}
                                        fullWidth
                                        name="citNum"
                                        id="citNum"
                                        label="Citation Number"
                                        value={state.citNum || ''}
                                        onChange={(e) => { handleFieldChange('citNum', e.target.value) }}
                                        onBlur={(e) => { handleBlur('citNum', e.target.value) }}
                                        helperText={state?.errors?.citNum}
                                        error={!!state?.errors?.citNum}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid
                                    item
                                    sm={3}
                                >
                                    <TextField
                                        required
                                        sx={ts}
                                        fullWidth
                                        type='Date'
                                        name="courtDate"
                                        id="courtDate"
                                        label="Court Date"
                                        value={state.courtDate || ''}
                                        onChange={(e) => { handleFieldChange('courtDate', e.target.value) }}
                                        onBlur={(e) => { handleBlur('courtDate', e.target.value) }}
                                        helperText={state?.errors?.courtDate}
                                        error={!!state?.errors?.courtDate}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                        <Paper
                            sx={
                                {
                                    mb: '1vh'
                                }
                            }
                            elevation={10}
                        >
                            <Paper
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        p: '.5vh',
                                        height: '3vh'
                                    }
                                }
                                elevation={10}
                            >
                                <h6>
                                    Remitter Info
                                </h6>
                            </Paper>
                            <Box
                                sx={
                                    {
                                        maxHeight: '45vh',
                                        overflowY: 'auto'
                                    }
                                }
                            >
                                <Grid
                                    container
                                    direction="row"
                                    sx={
                                        {
                                            display: 'flex',
                                            gap: '1rem',
                                            flexWrap: 'wrap',
                                            p: '1vh',
                                            justifyContent: 'space-around'
                                        }
                                    }
                                >
                                    <Grid
                                        item
                                        sm={5.75}
                                    >
                                        <TextField
                                            required
                                            sx={ts}
                                            fullWidth
                                            name="firstName"
                                            id="firstName"
                                            label="First Name"
                                            value={state.firstName || ''}
                                            onChange={(e) => { handleFieldChange('firstName', e.target.value) }}
                                            onBlur={(e) => { handleBlur('firstName', e.target.value) }}
                                            helperText={state?.errors?.firstName}
                                            error={!!state?.errors?.firstName}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={5.75}
                                    >
                                        <TextField
                                            required
                                            sx={ts}
                                            name="lastName"
                                            fullWidth
                                            id="lastName"
                                            label="Last Name"
                                            value={state.lastName || ''}
                                            onChange={(e) => { handleFieldChange('lastName', e.target.value) }}
                                            onBlur={(e) => { handleBlur('lastName', e.target.value) }}
                                            helperText={state?.errors?.lastName}
                                            error={!!state?.errors?.lastName}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4.5}
                                    >
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            name="phone"
                                            id="phone"
                                            label="Phone Number"
                                            value={state.phone || ''}
                                            onChange={(e) => { handleFieldChange('phone', e.target.value) }}
                                            onBlur={(e) => { handleBlur('phone', e.target.value) }}
                                            helperText={state?.errors?.phone}
                                            error={!!state?.errors?.phone}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={2.3}
                                    >
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            type='date'
                                            name="birthDate"
                                            id="birthDate"
                                            label="Date of Birth"
                                            value={state.birthDate || ''}
                                            onChange={(e) => { handleFieldChange('birthDate', e.target.value) }}
                                            onBlur={(e) => { handleBlur('birthDate', e.target.value) }}
                                            helperText={state?.errors?.birthDate}
                                            error={!!state?.errors?.birthDate}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4.5}
                                    >
                                        <TextField
                                            required
                                            fullWidth
                                            sx={ts}
                                            name="email"
                                            id="email"
                                            label="Email"
                                            value={state.email || ''}
                                            onChange={(e) => { handleFieldChange('email', e.target.value) }}
                                            onBlur={(e) => { handleBlur('email', e.target.value) }}
                                            helperText={state?.errors?.email}
                                            error={!!state?.errors?.email}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={5.75}
                                    >
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            name="address1"
                                            id="address1"
                                            label="Address 1"
                                            value={state.address1 || ''}
                                            onChange={(e) => { handleFieldChange('address1', e.target.value) }}
                                            onBlur={(e) => { handleBlur('address1', e.target.value) }}
                                            helperText={state?.errors?.address1}
                                            error={!!state?.errors?.address1}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={5.75}
                                    >
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            name="address2"
                                            id="address2"
                                            label="Address 2"
                                            value={state.address2 || ''}
                                            onChange={(e) => { handleFieldChange('address2', e.target.value) }}
                                            onBlur={(e) => { handleBlur('address2', e.target.value) }}
                                            helperText={state?.errors?.address2}
                                            error={!!state?.errors?.address2}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4.5
                                        }>
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            name="city"
                                            id="city"
                                            label="City"
                                            value={state.city || ''}
                                            onChange={(e) => { handleFieldChange('city', e.target.value) }}
                                            onBlur={(e) => { handleBlur('city', e.target.value) }}
                                            helperText={state?.errors?.city}
                                            error={!!state?.errors?.city}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        sm={2.3}
                                    >
                                        <TextField
                                            fullWidth
                                            select
                                            sx={ts}
                                            name="state"
                                            id="state"
                                            label="State"
                                            value={state.state || ''}
                                            onChange={(e) => { handleFieldChange('state', e.target.value) }}
                                            onBlur={(e) => { handleBlur('state', e.target.value) }}
                                            helperText={state?.errors?.state}
                                            error={!!state?.errors?.state}
                                            variant="outlined"
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: '20em',
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <MenuItem value=''>N/A</MenuItem>
                                            {
                                                stateOptions?.map(({ value, label }) => {
                                                    return <MenuItem key={label} value={value}>{label}</MenuItem>
                                                })
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4.5}
                                    >
                                        <TextField
                                            fullWidth
                                            sx={ts}
                                            name="zipCode"
                                            id="zipCode"
                                            label="ZIP Code"
                                            value={state.zipCode || ''}
                                            onChange={(e) => { handleFieldChange('zipCode', e.target.value) }}
                                            onBlur={(e) => { handleBlur('zipCode', e.target.value) }}
                                            helperText={state?.errors?.zipCode}
                                            error={!!state?.errors?.zipCode}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                        <Paper
                            elevation={10}
                            mb={1}
                        >
                            <Paper
                                sx={
                                    {
                                        backgroundColor: 'steelblue',
                                        color: 'white',
                                        p: '.5vh',
                                        height: '3vh'
                                    }
                                }
                                elevation={10}
                            >
                                <h6>
                                    Payment Info
                                </h6>
                            </Paper>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={ccPayment}
                                        onChange={() => {
                                            const value = !ccPayment
                                            setCcPayment(value);
                                            if (value) {
                                                setPaymentRows([
                                                    {
                                                        uuid: crypto.randomUUID(),
                                                        paymentType: 'CREDIT CARD',
                                                        amountRecived: 0,
                                                        displayedAmountRecived: '$0.00',
                                                        paymentAmount: 0,
                                                        displayedPaymentAmount: '$0.00',
                                                        change: '$0.00',
                                                        checkNumber: '',
                                                        convenienceFee: 0,
                                                    },
                                                ])
                                            } else {
                                                setPaymentRows([
                                                    {
                                                        uuid: crypto.randomUUID(),
                                                        paymentType: paymentOptions[0].code,
                                                        amountRecived: 0,
                                                        displayedAmountRecived: '$0.00',
                                                        paymentAmount: 0,
                                                        displayedPaymentAmount: '$0.00',
                                                        change: '$0.00',
                                                        checkNumber: '',
                                                        convenienceFee: 0,
                                                    },
                                                ])
                                            }
                                        }}
                                        sx={
                                            {
                                                ml: '1vh'
                                            }
                                        }
                                    />
                                }
                                label="Credit Card Payment"
                            />
                            <Box
                                sx={
                                    {
                                        maxHeight: '25vh',
                                        overflowY: 'auto'
                                    }
                                }
                            >
                                <Grid
                                    container
                                    direction="row"
                                    sx={
                                        {
                                            display: "inline-block",
                                            verticalAlign: 'middle'
                                        }
                                    }
                                >
                                    {paymentRows?.map((payment, index) => (
                                        <React.Fragment
                                            key={payment.uuid}
                                        >
                                            <Box
                                                sx={
                                                    {
                                                        display: "flex",
                                                        alignItems: 'center',
                                                        mt: "1vh"
                                                    }
                                                }
                                            >
                                                <Grid
                                                    item
                                                    sm={1}
                                                >
                                                    <Box
                                                        sx={
                                                            {
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '50px'
                                                            }
                                                        }
                                                    >
                                                        <Tooltip
                                                            title="Delete Payment"
                                                            placement="top"
                                                        >
                                                            <Button
                                                                variant="contained"
                                                                onClick={() => handleDeleteRow(index)}
                                                                disabled={ccPayment}
                                                                sx={
                                                                    {
                                                                        backgroundColor: 'steelblue',
                                                                        color: 'white',
                                                                        m: 'auto',
                                                                        height: '100%',
                                                                        fontSize: '20px'
                                                                    }
                                                                }
                                                            >
                                                                <RiDeleteBinLine />
                                                            </Button>
                                                        </Tooltip>
                                                    </Box>
                                                </Grid>
                                                <Grid
                                                    item
                                                    sm={2.25}
                                                >
                                                    <Box
                                                        m={.5}
                                                    >
                                                        {
                                                            !ccPayment ? (

                                                                <TextField
                                                                    sx={ts}
                                                                    select
                                                                    id="paymentType"
                                                                    label="Type"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={
                                                                        {
                                                                            shrink: true
                                                                        }
                                                                    }
                                                                    value={payment.paymentType}
                                                                    onChange={(e) => {
                                                                        const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                        const newRows = [...paymentRows];
                                                                        newRows[indexByUUID].paymentType = e.target.value;
                                                                        setPaymentRows(newRows);
                                                                    }}
                                                                >
                                                                    {
                                                                        paymentOptions?.map(({ code, description }) => {
                                                                            return (
                                                                                <MenuItem
                                                                                    key={code}
                                                                                    value={code}
                                                                                >
                                                                                    {description}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </TextField>
                                                            ) : (
                                                                <TextField
                                                                    sx={ts}
                                                                    id="paymentType"
                                                                    label="Type"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={payment.paymentType}
                                                                    InputLabelProps={
                                                                        {
                                                                            shrink: true
                                                                        }
                                                                    }
                                                                    inputProps={
                                                                        {
                                                                            readOnly: true
                                                                        }
                                                                    }
                                                                    InputProps={
                                                                        {
                                                                            startAdornment: (
                                                                                <InputAdornment
                                                                                    position="start"
                                                                                >
                                                                                    <MdEditOff />
                                                                                </InputAdornment>
                                                                            )
                                                                        }
                                                                    }
                                                                />
                                                            )
                                                        }
                                                    </Box>
                                                </Grid>
                                                {
                                                    !ccPayment && (
                                                        <Grid
                                                            item
                                                            sm={2}
                                                        >
                                                            <Box
                                                                m={.5}
                                                            >
                                                                <TextField
                                                                    sx={ts}
                                                                    id={`amountRecived-${index}`}
                                                                    label="Amount Received"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    InputLabelProps={{ shrink: true }}
                                                                    inputProps={{
                                                                        style: { textAlign: 'right' },
                                                                        inputMode: 'numeric',
                                                                    }}
                                                                    value={payment.displayedAmountRecived}
                                                                    onClick={() => {
                                                                        // Clear the input field when clicked
                                                                        const newRows = [...paymentRows];
                                                                        newRows[index].displayedAmountRecived = '$';
                                                                        setPaymentRows(newRows);
                                                                    }}
                                                                    onChange={(e) => {
                                                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                                                        if (!isNaN(value)) {
                                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                            const newRows = [...paymentRows];
                                                                            newRows[indexByUUID].amountRecived = parseFloat(value);
                                                                            newRows[indexByUUID].displayedAmountRecived = '$' + value;
                                                                            newRows[indexByUUID].change = '$' + parseFloat(value - paymentRows[indexByUUID].paymentAmount).toFixed(2);
                                                                            setPaymentRows(newRows);
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const value = e.target.value.replace(/[^0-9.]/g, '');
                                                                        if (isNaN(value) || value === '') {
                                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                            const newRows = [...paymentRows];
                                                                            newRows[indexByUUID].amountRecived = 0;
                                                                            newRows[indexByUUID].displayedAmountRecived = '$0.00';
                                                                            setPaymentRows(newRows);
                                                                        } else {
                                                                            const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                            const newRows = [...paymentRows];
                                                                            newRows[indexByUUID].displayedAmountRecived = '$' + parseFloat(value).toFixed(2);
                                                                            setPaymentRows(newRows);
                                                                        }
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    )
                                                }
                                                <Grid
                                                    item
                                                    sm={2}
                                                >
                                                    <Box
                                                        m={.5}
                                                    >
                                                        <TextField
                                                            sx={ts}
                                                            id={`paymentAmount-${index}`}
                                                            label="Payment Amount"
                                                            variant="outlined"
                                                            fullWidth
                                                            InputLabelProps={{ shrink: true }}
                                                            inputProps={{
                                                                style: { textAlign: 'right' },
                                                                inputMode: 'numeric',
                                                            }}
                                                            value={payment.displayedPaymentAmount}
                                                            onClick={() => {
                                                                // Clear the input field when clicked
                                                                const newRows = [...paymentRows];
                                                                newRows[index].displayedPaymentAmount = '$';
                                                                setPaymentRows(newRows);
                                                            }}
                                                            onChange={(e) => {
                                                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                                                if (!isNaN(value)) {
                                                                    const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                    const newRows = [...paymentRows];
                                                                    newRows[indexByUUID].paymentAmount = parseFloat(value);
                                                                    newRows[indexByUUID].displayedPaymentAmount = '$' + value;
                                                                    newRows[indexByUUID].change = '$' + parseFloat(paymentRows[indexByUUID].amountRecived - value).toFixed(2);
                                                                    setPaymentRows(newRows);
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                const value = e.target.value.replace(/[^0-9.]/g, '');
                                                                if (isNaN(value) || value === '') {
                                                                    const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                    const newRows = [...paymentRows];
                                                                    newRows[indexByUUID].paymentAmount = 0;
                                                                    newRows[indexByUUID].displayedPaymentAmount = '$0.00';
                                                                    setPaymentRows(newRows);
                                                                } else {
                                                                    const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                    const newRows = [...paymentRows];
                                                                    newRows[indexByUUID].displayedPaymentAmount = '$' + parseFloat(value).toFixed(2);
                                                                    setPaymentRows(newRows);
                                                                }
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                                {
                                                    !ccPayment &&
                                                    <Grid
                                                        item
                                                        sm={2}
                                                    >
                                                        <Box
                                                            m={.5}
                                                        >
                                                            <TextField
                                                                sx={ts}
                                                                id={`change-${index}`}
                                                                label="Change"
                                                                variant="outlined"
                                                                value={payment.change}
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    style: { textAlign: 'right' },
                                                                    readOnly: true
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <MdEditOff />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                }
                                                {
                                                    ccPayment &&
                                                    <Grid
                                                        item
                                                        sm={2.25}
                                                    >
                                                        <Box
                                                            m={.5}
                                                        >
                                                            <TextField
                                                                sx={ts}
                                                                id={`convenienceFee-${index}`}
                                                                label="Service Fee"
                                                                variant="outlined"
                                                                value={`$${(convenienceFee || '0.00')}`}
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    style: { textAlign: 'right' },
                                                                    readOnly: true
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <MdEditOff />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                }
                                                {
                                                    ccPayment &&
                                                    <Grid
                                                        item
                                                        sm={2.25}
                                                    >
                                                        <Box
                                                            m={.5}
                                                        >
                                                            <TextField
                                                                sx={ts}
                                                                variant="outlined"
                                                                label='Grand Total'
                                                                value={`$${parseFloat(totalPayment).toFixed(2)}`}
                                                                InputLabelProps={{ shrink: true }}
                                                                inputProps={{
                                                                    style: { textAlign: 'right' },
                                                                    readOnly: true
                                                                }}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <MdEditOff />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                    sm={2.25}
                                                >
                                                    {(payment.paymentType === "MONEY ORDER" || payment.paymentType === "CHECK") && (
                                                        <Box
                                                            m={.5}
                                                        >
                                                            <Tooltip
                                                                title={errors?.[index] || ''} open={!!errors?.[index]}
                                                                placement="top"
                                                            >
                                                                <TextField
                                                                    sx={ts}
                                                                    id={`checkNumber-${index}`}
                                                                    label="Check Number"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    value={payment.checkNumber}
                                                                    onChange={(e) => {
                                                                        const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                        const newRows = [...paymentRows];
                                                                        newRows[indexByUUID].checkNumber = e.target.value.replace(/[^0-9.]/g, '');
                                                                        setPaymentRows(newRows);
                                                                        if (e.target.value === '') {
                                                                            setErrors({
                                                                                ...errors,
                                                                                [indexByUUID]: 'Check Number Required'
                                                                            })
                                                                        } else {
                                                                            setErrors({
                                                                                ...errors,
                                                                                [indexByUUID]: undefined
                                                                            })
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        const indexByUUID = paymentRows.findIndex((row) => row.uuid === payment.uuid);
                                                                        if (e.target.value === '') {
                                                                            setErrors({
                                                                                ...errors,
                                                                                [indexByUUID]: 'Check Number Required'
                                                                            })
                                                                        } else {
                                                                            setErrors({
                                                                                ...errors,
                                                                                [indexByUUID]: undefined
                                                                            })
                                                                        }
                                                                    }}
                                                                    error={!!errors?.[index]}
                                                                />
                                                            </Tooltip>
                                                        </Box>
                                                    )}
                                                </Grid>
                                            </Box>
                                        </React.Fragment>
                                    ))}
                                </Grid >
                            </Box >
                            <Box
                                sx={
                                    {
                                        display: "flex",
                                        justifyContent: "space-between",
                                        mt: "1vh",
                                        alignItems: "flex-end"
                                    }
                                }
                            >

                                <Box
                                    sx={
                                        {
                                            width: "30%"
                                        }
                                    }
                                >
                                    {!ccPayment &&
                                        <Tooltip
                                            title="A payment type is needed on the payment before adding a new payment"
                                            placement="top"
                                            arrow
                                            disableHoverListener={!paymentRows.some((row) => row.paymentType === '')}
                                        >
                                            <span>
                                                <Button
                                                    variant="contained"
                                                    sx={
                                                        {
                                                            m: ".5vh",
                                                            mt: "0",
                                                            width: '95%',
                                                            backgroundColor: 'steelblue',
                                                            color: 'white'
                                                        }
                                                    }
                                                    onClick={addPaymentRow}
                                                    disabled={paymentRows.some((row) => row.paymentType === '')}
                                                >
                                                    Add Payment
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    }
                                </Box>
                                <Box
                                    sx={
                                        {
                                            marginLeft: 'auto'
                                        }
                                    }
                                >
                                    {ccPayment ?
                                        <Button
                                            variant="contained"
                                            sx={
                                                {
                                                    m: ".5vh",
                                                    mt: "0",
                                                    backgroundColor: 'steelblue',
                                                    color: 'white'
                                                }
                                            }
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Continue
                                        </Button>
                                        :
                                        <Button
                                            variant="contained"
                                            sx={
                                                {
                                                    m: ".5vh",
                                                    mt: "0",
                                                    backgroundColor: 'steelblue',
                                                    color: 'white'
                                                }
                                            }
                                            type="submit"
                                            disabled={loading}
                                        >
                                            Submit Payment
                                        </Button>
                                    }
                                </Box>
                            </Box>
                        </Paper>
                    </Box>
                </form>
                {
                    loading && <CircularProgress />
                }
            </Paper>
            <Modal
                open={modal}
                sx={
                    {
                        width: '50%',
                        margin: "5vh auto"
                    }
                }
            >
                <>
                    <DesotoCcPayment
                        ts={ts}
                        payState={state}
                        setModal={setModal}
                        totalPayment={totalPayment}
                        convenienceFee={convenienceFee}
                    />
                </>
            </Modal>
        </>
    )
}

export default DesotoPayment;