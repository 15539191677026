import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuItem, Box, Button, TextField, Paper, Modal } from '@mui/material'

import { ThemeModeContext } from '../../App.js';
import { SearchContext } from '../../context/SearchContext.js';
import SearchModals from '../modals/views/SearchModals.js';

const SearchForm = (props) => {
    const { modal, modalType, handleClose } = props;
    const { mode } = useContext(ThemeModeContext);
    const {
        onSubmitHandler,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        caseNumber,
        setCaseNumber,
        citationNumber,
        setCitationNumber,
        violationDate,
        setViolationDate,
        courtDate,
        setCourtDate,
        driverLicenseNumber,
        setDriverLicenseNumber,
        dateOfBirth,
        setDateOfBirth,
        agency,
        setAgency,
        agencies,
        clearFields,
        caseStatus,
        setCaseStatus,
        caseType,
        setCaseType,
        caseTypes,
        receiptNum,
        setReceiptNum,
        bondNumber,
        setBondNumber,
        postedLast,
        setPostedLast,
        postedFirst,
        setPostedFirst,
        chargeDesc,
        setChargeDesc,
        fullCaseNumber,
        setFullCaseNumber
    } = useContext(SearchContext);

    const { statusCodes } = useContext(SearchContext)
    const [bondCaseType, setBondCaseType] = useState(caseType === 'BOND');

    const handleInputChange = (value, setValue) => {
        const sanitizedValue = value.replace(/[^a-zA-Z0-9-\s]/g, ''); // Allow only letters, numbers, and spaces
        setValue(sanitizedValue);
    };

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <>
            <form onSubmit={onSubmitHandler}>
                <Box sx={{ width: "100%", display: "flex", gap: "1rem", flexGrow: 1 }}>
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                            <h5 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Search By</h5>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "0 auto", display: "flex", gap: "1rem", alignItems: "center", flexGrow: 1 }}>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={textFieldStyle}
                                    id="fullCaseNumber"
                                    label="Full Case #"
                                    value={fullCaseNumber}
                                    onChange={e => handleInputChange(e.target.value, setFullCaseNumber)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={textFieldStyle}
                                    id="caseNumber"
                                    label="Case #"
                                    value={caseNumber}
                                    onChange={e => handleInputChange(e.target.value, setCaseNumber)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={textFieldStyle}
                                    id="citationNumber"
                                    label="Citation #"
                                    value={citationNumber}
                                    onChange={e => handleInputChange(e.target.value, setCitationNumber)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={textFieldStyle}
                                    id="lastName"
                                    label="Last Name"
                                    value={lastName}
                                    onChange={e => handleInputChange(e.target.value, setLastName)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={textFieldStyle}
                                    id="firstName"
                                    label="First Name"
                                    value={firstName}
                                    onChange={e => handleInputChange(e.target.value, setFirstName)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>

                        <Box sx={{ width: "95%", margin: "0 auto", display: "flex", gap: '1rem', alignItems: "center", flexGrow: 1 }}>
                            <Box mb={1.5} style={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    sx={{ ...textFieldStyle, flex: 1 }}
                                    id="driverLicenseNumber"
                                    label="License #"
                                    value={driverLicenseNumber}
                                    onChange={e => setDriverLicenseNumber(e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mb={1.5} style={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    sx={{ ...textFieldStyle, flex: 1 }}
                                    type="date"
                                    id="violationDate"
                                    label="Violation / Court Date"
                                    value={violationDate}
                                    onChange={e => setViolationDate(e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mb={1.5} style={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    sx={{ ...textFieldStyle, flex: 1 }}
                                    type="date"
                                    id="dateOfBirth"
                                    label="D.O.B."
                                    value={dateOfBirth}
                                    onChange={e => setDateOfBirth(e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                            <Box mb={1.5} style={{ flex: 1 }}>
                                <TextField
                                    fullWidth
                                    sx={{ ...textFieldStyle, flex: 1 }}
                                    type="date"
                                    id="courtDate"
                                    label="Pay By Date"
                                    value={courtDate}
                                    onChange={e => setCourtDate(e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ width: "95%", margin: "0 auto", display: "flex", gap: '1rem', alignItems: "center", mb: 1.5, flexGrow: 1 }}>
                            {/* "Agency" Box */}
                            <Box>
                                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", flexGrow: 1 }}>
                                    <TextField
                                        sx={{ ...textFieldStyle, minWidth: 199, flex: 1 }}
                                        id="agency"
                                        label="Agency"
                                        name="agency"
                                        value={agency}
                                        onChange={(e) => setAgency(e.target.value)}
                                        select
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                    >
                                        <MenuItem value={''}>N/A</MenuItem>
                                        {
                                            agencies?.map(({ code, description }) => {
                                                return <MenuItem key={code} value={code}>{description}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                                    <TextField
                                        sx={{ ...textFieldStyle, minWidth: 199, flex: 1 }}
                                        id="caseStatus"
                                        label="Case Status"
                                        name="caseStatus"
                                        value={caseStatus}
                                        onChange={(e) => setCaseStatus(e.target.value)}
                                        select
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                    >
                                        {statusCodes?.map(({ defaultValue }) => (
                                            <MenuItem key={defaultValue} value={defaultValue}>
                                                {defaultValue}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                                    <TextField
                                        sx={{ ...textFieldStyle, minWidth: 199, flex: 1 }}
                                        id="caseTypes"
                                        label="Case Type"
                                        name="caseTypes"
                                        select
                                        fullWidth
                                        value={caseType}
                                        onChange={(e) => {
                                            setCaseType(e.target.value);
                                            setBondCaseType(e.target.value === 'BOND' ? true : false);
                                        }}
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                    >
                                        <MenuItem value={''}>N/A</MenuItem>
                                        {
                                            caseTypes?.map(({ code, description }) => {
                                                return <MenuItem key={code} value={code}>{description}</MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Box>
                            </Box>
                            {
                                firstName.length > 0 && lastName.length > 0 && (
                                    <Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                                            <TextField
                                                sx={{ ...textFieldStyle, minWidth: 199, flex: 1 }}
                                                id="chargeDesc"
                                                label="Charge Description"
                                                name="chargeDesc"
                                                fullWidth
                                                value={chargeDesc}
                                                onChange={(e) => setChargeDesc(e.target.value)}
                                                variant="outlined"
                                                InputLabelProps={{ shrink: true }}
                                            >
                                                {/*<MenuItem value={''}>N/A</MenuItem>*/}
                                                {/*{*/}
                                                {/*    caseTypes?.map(({ code, description }) => {*/}
                                                {/*        return <MenuItem key={code} value={code}>{description}</MenuItem>*/}
                                                {/*    })*/}
                                                {/*}*/}
                                            </TextField>
                                        </Box>
                                    </Box>
                                )
                            }

                            {/* Radio Buttons */}
                            {/*<Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "100%", ml: 3 }}>*/}
                            {/*    <Box sx={{ width: '100%' }}>*/}
                            {/*        <RadioGroup*/}
                            {/*            row*/}
                            {/*            name='searchFilter'*/}
                            {/*            value={searchButtons}*/}
                            {/*            onChange={(e) => setSearchButtons(e.target.value)}*/}
                            {/*        >*/}
                            {/*            <FormControlLabel value="all" control={<Radio />} label="All" />*/}
                            {/*            <FormControlLabel value="onlyPaid" control={<Radio />} label="Only Paid" />*/}
                            {/*            <FormControlLabel value="onlyUnpaid" control={<Radio />} label="Only Unpaid" />*/}
                            {/*            */}{/*<FormControlLabel value="delinquent" control={<Radio />} label="Delinquent" />*/}
                            {/*        </RadioGroup>*/}
                            {/*    </Box>*/}
                            {/*</Box>*/}
                        </Box>

                    </Paper>
                    {
                        bondCaseType && (

                            <Paper sx={{ height: "50%" }} elevation={10}>
                                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh',display:'flex' }}>
                                    <h5 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom:'0px' }}>Bond Search</h5>
                                </Paper>
                                <Box sx={{ width: "95%", margin: "1vh auto", display: "flex", gap: "1rem" }}>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            sx={textFieldStyle}
                                            id="receiptNum"
                                            label="Bond Receipt #"
                                            value={receiptNum}
                                            onChange={(e) => setReceiptNum(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            sx={textFieldStyle}
                                            id="bondNumber"
                                            label="Bond Number"
                                            value={bondNumber}
                                            onChange={(e) => setBondNumber(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            sx={textFieldStyle}
                                            id="postedLast"
                                            label="Last Name"
                                            value={postedLast}
                                            onChange={(e) => setPostedLast(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                    <Box mt={1.5} mb={1.5}>
                                        <TextField
                                            fullWidth
                                            sx={textFieldStyle}
                                            id="postedFirst"
                                            label="First Name"
                                            value={postedFirst}
                                            onChange={(e) => setPostedFirst(e.target.value)}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </Box>
                                </Box>
                            </Paper>
                        )
                    }
                </Box>
                <Box sx={{ width: "100%", margin: "1vh auto", display: 'flex', gap: '1rem' }}>
                    <Paper elevation={10}>
                        <Button type="submit" variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }}>Search (Enter)</Button>
                    </Paper>
                    <Paper elevation={10}>
                        <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }} onClick={clearFields}>Clear Fields</Button>
                    </Paper>
                    {/*<Paper elevation={10}>*/}
                    {/*    <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }}>Open Record</Button>*/}
                    {/*</Paper>*/}
                    <Link to="/new" style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Paper elevation={10}>
                            <Button variant="contained" sx={{ backgroundColor: "steelblue", color: "white" }}>New Case</Button>
                        </Paper>
                    </Link>
                </Box>
            </form>
            <Modal open={modal} onClose={handleClose} sx={{ width: '25%', margin: "2vh auto" }}>
                <>
                    <SearchModals
                        handleClose={handleClose}
                        modalType={modalType}
                        ts={textFieldStyle}

                    />
                </>
            </Modal>
        </>
    )
}

export default SearchForm;