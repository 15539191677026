import React, { useContext } from 'react';

import { Box, Button, TextField, Paper, Autocomplete } from '@mui/material';
import { BsArrowCounterclockwise } from 'react-icons/bs';

import { DistributionCheckContext } from '../../../context/DistributionCheckContext';
import { AddValueAndLabel } from '../../../Utils';

const CheckSearch = (props) => {
    const { ts } = props;

    const { payees, receivingEntity, setReceivingEntity, checkNumber, setCheckNumber, handleSearch, loadData } = useContext(DistributionCheckContext);

    const resetSearch = () => {
        setReceivingEntity('');
        setCheckNumber('');
        loadData();
    };

    const entityOptions = AddValueAndLabel(payees, 'paytoName', 'paytoName');

    return (
        <form onSubmit={handleSearch}>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', mb: '1vh' }}>
                <Paper elevation={10}>
                    <Autocomplete
                        fullWidth
                        name="receivingEntities"
                        sx={{ ...ts, width: "18vw" }}
                        options={[{value: '', label: 'N/A'}, ...entityOptions]}
                        value={receivingEntity}
                        onChange={(e, newValue) => setReceivingEntity(newValue ? newValue.value : '') }
                        renderInput={(params) => (<TextField {...params} label="Receiving Entity" />)}
                    />
                </Paper>
                <Paper elevation={10}>
                    <TextField
                        fullWidth
                        sx={ts}
                        id="checkNumber"
                        label="Check No."
                        value={checkNumber}
                        onChange={(e) => { setCheckNumber(e.target.value) }}
                        variant="outlined"
                    />
                </Paper>
                <Paper elevation={10}>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} type="submit">Search</Button>
                </Paper>
                <Paper elevation={10}>
                    <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={resetSearch}>
                        <BsArrowCounterclockwise />&nbsp; Reset
                    </Button>
                </Paper>
            </Box>
        </form>
    )
}

export default CheckSearch;