import React from 'react'
import CaseProvider from '../context/CaseContext';
import CreateCaseForm from '../components/sections/createCase/CreateCaseForm';
import { useNavigate } from 'react-router-dom'

const CreateCaseView = () => {
    const navigate = useNavigate()
    return (
        <CaseProvider>
            <CreateCaseForm navigate={navigate} />
        </CaseProvider>
    )
}

export default CreateCaseView;