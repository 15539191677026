import React, { useContext, useState } from 'react';

import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import { SearchContext } from '../../context/SearchContext';

const Search = () => {
    const { formSubmitted } = useContext(SearchContext);

    const [modal, setModal] = useState(false);
    const [modalType, setModalType] = useState('');

    const handleOpen = (type) => {
        setModalType(type);
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
        setModalType('');
    };

    return (
        <>
            <SearchForm
                modal={modal}
                handleClose={handleClose}
                modalType={modalType}
            />
            {
                formSubmitted && (
                    <SearchResults
                        handleOpen={handleOpen}
                    />
                )
            }
        </>
    );
};

export default Search;