import React from 'react';

import { Box, Paper } from '@mui/material';

import CaseHistoryView from '../../tables/views/paymentsTab/CaseHistoryTable';

const CaseHistoryBox = (props) => {
    const { handleOpen, setSelectedPayment, selectedPayment, retrieveReceipt, retrieveReceiptPayment, setRetrieveReceiptPayment  } = props;

    return (
        <Paper elevation={10}>
            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: 'auto', m: 'auto', p: '.5vh', display: "flex" }}>
                <h6 style={{ fontWeight: 'bold', fontSize: 'inherit', marginBottom: '0px' }}>Case History - Payments</h6>
            </Paper>
            <Box sx={{ p: '0.5rem', width: '99%', m: '0 auto' }}>
                <Paper elevation={10}>
                    <CaseHistoryView
                        handleOpen={handleOpen}
                        setSelectedPayment={setSelectedPayment}
                        selectedPayment={selectedPayment}
                        retrieveReceipt={retrieveReceipt}
                        setRetrieveReceiptPayment={setRetrieveReceiptPayment}
                        retrieveReceiptPayment={retrieveReceiptPayment}
                    />
                </Paper>
            </Box>
        </Paper>
    )
}

export default CaseHistoryBox;