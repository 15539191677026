import React, { useContext, useState } from 'react'
import axios from 'axios';

import { Box, Button, Paper, TextField, LinearProgress } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import BusinessIcon from '@mui/icons-material/Business';

import { toast } from 'react-toastify';

import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { ThemeModeContext, apiRoute } from '../../App';

const ContactModal = (props) => {
    const { closeContact, contactModalCalledFrom } = props;

    const { mode } = useContext(ThemeModeContext);

    const [loading, setLoading] = useState(false);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === 'dark' ? '#4C4E52' : 'white',
            color: mode === 'dark' ? 'white' : 'black',
        },
        '& .MuiInputLabel-root': {
            color: mode === 'dark' ? 'white' : 'black',
            textshadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    const handleSubmitFeedBackForm = (values) => {
        setLoading(true);
        axios.post(`${apiRoute}/api/email/feedback`, values)
            .then(() => {
                toast.success('Feedback submitted successfully! Thank you for the feeback!', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                closeContact();
            })
            .catch((err) => {
                toast.error(`Error ${err.response.status} while submitting feedback`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                console.log(err, 'Error submitting feedback!');
            })
            .finally(() => setLoading(false))
    };

    const feedbackFormValidation = object().shape({
        Subject: string().required('*Subject is required.'),
        Message: string().required('*Message is required.'),
    });

    const initialValues = {
        Subject: '',
        Message: '',
    };

    return (
        <Box sx={{ width: '99.9%', m: '0 auto', height: '100%' }}>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom"
                    sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }}
                    elevation={10}
                >
                    <h1>
                        <strong>{contactModalCalledFrom === 'contactUs' ? 'Contact Us' : 'Feedback'}</strong>
                    </h1>
                </Paper>
                {contactModalCalledFrom === 'contactUs' && (
                    <Box sx={{ p: '1vh' }}>
                        <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'left', width: '70%', m: '0 auto' }}>
                                <p>
                                    <BusinessIcon />:
                                </p>
                                <Box>
                                    <p>10543 N. Glenstone Pl.</p>
                                    <p>Baton Rouge, LA 70810</p>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'left', width: '70%', m: '0 auto' }}>
                                <p>
                                    <LocalPhoneIcon />:
                                </p>
                                <p>225-308-2800</p>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'left', width: '70%', m: '0 auto' }}>
                                <p>
                                    <EmailIcon />:
                                </p>
                                <a href="mailto:support@interactiveus.com" style={{ color: 'steelblue' }}>
                                    support@interactiveus.com
                                </a>
                            </Box>
                        </Box>
                        <hr />
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                            <Button
                                onClick={closeContact}
                                variant="contained"
                                sx={{ backgroundColor: 'steelblue', color: 'white' }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                )}
                {contactModalCalledFrom === 'feedback' && (
                    <Box sx={{ p: '1vh' }}>
                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => handleSubmitFeedBackForm(values)}
                            validationSchema={feedbackFormValidation}
                        >
                            {({ values, setFieldValue, handleBlur, touched, errors }) => (
                                <Form>
                                    <Box width={1} flexWrap="wrap" display="flex" alignItems="center">
                                        <Box width={1}>
                                            <TextField
                                                fullWidth
                                                sx={textFieldStyle}
                                                id="Subject"
                                                label="Subject"
                                                name="Subject"
                                                value={values.Subject}
                                                onChange={(e) => {
                                                    setFieldValue('Subject', e.target.value);
                                                }}
                                                variant="outlined"
                                                size="small"
                                                onBlur={handleBlur}
                                                helperText={touched.Subject ? errors.Subject : ''}
                                                error={touched.Subject && Boolean(errors.Subject)}
                                            />
                                        </Box>
                                        <Box width={1} mt={1}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                minRows={10}
                                                sx={textFieldStyle}
                                                id="Message"
                                                label="Message"
                                                name="Message"
                                                value={values.Message}
                                                onChange={(e) => {
                                                    setFieldValue('Message', e.target.value);
                                                }}
                                                variant="outlined"
                                                inputProps={{ maxLength: 255 }}
                                                size="small"
                                                onBlur={handleBlur}
                                                helperText={touched.Message ? errors.Message : ''}
                                                error={touched.Message && Boolean(errors.Message)}
                                            />
                                        </Box>
                                    </Box>
                                    <hr />
                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                        >
                                            Send
                                        </Button>
                                        <Button
                                            onClick={closeContact}
                                            variant="contained"
                                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                        >
                                            Close
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    </Box>
                )}
                {
                    loading && <LinearProgress />
                }
            </Paper>
        </Box>
    );
}

export default ContactModal;