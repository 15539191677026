import React, { useContext, useState } from 'react';

import { Box, Paper, Modal, Backdrop, CircularProgress } from '@mui/material';

import DistributionCheckTable from '../components/tables/views/DistributionCheckTable';
import CheckSearch from '../components/sections/distributionChecks/CheckSearch';

import { ThemeModeContext } from '../App';
import DistributionCheckProvider from '../context/DistributionCheckContext';
import DistributionCheckModal from '../components/modals/views/DistributionCheckModal';

const DistributionCheck = () => {

    const { mode } = useContext(ThemeModeContext);

    const [selectedCheck, setSelectedCheck] = useState({});
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [loadingCheck, setLoadingCheck] = useState(false);

    const [modal, setModal] = useState(false);

    const handleOpen = () => setModal(true);
    const handleClose = () => setModal(false);

    const textFieldStyle = {
        '& .MuiInputBase-root': {
            backgroundColor: mode === "dark" ? "#4C4E52" : "white",
            color: mode === "dark" ? "white" : "black"
        },
        '& .MuiInputLabel-root': {
            color: mode === "dark" ? "white" : "black",
            textShadow: mode === 'dark' ? '1px 1px 2px black' : 'none',
        },
    };

    return (
        <DistributionCheckProvider>
            <Box sx={{ width: '99.9%', m: '0 auto', height: '100%' }}>
                <Paper sx={{ height: '100%', pb: '1vh' }} elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '1vh', textAlign: 'center' }} elevation={10}>
                        <h1 style={{ fontWeight: 'bold' }}>Distribution Checks</h1>
                    </Paper>
                    <Box sx={{ width: '99%', m: '1vh auto' }}>
                        <CheckSearch ts={textFieldStyle} />
                        <DistributionCheckTable
                            ts={textFieldStyle}
                            handleOpen={handleOpen}
                            setSelectedCheck={setSelectedCheck}
                            modal={modal}
                            setLoadingCheck={setLoadingCheck}
                            handleClose={handleClose}
                            selectedCheck={selectedCheck}
                        />
                    </Box>
                </Paper>
                <Modal open={modal} sx={{ width: '40%', m: '5vh auto' }}>
                    <>
                        <DistributionCheckModal
                            handleClose={handleClose}
                            selectedCheck={selectedCheck}
                            setLoadingPrint={setLoadingPrint}
                            loadingPrint={loadingPrint}
                            ts={textFieldStyle}
                        />
                    </>
                </Modal>
            </Box>
            {
                loadingCheck && (
                    <Backdrop open sx={{ zIndex: 1 }}>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <p>
                                <CircularProgress />
                            </p>
                        </div>
                    </Backdrop>
                )
            }
        </DistributionCheckProvider>
    )
}

export default DistributionCheck;