import React, { useContext, useState } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';

import { Box, Paper, TextField, Button, LinearProgress, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { toast } from 'react-toastify';

import BondsForm from '../components/bondsTab/BondsForm';
import BondCheckForm from '../components/bondsTab/BondCheckForm';

import UserContext from '../../../context/UserContext';
import { BatchContext } from '../../../context/BatchMgmtContext';
import { apiRoute } from '../../../App';
import { CaseContext } from '../../../context/CaseContext';
import OpenBatchModal from '../components/batches/OpenBatchModal';

const BondsModals = (props) => {
    const {
        modalType,
        handleClose,
        ts,
        selectedBond,
        bondRows,
        setBondRows,
        updateCaseBalance,
        handleOpen,
        checkInfo,
        bondNumber,
        newBatch
    } = props;

    // null variable so the open batch modal can be called
    const selectedRow = {};

    const { setCaseHistoryRows, citationView, violationRows } = useContext(CaseContext);
    const { shiftDate, userName, userId, entityId } = useContext(UserContext);
    const { paymentBatchNumber, setPaymentBatchNumber, openBatches, setOpenBatches } = useContext(BatchContext);

    const { caseNumber } = useParams();
    const [batchName, setBatchName] = useState("");

    const [loading, setLoading] = useState(false);

    const [batchDate, setBatchDate] = useState(dayjs(''));
    const [batchType, setBatchType] = useState('SIMPLE');

    const [reason, setReason] = useState('');
    const [errors, setErrors] = useState({});

    const handleBatchOpen = async () => {
        let dayjsBatchDate = dayjs(batchDate);
        const { $D } = dayjsBatchDate;
        if (isNaN($D)) {
            toast.error(`Select a date to open a new batch.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {

            const batchData = {
                fkUserId: userId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                BatchType: batchType,
                batchName: batchName,
                batchNumber: newBatch.batchNumber
            }

            try {
                const res = await axios.post(`${apiRoute}/api/batchtbl`, batchData);
                setOpenBatches([...openBatches, res.data]);
                toast.success(`Batch ${batchName}(${newBatch?.batchNumber}) opened.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            } catch (err) {
                console.error('error closing batch', err);
                if(err?.response.status === 400){
                    toast.error(`Batch already exists with name ${
                        modalType === 'openBatch' 
                        ?
                         `${batchName}(${newBatch?.batchNumber})` 
                         : 
                         selectedRow?.batchName 
                         ? 
                         `${selectedRow?.batchName}(${selectedRow?.batchNumber})`
                         : 
                         `${selectedRow?.batchNumber}`}.`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }else{
                toast.error(`Error opening batch ${batchName}(${newBatch?.batchNumber}).`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
            }
        }
    };

    const deleteBond = async () => {
        if (!reason) {
            setErrors({
                error: 'Reason Required'
            })
            return;
        }
        const bondData = {
            ...selectedBond,
            incidentDate: new Date(selectedBond.incidentDate) || '',
            bondDate: new Date(selectedBond.bondDate) || '',
            deletedDate: new Date(),
            reimbursementDate: new Date(selectedBond.reimbursementDate) || '',
            deletedBy: userName,
            deletedReason: reason,
            appliedToCaseDate: null
        }
        setLoading(true);
        try {
            await axios.put(`${apiRoute}/api/cashbonds/delete/${selectedBond.id}`, bondData);
            setBondRows(bondRows.filter(r => r.id !== selectedBond.id));
            handleClose();
            toast.success(`Bond ${selectedBond.bondNumber} successfully delete.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setReason('');
        } catch (err) {
            console.log(err);
            toast.error(`Error deleting bond ${selectedBond.bondNumber}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const applyBond = async (e) => {
        e.preventDefault();
        if (!paymentBatchNumber) {
            toast.error('Please select the batch this applied bond will be in.', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            return;
        }
        setLoading(true);
        try {
            const applyData = {
                FkCaseId: caseNumber,
                FkViolationId: selectedBond.violationIdFk,
                PaymentAmount: selectedBond.bondAmount,
                ShiftDate: shiftDate,
                PaymentType: "BOND",
                BatchNumber: paymentBatchNumber,
                PayToName: `${selectedBond.postedFirst} ${selectedBond.postedLast}`,
                PayToAddress1: selectedBond.postedAddress1,
                PayToAddress2: selectedBond.postedAddress2,
                PayToCity: selectedBond.postedCity,
                PayToState: selectedBond.postedState,
                PayToZip: selectedBond.postedZip,
            }

            const res = await axios.post(`${apiRoute}/api/cashbonds/apply/${selectedBond.id}`, applyData);
            const newBondRows = res.data.map((row) => ({
                ...row,
                incidentDate: row.incidentDate ? new Date(row.incidentDate).toISOString().substring(0, 10) : null,
                reimbursementDate: row.reimbursementDate ? new Date(row.reimbursementDate).toISOString().substring(0, 10) : null,
                bondDate: row.bondDate ? new Date(row.bondDate).toISOString().substring(0, 10) : null,
                appliedToCaseDate: row.appliedToCaseDate ? new Date(row.appliedToCaseDate).toISOString().substring(0, 10) : null,
                statute: violationRows.filter(v => v.pkViolationId === row.violationIdFk)[0].currentStatute
            }));

            const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
            const paymentDataWithId = paymentRes.data.map((row) => ({
                ...row,
                id: row.pkFfPaymentId,
                paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
            }));

            setBondRows(newBondRows);
            setCaseHistoryRows(paymentDataWithId);
            await updateCaseBalance();
            handleClose();
            toast.success(`Bond #${selectedBond.bondNumber} applied`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } catch (err) {
            toast.error(`Failed to apply bond #${selectedBond.bondNumber}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(err)
        } finally {
            setLoading(false);
        }
    };

    const refundBond = async (e) => {
        setLoading(true);
        const newBond = {
            citationIdFk: citationView.pkCitationId,
            incidentDate: new Date(selectedBond.incidentDate) || '',
            incidentLocation: selectedBond.incidentLocation,
            postedLast: selectedBond.postedLast,
            postedFirst: selectedBond.postedFirst,
            postedPhone1: selectedBond.postedPhone1,
            postedAddress1: selectedBond.postedAddress1,
            postedAddress2: selectedBond.postedAddress2,
            postedCity: selectedBond.postedCity,
            postedState: selectedBond.postedState,
            postedZip: selectedBond.postedZip,
            bondAmount: parseFloat(selectedBond.bondAmount),
            bondNumber: selectedBond.bondNumber,
            reimbursementDate: new Date(selectedBond.reimbursementDate) || '',
            reimbursementCheckNumber: selectedBond.reimbursementCheckNumber,
            receiptNum: selectedBond.receiptNum,
            bondDate: new Date(selectedBond.bondDate) || '',
            refundReason: reason,
        }
        try {
            const res = await axios.post(`${apiRoute}/api/cashbonds/refund/${selectedBond.id}`, newBond);

            const newBondRows = res.data.map((row) => ({
                ...row,
                incidentDate: row.incidentDate ? new Date(row.incidentDate).toISOString().substring(0, 10) : null,
                reimbursementDate: row.reimbursementDate ? new Date(row.reimbursementDate).toISOString().substring(0, 10) : null,
                bondDate: row.bondDate ? new Date(row.bondDate).toISOString().substring(0, 10) : null,
                appliedToCaseDate: row.appliedToCaseDate ? new Date(row.appliedToCaseDate).toISOString().substring(0, 10) : null,
                statute: violationRows.filter(v => v.pkViolationId === row.violationIdFk)[0].currentStatute
            }));
						
            const paymentRes = await axios.get(`${apiRoute}/api/FfPaymentTbls/CaseId/${caseNumber}`);
            const paymentDataWithId = paymentRes.data.map((row) => ({
                ...row,
                id: row.pkFfPaymentId,
                paymentDate: row.paymentDate ? new Date(row.paymentDate).toISOString().split('T')[0] : '',
                dateEnter: row.dateEnter ? new Date(row.dateEnter).toISOString().split('T')[0] : '',
            }));


            setBondRows(newBondRows);
            setCaseHistoryRows(paymentDataWithId);
            await updateCaseBalance();
            handleOpen('checks', '50%');
            toast.success(`Bond #${selectedBond.bondNumber} refunded`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setReason('');
        } catch (err) {
            toast.error(`Failed to refund bond #${selectedBond.bondNumber}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {
                (modalType === 'form' || modalType === 'edit') && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1 style={{ fontWeight: 'bold' }}>
                                    {
                                        modalType === 'form' ? 'Add Bond' : `Edit Bond #${selectedBond.bondNumber}`
                                    }
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <BondsForm
                                    ts={ts}
                                    handleClose={handleClose}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    modalType={modalType}
                                    selectedBond={selectedBond}
                                    setLoading={setLoading}
                                    loading={loading}
                                    bondNumber={bondNumber}
                                />
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'delete' && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1>
                                    <strong>Delete Bond #{selectedBond.bondNumber}</strong>
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <h3>
                                    Please enter the reason you are deleting bond #{selectedBond.bondNumber} below:
                                </h3>
                                <form>
                                    <TextField
                                        sx={{ ...ts, mt: '1vh', pb: '1vh' }}
                                        multiline
                                        rows={7}
                                        value={reason}
                                        onChange={(e) => setReason(e.target.value)}
                                        onBlur={(e) => {
                                            if (!e.target.value) {
                                                setErrors({
                                                    error: 'Reason Required'
                                                })
                                            }
                                        }}
                                        fullWidth
                                        label="Delete Reason"
                                        variant="outlined"
                                        helperText={errors?.error}
                                        error={!!errors?.error}
                                        inputProps={{ maxLength: 255 }}
                                        InputLabelProps={{ shrink: true }}
                                    />
                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh' }}>
                                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={deleteBond} disabled={loading}>Delete Bond</Button>
                                        <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Cancel</Button>
                                    </Box>
                                </form>
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'apply' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                            <h1>
                                <strong>Apply Bond</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <h3 style={{ textAlign: 'center' }}>Apply {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.bondAmount)} from bond #{selectedBond.bondNumber}.</h3>
                            <hr />
                            <Box sx={{ display: "flex", gap: '1rem', alignItems: 'center' }}>
                                <h5 style={{ marginTop: '.5vh' }}>*Select batch to apply bond: </h5>
                                <>
                                    <RadioGroup
                                        row
                                        name='batchSelection'
                                        value={paymentBatchNumber}
                                        onChange={(e) => setPaymentBatchNumber(e.target.value)}
                                    >
                                        {
                                            openBatches?.map(({ batchNumber }) => (
                                                <FormControlLabel
                                                    key={batchNumber}
                                                    value={batchNumber}
                                                    control={<Radio />}
                                                    label={batchNumber}
                                                />
                                            ))
                                        }
                                    </RadioGroup>
                                </>
                            </Box>
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={applyBond} disabled={loading}>Apply Bond</Button>
                                <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                            </Box>
                        </Box>
                        {
                            loading && (
                                <>
                                    <LinearProgress />
                                </>
                            )
                        }
                    </Paper>
                )
            }
            {
                modalType === 'refund' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                            <h1>
                                <strong>Refund Bond</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ width: '99%', m: '1vh auto' }}>
                            <h3 style={{ textAlign: 'center' }}>Refund {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(selectedBond.bondAmount)} from bond #{selectedBond.bondNumber} from this case.</h3>
                            <hr />
                            <form>
                                <TextField
                                    sx={{ ...ts, mt: '1vh', pb: '1vh' }}
                                    multiline
                                    rows={7}
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    fullWidth
                                    label="Refund Reason"
                                    variant="outlined"
                                    helperText={errors?.error}
                                    error={!!errors?.error}
                                    inputProps={{ maxLength: 255 }}
                                    InputLabelProps={{ shrink: true }}
                                />
                                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', py: '1vh' }}>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={refundBond} disabled={loading}>Refund Bond</Button>
                                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                                </Box>
                            </form>
                        </Box>
                        {
                            loading && (
                                <>
                                    <LinearProgress />
                                </>
                            )
                        }
                    </Paper>
                )
            }
            {
                (modalType === 'checks' || modalType === 'checksView') && (
                    <>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', textAlign: 'center', p: '.5vh' }}>
                                <h1>
                                    <strong>
                                        {
                                            modalType === 'checks' ? (
                                                'Add Bond Check'
                                            ) : (
                                                `View Check #${checkInfo.checkNumber} For Bond #${selectedBond.bondNumber}`
                                            )
                                        }
                                    </strong>
                                </h1>
                            </Paper>
                            <Box sx={{ width: '99%', m: '1vh auto' }}>
                                <BondCheckForm
                                    ts={ts}
                                    handleClose={handleClose}
                                    bondRows={bondRows}
                                    setBondRows={setBondRows}
                                    modalType={modalType}
                                    selectedBond={selectedBond}
                                    setLoading={setLoading}
                                    checkInfo={checkInfo}
                                />
                            </Box>
                            {
                                loading && (
                                    <>
                                        <LinearProgress />
                                    </>
                                )
                            }
                        </Paper>
                    </>
                )
            }
            {
                modalType === 'openBatch' && (
                    <>
                        <OpenBatchModal
                            newBatch={newBatch}
                            ts={ts}
                            batchDate={batchDate}
                            setBatchDate={setBatchDate}
                            handleBatchOpen={handleBatchOpen}
                            batchType={batchType}
                            setBatchType={setBatchType}
                            handleClose={handleClose}
                            modalType={modalType}
                            selectedRow={selectedRow}
                            batchName={batchName}
                            setBatchName={setBatchName}
                        />
                    </>
                )
            }
        </>
    )
}

export default BondsModals;