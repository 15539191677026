import axios from 'axios';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';

import { Box, Button, LinearProgress, MenuItem, Paper, TextField } from '@mui/material';
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { toast } from 'react-toastify';

import { apiRoute } from '../../../App';
import { BatchContext } from '../../../context/BatchMgmtContext';
import UserContext from '../../../context/UserContext';
import CloseOutCountModal from '../components/batches/CloseOutCountModal';
import OpenBatchModal from '../components/batches/OpenBatchModal';

const BatchModals = (props) => {
    const {
        isPaymentExists,
        modalType,
        ts,
        handleClose,
        newBatch,
        selectedRow,
        handleOpen,
        resetSearch,
        depositSubmitHandler,
        depositLoad,
        accountingPeriod,
        setAccountingPeriod,
        selectedBank,
        setSelectedBank
    } = props;

    const { userId, entityId, banks } = useContext(UserContext);
    const { setOpenBatches, setBatchRows, openBatches, searchedBatches, setSearchedBatches } = useContext(BatchContext);
    const [batchName, setBatchName] = useState(modalType === "openBatch" ? "Batch " + newBatch.batchNumber : selectedRow.batchName);
    const [batchDate, setBatchDate] = useState(dayjs(''));
    const [batchType, setBatchType] = useState('SIMPLE');
    const [newBatchDate, setNewBatchDate] = useState(dayjs(!selectedRow ? '' : selectedRow.openDatetime));
    const [loading, setLoading] = useState(false);

    // REOPEN/OPEN NEW BATCH
    const handleBatchOpen = async () => {
        let dayjsBatchDate = 
        modalType === 'openBatch' 
        ? 
        dayjs(batchDate)
        : isPaymentExists ? 
        dayjs(selectedRow?.openDatetime) 
        : dayjs(batchDate) ;

         
        const { $D } = dayjsBatchDate;
        if (isNaN($D) ) {
                return toast.error(`Select a date to open a new batch.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
        }
        const { batchNumber, shiftDate, settleDate, settledBy, fkUserId, pkBatchId } = selectedRow;
        const batchData = modalType === 'openBatch'
            ? {
                fkUserId: userId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                batchType: 'SIMPLE',
                batchName: batchName,
                batchNumber: newBatch.batchNumber
            }
            : {
                pkBatchId: pkBatchId,
                fkUserId: fkUserId,
                entityId: entityId,
                openDatetime: dayjsBatchDate.toISOString().split('T')[0],
                closeDatetime: null,
                batchName: selectedRow?.batchName,
                batchType: 'SIMPLE',
                batchNumber: batchNumber,
                shiftDate: shiftDate,
                settleDate: settleDate,
                settledBy: settledBy,
            }
        setLoading(true)
        try {
            const { pkBatchId } = selectedRow;
            const { data } = modalType === 'openBatch'
                ? await axios.post(`${apiRoute}/api/batchtbl`, batchData)
                : await axios.put(`${apiRoute}/api/batchtbl/${pkBatchId}`, batchData)
            setOpenBatches([...openBatches, data]);
            toast.success(`Batch ${modalType === 'openBatch' ? `${batchName}(${newBatch?.batchNumber})` : 
                selectedRow?.batchName 
                 ? 
                 `${selectedRow?.batchName}(${selectedRow?.batchNumber})`
                 : 
                 `${selectedRow?.batchNumber}`} opened.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            resetSearch();
            handleClose();
        } catch (err) {
            console.error('error closing batch', err);
            if(err?.response.status === 400){
                toast.error(`Batch already exists with name ${
                    modalType === 'openBatch' 
                    ?
                     `${batchName}(${newBatch?.batchNumber})` 
                     : 
                     selectedRow?.batchName 
                     ? 
                     `${selectedRow?.batchName}(${selectedRow?.batchNumber})`
                     : 
                     `${selectedRow?.batchNumber}`}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else{

            // const { batchName } = newBatch
            toast.error(`Error opening batch ${
                modalType === 'openBatch' 
                ?
                 `${batchName}(${newBatch?.batchNumber})` 
                 : 
                 selectedRow?.batchName 
                 ? 
                 `${selectedRow?.batchName}(${selectedRow?.batchNumber})`
                 : 
                 `${selectedRow?.batchNumber}`}.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        } finally { setLoading(false) };
    };

    // CLOSE BATCH
    const handleBatchClose = (quick) => {
        setLoading(true);
        const { pkBatchId, batchNumber, batchName } = selectedRow
        axios.put(`${apiRoute}/api/batchtbl/close/${pkBatchId}`)
            .then(() => {
                setSearchedBatches((prevBatches) => prevBatches.filter(({ batchNumber }) => batchNumber !== selectedRow.batchNumber));
                setOpenBatches((prevBatches) => prevBatches.filter(({ batchNumber }) => batchNumber !== selectedRow.batchNumber));
                if (quick) {
                    toast.success(`Batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber} closed.`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                resetSearch();
                handleClose();
            })
            .catch((err) => {
                console.log('error getting batch', err);
                toast.error(`Error ${err.reponse.status} closing batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber}).`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoading(false));
    };

    // CHANGE BATCH DATE
    const handleDateChange = async () => {
        const { batchNumber, pkBatchId, batchName } = selectedRow;
        const { $D } = newBatchDate;
        if (isNaN($D)) {
            return toast.error(`Select which date to update the batch date to.`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }
        setLoading(true);
        axios.get(`${apiRoute}/api/batchtbl/${pkBatchId}`)
            .then((res) => {
                const batchData = {
                    ...res.data,
                    openDatetime: newBatchDate.toISOString().split('T')[0],
                };
                axios.put(`${apiRoute}/api/batchtbl/${pkBatchId}`, batchData)
                    .then(() => {
                        toast.success(`The date for batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber} has been updated.`, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        handleClose();
                        resetSearch();
                    })
                    .catch((err) => {
                        console.error('error changing batch date', err);
                        toast.error(`Error ${err.reponse.status} while changing the date for batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber}.`, {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    })
            })
            .catch((err) => {
                console.log('error getting selected batch', err);
                toast.error(`Error ${err.reponse.status} while changing the date for batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoading(false))
    };

    // SETTLE BATCH
    const handleBatchSettle = () => {
        const { pkBatchId, batchNumber, batchName } = selectedRow;
        setLoading(true);
        axios.put(`${apiRoute}/api/batchtbl/settle/${pkBatchId}`)
            .then(() => {
                setSearchedBatches((prevBatches) => prevBatches.filter(({ batchNumber }) => batchNumber !== selectedRow.batchNumber));
                toast.success(`Batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber} settled.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                handleClose();
            })
            .catch((err) => {
                console.log('error settling batch', err)
                toast.error(`Failed to settle batch ${batchName ? `${batchName}(${batchNumber})` : batchNumber}.`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            })
            .finally(() => setLoading(false))
    };
    const { batchNumber } = selectedRow;
    const { $D } = newBatchDate;

    return (
        <>
            {
                (modalType === 'openBatch' || modalType === 'reopenBatch') && (
                    <OpenBatchModal
                        newBatch={newBatch}
                        ts={ts}
                        isPaymentExists={isPaymentExists}
                        batchDate={batchDate}
                        setBatchDate={setBatchDate}
                        handleBatchOpen={handleBatchOpen}
                        batchType={batchType}
                        setBatchType={setBatchType}
                        handleClose={handleClose}
                        modalType={modalType}
                        selectedRow={selectedRow}
                        loading={loading}
                        batchName={batchName}
                        setBatchName={setBatchName}
                    />
                )
            }
            {
                modalType === 'closeBatch' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Close Batch</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1vh' }}>
                            <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                <h2>Do you want to close batch {selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber}?</h2>
                            </Box>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                <Button
                                    onClick={() => handleOpen('moneyCountCheck', '35%')}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={() => handleClose()}
                                    variant="contained"
                                    sx={{ backgroundColor: 'steelblue', color: 'white' }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'moneyCountCheck' && (
                    <Paper elevation={10} sx={{ width: '80%', ml: '3vw' }}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '1vh', textAlign: 'center' }} elevation={10}>
                            <h3 style={{ fontWeight: 'bold' }}>Close Out Drawer Count?</h3>
                        </Paper>
                        <Box display="flex" gap={5} m={5} pb={1} justifyContent="center">
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mb: '3vh' }} onClick={() => handleOpen('moneyCount', '75%')}>Count the Drawer</Button>
                            <Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white', mb: '3vh' }} onClick={() => handleBatchClose(true)}>Skip Drawer Count</Button>
                            {/*<Button variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={() => handleClose}>Cancel</Button>*/}
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'moneyCount' && (
                    <>
                        <CloseOutCountModal
                            selectedRow={selectedRow}
                            handleCloseOutCountModal={handleClose}
                            handleBatchClose={handleBatchClose}
                            ts={ts}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    </>
                )
            }
            {
                modalType === 'changeDate' && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Paper elevation={10}>
                            <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                                <h1>
                                    <strong>Change Batch Date</strong>
                                </h1>
                            </Paper>
                            <Box sx={{ p: '1vh' }}>
                                <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                    <h3>Do you want to change the date for batch {selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber}?</h3>
                                </Box>
                                <hr />
                                <Box>
                                    <h5>
                                        Select the new date for this batch:
                                    </h5>
                                    <StaticDatePicker
                                        value={newBatchDate}
                                        onChange={(newDate) => setNewBatchDate(newDate)}
                                        renderInput={(params) => <TextField {...params} />}
                                        componentsProps={{
                                            actionBar: {
                                                actions: ['today'],
                                            },
                                        }}
                                        disabled={isPaymentExists}
                                    />
                                </Box>
                                <hr />
                                <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                    <Button onClick={handleDateChange} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }} disabled={isNaN($D)}>Save Changes</Button>
                                    <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Cancel</Button>
                                </Box>
                            </Box>
                            {
                                loading && <LinearProgress />
                            }
                        </Paper>
                    </LocalizationProvider>
                )
            }
            {
                modalType === 'settleBatch' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                            <h1>
                                <strong>Settle Batch</strong>
                            </h1>
                        </Paper>
                        <Box sx={{ p: '1vh' }}>
                            <Box sx={{ textAlign: 'center', mb: '2vh' }}>
                                <h2>Do you want to settle batch {selectedRow?.batchName ? `${selectedRow?.batchName}(${selectedRow?.batchNumber})`: selectedRow?.batchNumber} ?</h2>
                            </Box>
                            <hr />
                            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'right' }}>
                                <Button onClick={handleBatchSettle} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>Yes</Button>
                                <Button onClick={handleClose} variant="contained" sx={{ backgroundColor: 'steelblue', color: 'white' }}>No</Button>
                            </Box>
                        </Box>
                        {
                            loading && <LinearProgress />
                        }
                    </Paper>
                )
            }
            {
                modalType === 'depositBatch' && (
                    <Paper
                        elevation={10}
                    >
                        <Paper
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                p: '.5vh',
                                mb: '2vh',
                                textAlign: 'center'
                            }}
                            elevation={10}
                        >
                            <h1>
                                <strong>Deposit Batches</strong>
                            </h1>
                        </Paper>
                        <Box
                            sx={{
                                p: '1vh'
                            }}
                        >
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mb: '2vh'
                                }}
                            >
                                <h4>Do you want to deposit the selected batches?</h4>
                                <TextField
                                    sx={{
                                        width: "16vw",
                                        mt: '2vh'
                                    }}
                                    select
                                    label="Select Bank Account"
                                    value={selectedBank}
                                    onChange={
                                        (e) => {
                                            setSelectedBank(e.target.value)
                                        }
                                    }
                                >
                                    <MenuItem value={'N/A'}>N/A</MenuItem>
                                    {
                                        banks.map((bank) => {
                                            return (
                                                <MenuItem
                                                    key={bank.pkBankId}
                                                    value={bank.pkBankId}
                                                >
                                                    {bank.name}
                                                </MenuItem>
                                            )
                                        })

                                    }
                                </TextField>
                                <TextField
                                    sx={{
                                        width: "16vw", mt: '2vh'
                                    }}
                                    label="Accounting Period"
                                    value={accountingPeriod}
                                    onChange={(e) => { setAccountingPeriod(e.target.value) }}
                                >
                                </TextField>
                            </Box>
                            <hr />
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '1rem',
                                    justifyContent: 'right'
                                }}
                            >
                                <Button
                                    onClick={depositSubmitHandler}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'steelblue',
                                        color: 'white'
                                    }}
                                >
                                    Yes
                                </Button>
                                <Button
                                    onClick={handleClose}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'steelblue',
                                        color: 'white'
                                    }}
                                >
                                    No
                                </Button>
                            </Box>
                        </Box>
                        {
                            depositLoad && <LinearProgress />
                        }
                    </Paper>
                )
            }
        </>
    )
}

export default BatchModals;