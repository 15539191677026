import React, { useContext } from 'react';

import { Box, Paper, TextField } from '@mui/material';

import { CaseContext } from '../../../context/CaseContext';
import { commaMoneyFormat } from '../../../Utils';

const FeeFinesBox = (props) => {
    const { ts } = props;

    const {
        assessFees,
        assessFines,
        assessCosts,
        assessTotal,
        suspendFees,
        suspendFines,
        suspendCosts,
        suspendTotal,
        netFees,
        netFines,
        netCosts,
        netTotal,
    } = useContext(CaseContext);

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
            <Paper sx={{ width: "auto", display: "flex", p: '1vh' }} elevation={10}>
                <Box sx={{ display: "flex", flexDirection: "column", marginRight: ".25rem" }}>
                    <br />
                    <strong>
                        Fines:
                    </strong>
                    <br />
                    <strong>
                        Fees:
                    </strong>
                    <br />
                    <strong>
                        Costs:
                    </strong>
                    <br />
                    <strong>
                        Total:
                    </strong>
                </Box>
                <Box sx={{ display: "flex", alignItems: 'right' }}>
                    <Box sx={{ display: "flex", flexDirection: "column", marginRight: ".25rem" }}>
                        <strong>Assessed</strong>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(assessFines)}
                                id="assessFines"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(assessFees)}
                                id="assessFees"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(assessCosts)}
                                id="assessCosts"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(assessTotal)}
                                id="assessTotals"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", marginRight: ".25rem" }}>
                        <strong>Suspended</strong>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(suspendFines)}
                                id="suspendFines"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(suspendFees)}
                                id="suspendFees"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(suspendCosts)}
                                id="suspendCosts"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(suspendTotal)}
                                id="suspendTotal"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", marginRight: ".25rem" }}>
                        <strong>Net</strong>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(netFines)}
                                id="netFines"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(netFees)}
                                id="netFees"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(netCosts)}
                                id="netCosts"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                        <Box marginBottom={.25}>
                            <TextField
                                sx={ts}
                                value={commaMoneyFormat(netTotal)}
                                id="netTotal"
                                size="small"
                                inputProps={{ min: 0, style: { textAlign: 'right' }, readOnly: true }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

export default FeeFinesBox;