import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';

import { apiRoute } from '../App.js';

const DistributionCheckContext = createContext({
    loading: Boolean,
    setLoading: () => { },
    rows: [],
    setRows: () => { },
    payees: [],
    setPayees: () => { },
    receivingEntity: '',
    setReceivingEntity: () => { },
    checkNumber: '',
    setCheckNumber: () => { },
    checksPrinted: Boolean,
    setChecksPrinted: () => { },
})

const DistributionCheckProvider = ({ children }) => {
    const [rows, setRows] = useState([]);
    const [payees, setPayees] = useState([]);
    const [receivingEntity, setReceivingEntity] = useState('');
    const [checkNumber, setCheckNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [checksPrinted, setChecksPrinted] = useState(false);

    const loadData = () => {
        setLoading(true);
        axios.get(`${apiRoute}/api/ffCheckTbls`)
            .then((res) => {
                const rows = res.data.map((row) => (
                    row = {
                        ...row,
                        id: row.pkFfCheckId
                    }
                ));
                axios.get(`${apiRoute}/api/payeeTbls/disburse`)
                    .then((res) => {
                        const sortedPayees = res.data.sort((a, b) => a.paytoName.localeCompare(b.paytoName))
                        setPayees(sortedPayees);
                    })
                    .catch((err) => console.log(err))
                setRows(rows);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    const handleSearch = (e) => {
        e.preventDefault();

        // build search params string
        const searchParams = new URLSearchParams();
        if (receivingEntity) searchParams.set('receivingEntity', receivingEntity)
        if (checkNumber) searchParams.set('checkNumber', checkNumber)

        setLoading(true);

        axios.get(`${apiRoute}/api/ffCheckTbls/search?${searchParams.toString()}`)
            .then((res) => {
                const rows = res.data.map((row) => (
                    row = {
                        ...row,
                        id: row.pkFfCheckId
                    }
                ));
                setRows(rows);
            })
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    };

    useEffect(() => { loadData() }, []);

    const contextValue = {
        loading: loading,
        setLoading,
        rows: rows,
        setRows,
        payees: payees,
        setPayees,
        receivingEntity: receivingEntity,
        setReceivingEntity,
        checkNumber: checkNumber,
        setCheckNumber,
        handleSearch,
        loadData,
        checksPrinted: checksPrinted,
        setChecksPrinted,
    };

    return (
        <DistributionCheckContext.Provider value={contextValue}>
            {children}
        </DistributionCheckContext.Provider>
    )
}

export default DistributionCheckProvider;
export { DistributionCheckContext };