import React from 'react';
import axios from 'axios';

import { Box, Button, Typography, Paper, LinearProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { apiRoute } from '../../../../../App';

const DeleteModal = (props) => {
    const { handleClose, name, loading, setLoading, data, controller, setRows, getRows } = props;
    const handleDelete = () => {
        setLoading(true);
        axios.delete(`${apiRoute}/api/${controller}/${controller === 'OfficerTbls' ? data.pkOfficerId : data.id}`)
            .then(() => {
                setRows((prevRows) => prevRows.filter(row => row.id !== data.id));
                toast.success(`${name === 'Agencies' ? 'Delete Agency' : `Delete ${name.substring(0, name.length - 1)}`} successfully deleted`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
                handleClose();
            })
            .catch((err) => {
                toast.error(`Error ${err?.response?.status} while trying to delete ${name === 'Agencies' ? 'Delete Agency' : `Delete ${name.substring(0, name.length - 1)}`}.`, {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                });
            })
            .finally(() => { setLoading(false); getRows() })
    }

    return (
        <>
            <Paper elevation={10}>
                <Paper sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        {/* GETS RID OF THE 'S' AT THE END OF THE STRING */}
                        <strong>
                            {
                                name === 'Agencies'
                                    ? 'Delete Agency'
                                    : `Delete ${name.substring(0, name.length - 1)}`
                            }
                        </strong>
                    </h1>
                </Paper>
                <Box width={1} textAlign="center" fontSize="50px">
                    <Typography my={2} fontSize={24} fontWeight="bold">
                        Are you sure you want to delete this {
                            name === 'Agencies'
                                ? 'Agency?'
                                : `${name.substring(0, name.length - 1)}?`
                        }
                    </Typography>
                    <Typography mx={8}>You won't be able to revert this!</Typography>
                </Box>
                <Box display="flex" gap={2} mt={2} pb={1} justifyContent="center">
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            mt: '2vh',
                        }}
                        onClick={handleDelete}
                    >
                        Yes
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            backgroundColor: 'steelblue',
                            color: 'white',
                            mt: '2vh',
                        }}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </Box>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    )
}

export default DeleteModal;