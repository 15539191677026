import React, { useContext } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import { RiPrinterLine } from "react-icons/ri";

import { BatchContext } from '../../../../context/BatchMgmtContext';

const BatchReports = (props) => {
    const {
        anchorEl,
        row,
        handleCloseDropdown,
        collectionReport,
        depositReport,
        batchReportSubmitHandler,
    } = props;

    const { statusCheck } = useContext(BatchContext);

    return (
        <Menu
            id='violation-menu'
            anchorEl={anchorEl[row.id]}
            open={Boolean(anchorEl[row.id])}
            onClose={() => handleCloseDropdown(row.id)}
            MenuListProps={{
                'aria-labelledby': 'violation-actions',
            }}
        >
            <MenuItem onClick={() => handleCloseDropdown(row.id)}>
                <Button
                    fullWidth
                    onClick={() => collectionReport(row.batchNumber)}
                    variant="contained"
                    sx={{ backgroundColor: "steelblue", color: 'white' }}
                >
                    Collection Report &nbsp; <RiPrinterLine />
                </Button>
            </MenuItem>
            {statusCheck !== 'Open' && [
                //<MenuItem key="batch-closeout" onClick={() => handleCloseDropdown(row.id)}>
                //    <Button
                //        fullWidth
                //        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                //        variant="contained"
                //        onClick={() => batchReportSubmitHandler('Batch Closeout', row.batchNumber)}
                //    >
                //        Batch Closeout Report
                //    </Button>
                //</MenuItem>,
                <MenuItem key="drawer-count" onClick={() => handleCloseDropdown(row.id)}>
                    <Button
                        fullWidth
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        variant="contained"
                        onClick={() => batchReportSubmitHandler('Drawer Count', row.pkBatchId)}
                    >
                        Drawer Count Report
                    </Button>
                </MenuItem>
            ]}
            {statusCheck === "Deposited" && (
                <MenuItem onClick={() => handleCloseDropdown(row.id)}>
                    <Button
                        fullWidth
                        onClick={() => depositReport(row.batchNumber)}
                        variant="contained"
                        sx={{ backgroundColor: "steelblue", color: 'white' }}
                    >
                        Deposit Report &nbsp; <RiPrinterLine />
                    </Button>
                </MenuItem>
            )}
        </Menu>
    )
};

export default BatchReports;