import React, { useReducer, useState } from 'react';
import axios from 'axios';

import { Box, Grid, Button, MenuItem, TextField, Paper, FormControlLabel, Checkbox, InputAdornment } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { MdEditOff } from "react-icons/md";

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../App.js';
import { stateOptions } from '../../../../Utils.js';

const BondCheckForm = (props) => {
    const { handleClose, ts, setLoading, selectedBond, modalType, checkInfo } = props;

    const initialBondState = {
        payToName: modalType === "checksView" ? checkInfo.payToName : '',
        payToAddress1: modalType === "checksView" ? checkInfo.payToAddress1 : '',
        payToAddress2: modalType === "checksView" ? checkInfo.payToAddress2 : '',
        payToCity: modalType === "checksView" ? checkInfo.payToCity : '',
        payToState: modalType === "checksView" ? checkInfo.payToState : '',
        payToZip: modalType === "checksView" ? checkInfo.payToZip : '',
        checkNumber: modalType === "checksView" ? checkInfo.checkNumber : '',
        checkAmount: modalType === "checksView" ? checkInfo.checkAmount.toFixed(2) : selectedBond.bondAmount.toFixed(2),
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialBondState);

    const [autoFill, setAutoFill] = useState(false);
    const displayCheckAmount = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(state.checkAmount);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;

        if (!value && ['payToName', 'payToAddress1', 'payToCity', 'payToState', 'payToZip', 'checkNumber', 'checkAmount'].includes(field)) {
            error = 'Field is required';
        }

        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const createCheck = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payeeData = {
            PayToName: state.payToName,
            PayToAddress1: state.payToAddress1,
            PayToAddress2: state.payToAddress2,
            PayToCity: state.payToCity,
            PayToState: state.payToState,
            PayToZip: state.payToZip,
        }

        try {
            await axios.post(`${apiRoute}/api/payeetbls/check/${state.checkAmount}/${state.checkNumber}/${selectedBond.id}`, payeeData);
            toast.success('Successfully created check', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            handleClose();
        } catch (err) {
            console.log(err);
            toast.error('Failed to create check', {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleAutoFill = () => {
        let bool = !autoFill;
        setAutoFill(!autoFill);
        if (bool) {
            handleFieldChange('payToName', `${selectedBond.postedFirst} ${selectedBond.postedLast}`);
            handleFieldChange('payToAddress1', selectedBond.postedAddress1);
            handleFieldChange('payToAddress2', selectedBond.postedAddress2);
            handleFieldChange('payToCity', selectedBond.postedCity);
            handleFieldChange('payToState', selectedBond.postedState);
            handleFieldChange('payToZip', selectedBond.postedZip);
        } else {
            handleFieldChange('payToName', state?.payToName);
            handleFieldChange('payToAddress1', state?.payToAddress1);
            handleFieldChange('payToAddress2', state?.payToAddress2);
            handleFieldChange('payToCity', state?.payToCity);
            handleFieldChange('payToState', state?.payToState);
            handleFieldChange('payToZip', state?.payToZip);
        }
    }

    const printCheck = () => {
        setLoading(true);
        axios.get(`${apiRoute}/api/ffchecktbls/bondcheck/${checkInfo.sourceId}`, { responseType: 'arraybuffer' })
            .then((response) => {
                const arrayBufferView = new Uint8Array(response.data);
                const blob = new Blob([arrayBufferView], { type: 'application/pdf' });
                const dataUrl = URL.createObjectURL(blob);
                return dataUrl
            })
            .then((dataUrl) => {
                window.open(dataUrl, '_blank');
            })
            .catch(error => {
                console.error('Error fetching receipt from front end:', error);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    return (
        <form onSubmit={createCheck}>
            <Paper sx={{ mb: '1vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: '3vh' }} elevation={10}>
                    <h6>Pay To The Order Of</h6>
                </Paper>
                {
                    modalType === 'checks' && (
                        <Box sx={{ p: '1vh' }}>
                            <FormControlLabel control={<Checkbox onClick={handleAutoFill} checked={autoFill} />} label="Autofill check fields with the existing posted by bond data" />
                        </Box>
                    )
                }
                <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1vh' }}>
                    <Grid item sm={12}>
                        <TextField
                            required
                            sx={{...ts, width: '50%'}}
                            name="payToName"
                            id="payToName"
                            label="Name"
                            value={state.payToName || ''}
                            onChange={(e) => { handleFieldChange('payToName', e.target.value) }}
                            onBlur={(e) => { handleBlur('payToName', e.target.value) }}
                            helperText={state?.errors?.payToName}
                            error={!!state?.errors?.payToName}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: ( modalType === 'checksView' && 
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            required
                            sx={{ ...ts, width: '50%' }}
                            name="payToAddress1"
                            id="payToAddress1"
                            label="Address"
                            value={state.payToAddress1 || ''}
                            onChange={(e) => { handleFieldChange('payToAddress1', e.target.value) }}
                            onBlur={(e) => { handleBlur('payToAddress1', e.target.value) }}
                            helperText={state?.errors?.payToAddress1}
                            error={!!state?.errors?.payToAddress1}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            sx={{ ...ts, width: '50%' }}
                            name="payToAdress2"
                            id="payToAdress2"
                            label="Line 2"
                            value={state.payToAddress2 || ''}
                            onChange={(e) => { handleFieldChange('payToAdress2', e.target.value) }}
                            onBlur={(e) => { handleBlur('payToAdress2', e.target.value) }}
                            helperText={state?.errors?.payToAdress2}
                            error={!!state?.errors?.payToAdress2}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            required
                            fullWidth
                            sx={ts}
                            name="payToCity"
                            id="payToCity"
                            label="City"
                            value={state.payToCity || ''}
                            onChange={(e) => { handleFieldChange('payToCity', e.target.value) }}
                            onBlur={(e) => { handleBlur('payToCity', e.target.value) }}
                            helperText={state?.errors?.payToCity}
                            error={!!state?.errors?.payToCity}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={1.5}>
                        <TextField
                            required
                            fullWidth
                            select
                            sx={ts}
                            name="payToState"
                            id="payToState"
                            label="State"
                            value={state.payToState || ''}
                            onChange={(e) => { handleFieldChange('payToState', e.target.value) }}
                            onBlur={(e) => { handleBlur('payToState', e.target.value) }}
                            helperText={state?.errors?.payToState}
                            error={!!state?.errors?.payToState}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                            SelectProps={{
                                MenuProps: {
                                    PaperProps: {
                                        style: {
                                            maxHeight: '20em',
                                        },
                                    },
                                },
                            }}
                        >
                            <MenuItem value=''>N/A</MenuItem>
                            {
                                stateOptions?.map(({ value, label }) => {
                                    return <MenuItem key={label} value={value}>{label}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item sm={3}>
                        <TextField
                            required
                            fullWidth
                            sx={ts}
                            name="payToZip"
                            id="payToZip"
                            label="ZIP Code"
                            value={state.payToZip || ''}
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                if (/^\d*$/.test(inputValue)) {
                                    handleFieldChange('payToZip', inputValue);
                                }
                            }}
                            onBlur={(e) => { handleBlur('payToZip', e.target.value) }}
                            helperText={state?.errors?.payToZip}
                            error={!!state?.errors?.payToZip}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView", maxLength: 5 }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Paper sx={{ mb: '1vh' }} elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', height: '3vh' }} elevation={10}>
                    <h6>Check Data</h6>
                </Paper>
                <Grid container direction="row" sx={{ display: 'flex', gap: '1rem', flexWrap: 'wrap', p: '1vh' }}>
                    <Grid item sm={3}>
                        <TextField
                            required
                            sx={ts}
                            name="checkNumber"
                            id="checkNumber"
                            label="Check #"
                            value={state.checkNumber || ''}
                            onChange={(e) => { handleFieldChange('checkNumber', e.target.value.replace(/[^0-9.]/g, '')) }}
                            onBlur={(e) => { handleBlur('checkNumber', e.target.value) }}
                            helperText={state?.errors?.checkNumber}
                            error={!!state?.errors?.checkNumber}
                            variant="outlined"
                            inputProps={{ readOnly: modalType === "checksView" }}
                            InputProps={{
                                startAdornment: (modalType === 'checksView' &&
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                    <Grid item sm={2}>
                        <TextField
                            fullWidth
                            sx={ts}
                            name="checkAmount"
                            id="checkAmount"
                            label="Amount"
                            value={displayCheckAmount}
                            variant="outlined"
                            inputProps={{ readOnly: true, style: { textAlign: 'right' } }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <MdEditOff />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh' }}>
                {modalType === "checks" && (
                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} type="submit">Create Check</Button>
                )}
                {modalType === "checksView" && (
                    <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={printCheck}>Print Check&nbsp;<PrintIcon /></Button>
                )}
                {modalType !== 'checks' && <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>}
            </Box>
        </form>
    )
};

export default BondCheckForm;