import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import jwt_decode from 'jwt-decode';

import { Box, LinearProgress, Button, TextField, IconButton, InputAdornment, List, ListItem, ListItemText } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

import * as yup from 'yup';
import { withFormik } from 'formik';
import passwordValidations from '../../schemas/PasswordSchema';

import Logo from "../../images/iss.png";
import BG from "../../images/LoginPng.png";

import { ThemeModeContext, apiRoute } from '../../App';

const ResetPassword = (props) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
    } = props;

    const { mode } = useContext(ThemeModeContext);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loadingPassword, setLoadingPassword] = useState(false);
    const [error, setError] = useState('');
    const errorArray = [];
    const [eArray, setEArray] = useState(errorArray);
    const [errorOne, setErrorOne] = useState('initial');
    const [errorTwo, setErrorTwo] = useState('initial');
    const [errorThree, setErrorThree] = useState('initial');
    const [errorFour, setErrorFour] = useState('initial');
    const [errorFive, setErrorFive] = useState('initial');

    const navigate = useNavigate();

    const { token } = useParams();

    const resetPassword = async (e) => {
        e.preventDefault();
        setLoadingPassword(true);
        const decodedToken = jwt_decode(token);
        const userPkId = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
        try {
            const newUser = {
                pkUserId: userPkId,
                password: values.password
            }
            const res = await axios.post(`${apiRoute}/api/auth/resetPassword`, newUser);
            try {
                const userLogin = {
                    email: res.data.email,
                    password: values.password
                }
                const loginRes = await axios.post(`${apiRoute}/api/auth/login`, userLogin);
                const token = loginRes.data;
                localStorage.setItem('token', token);
                //Token is valid, set it as a default Authorization header for axios
                axios.defaults.headers.common = {
                    'Authorization': `Bearer ${token}`
                };
                localStorage.removeItem('passwordToken');
                toast.success('Password updated', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate('/');
            } catch (err) {
                console.log('error updating user', err);
            }
        } catch (err) {
            if (err.response.data === 'Same Password') {
                setError("New password can't match original password");
            }
        } finally {
            setLoadingPassword(false);
        }
    };

    const handleClickShowPassword = (e) => {
        setShowPassword((show) => !show);
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };
    const handleClickShowConfirmPassword = (e) => {
        setShowConfirmPassword((show) => !show);
    };

    const handleMouseDownConfirmPassword = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        const errorCheck = () => {
            if (touched.password) {
                setErrorOne(values.password.length < 8 || values.password.length > 20 ? 'error' : 'valid');
                values.password.length < 8 || values.password.length > 20 ? errorArray.push('Password must be 8-20 characters long') : errorArray.push(...errorArray);

                setErrorTwo(!/[A-Z]/.test(values.password) ? 'error' : 'valid');
                !/[A-Z]/.test(values.password) ? errorArray.push('Password must have at least 1 uppercase letter') : errorArray.push(...errorArray);

                setErrorThree(!/[a-z]/.test(values.password) ? 'error' : 'valid');
                !/[a-z]/.test(values.password) ? errorArray.push('Password must have at least 1 lowercase letter') : errorArray.push(...errorArray);

                setErrorFour(!/\d/.test(values.password) ? 'error' : 'valid');
                !/\d/.test(values.password) ? errorArray.push('Password must have at least 1 number') : errorArray.push(...errorArray);

                setErrorFive(!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(values.password) ? 'error' : 'valid');
                !/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(values.password) ? errorArray.push('Password must have at least 1 special character') : errorArray.push(...errorArray);

                setEArray(errorArray);

                return true;
            } else {
                return false;
            }
        };
        errorCheck();
    }, [touched.password, values.password]);

    const disableSubmit = Object.keys(errors).length > 0 || values.password !== values.confirmPassword || (values.password === '' && values.confirmPassword === '') || eArray.length > 0;

    return (
        // bgContainer
        <Box sx={{
            backgroundImage: `url(${BG})`,
            backgroundSize: '50%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50% -1vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
        }}>
            {/* loginContainer */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                justifyContent: 'center',
                backgroundSize: 'contain'
            }}>
                {/* loginBox */}
                <Box sx={{
                    position: 'relative',
                    zIndex: '0',
                    borderRadius: '20px',
                    filter: 'drop-shadow(0px 1px 0.5px black)',
                    minHeight: '38vh',
                    minWidth: '50vh',
                    backgroundColor: mode === 'dark' ? '#2f3437' : 'white'
                }}>
                    {/* logoBox */}
                    <Box sx={{
                        width: '45vh',
                        margin: '0 auto',
                        position: 'relative',
                        zIndex: '1',
                        textAlign: 'center',
                        bottom: '40px',
                        borderRadius: '10px',
                        filter: 'drop-shadow(0px 1px .5px gray)',
                        backgroundColor: 'black'
                    }}>
                        <img
                            src={Logo}
                            alt="Interactive Software Solutions Logo"
                            // style={{ padding: '' }}
                            width='115rem'
                        />
                    </Box>
                    <form onSubmit={resetPassword}>
                        <Box px={10} sx={{
                            textAlign: 'center',
                            backgroundColor: mode === 'dark' ? '#2f3437' : 'white'
                        }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <h3>
                                    <strong>Password Requirements: </strong>
                                </h3>
                                <List>
                                    <ListItem sx={{ textAlign: 'center' }}>
                                        <ListItemText>
                                            {
                                                errorOne === 'initial' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ textAlign: 'center' }} ><CircleIcon sx={{ width: '1vh' }} /></Box>
                                                        <Box sx={{ textAlign: 'center' }} >Must be 8-20 characters</Box>
                                                    </Box>
                                                ) : errorOne === 'error' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'red' }}><CloseIcon /></Box>
                                                        <Box sx={{ color: 'red' }}>Must be 8-20 characters</Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'green' }}><CheckIcon /></Box>
                                                        <Box sx={{ color: 'green' }}>Must be 8-20 characters</Box>
                                                    </Box>
                                                )
                                            }
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem sx={{ textAlign: 'center' }}>
                                        <ListItemText>
                                            {
                                                errorTwo === 'initial' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ textAlign: 'center' }} ><CircleIcon sx={{ width: '1vh' }} /></Box>
                                                        <Box sx={{ textAlign: 'center' }} >Must have at least 1 uppercase letter</Box>
                                                    </Box>
                                                ) : errorTwo === 'error' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'red' }}><CloseIcon /></Box>
                                                        <Box sx={{ color: 'red' }}>Must have at least 1 uppercase letter</Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'green' }}><CheckIcon /></Box>
                                                        <Box sx={{ color: 'green' }}>Must have at least 1 uppercase letter</Box>
                                                    </Box>
                                                )
                                            }
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem sx={{ textAlign: 'center' }}>
                                        <ListItemText>
                                            {
                                                errorThree === 'initial' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ textAlign: 'center' }} ><CircleIcon sx={{ width: '1vh' }} /></Box>
                                                        <Box sx={{ textAlign: 'center' }} >Must have at least 1 lowercase letter</Box>
                                                    </Box>
                                                ) : errorThree === 'error' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'red' }}><CloseIcon /></Box>
                                                        <Box sx={{ color: 'red' }}>Must have at least 1 lowercase letter</Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'green' }}><CheckIcon /></Box>
                                                        <Box sx={{ color: 'green' }}>Must have at least 1 lowercase letter</Box>
                                                    </Box>
                                                )
                                            }
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem sx={{ textAlign: 'center' }}>
                                        <ListItemText>
                                            {
                                                errorFour === 'initial' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ textAlign: 'center' }} ><CircleIcon sx={{ width: '1vh' }} /></Box>
                                                        <Box sx={{ textAlign: 'center' }} >Must have at least 1 number</Box>
                                                    </Box>
                                                ) : errorFour === 'error' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'red' }}><CloseIcon /></Box>
                                                        <Box sx={{ color: 'red' }}>Must have at least 1 number</Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'green' }}><CheckIcon /></Box>
                                                        <Box sx={{ color: 'green' }}>Must have at least 1 number</Box>
                                                    </Box>
                                                )
                                            }
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem sx={{ textAlign: 'center' }}>
                                        <ListItemText>
                                            {
                                                errorFive === 'initial' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ textAlign: 'center' }} ><CircleIcon sx={{ width: '1vh' }} /></Box>
                                                        <Box sx={{ textAlign: 'center' }} >Must have at least 1 special character</Box>
                                                    </Box>
                                                ) : errorFive === 'error' ? (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'red' }}><CloseIcon /></Box>
                                                        <Box sx={{ color: 'red' }}>Must have at least 1 special character</Box>
                                                    </Box>
                                                ) : (
                                                    <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'start', alignItems: 'center' }}>
                                                        <Box sx={{ color: 'green' }}><CheckIcon /></Box>
                                                        <Box sx={{ color: 'green' }}>Must have at least 1 special character</Box>
                                                    </Box>
                                                )
                                            }
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Box mb={5}>
                                    <TextField
                                        fullWidth
                                        id='password'
                                        label='Password'
                                        type={showPassword ? 'text' : 'password'}
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.password ? errors.password : ''}
                                        error={touched.password && (Boolean(errors.password) || eArray.length > 0)}
                                        margin="dense"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge='end'
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <TextField
                                        fullWidth
                                        id='confirmPassword'
                                        label='Confirm Password'
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        value={values.confirmPassword}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.confirmPassword ? errors.confirmPassword : ''}
                                        error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                                        margin="dense"
                                        variant="standard"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowConfirmPassword}
                                                        onMouseDown={handleMouseDownConfirmPassword}
                                                        edge='end'
                                                    >
                                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                {error && <Box sx={{ color: 'red', mt: '1vh' }}>{error}</Box>}
                            </Box>
                            <Box sx={{
                                padding: '4vh 0',
                                filter: 'drop-shadow(0px 1px .5px gray)',
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <Button type='submit' variant='contained' color='error' sx={{ width: '100%', m: '0 auto' }} disabled={disableSubmit}>
                                    Reset Password
                                </Button>
                            </Box>
                            {
                                loadingPassword && (
                                    <LinearProgress color="error" sx={{ width: '99.5%', m: '0 auto' }} />
                                )
                            }
                        </Box>
                    </form>
                </Box>
            </Box>
            
        </Box>
    )
};

const Form  = withFormik({
    mapPropsToValues: ({
        password,
        confirmPassword
    }) => {
        return {
            password: password || '',
            confirmPassword: confirmPassword || '',
        }
    },

    validationSchema: yup.object().shape(passwordValidations),

    handleSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
            toast.info(JSON.stringify(values, null, 2), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            setSubmitting(false);
        }, 1000);
    }
})(ResetPassword);

export default Form;