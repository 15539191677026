import React, { useReducer } from 'react';
import axios from 'axios';

import { Box, Button, TextField, Paper, LinearProgress } from '@mui/material';

import { toast } from 'react-toastify';

import { apiRoute } from '../../../../../App';

const SpeedingAmountForm = (props) => {
    const { handleClose, data, modalType, loading, setLoading, ts, rows, setRows, getRows } = props;

    const initialState = {
        startRange: modalType === 'add' ? null : data?.startRange,
        endRange: modalType === 'add' ? null : data?.endRange,
        increaseBy: modalType === 'add' ? null : data?.increaseBy,
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'UPDATE_FIELD':
                return {
                    ...state,
                    [action.field]: action.value,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    },
                };
            case 'UPDATE_FIELD_ERROR':
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.field]: action.error,
                    }
                };
            case 'CLEAR_FIELD_ERROR':
                return {
                    ...state,
                    bond: (() => {
                        const updatedErrors = { ...state.errors };
                        delete updatedErrors[action.field];
                        return {
                            ...state,
                            errors: updatedErrors,
                        };
                    }),
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (field, value) => {
        dispatch({ type: 'UPDATE_FIELD', field, value });
    };

    const handleBlur = (field, value) => {
        let error = null;
        switch (field) {
            case 'payToName':
                if (!value) {
                    error = 'Payee name is required';
                }
                break;
            default:
                break;
        }
        dispatch({ type: 'UPDATE_FIELD', field, value, error });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === 'add') {
            const speedingAmount = {
                startRange: state?.startRange,
                endRange: state?.endRange,
                increaseBy: state?.increaseBy,
            }
            axios.post(`${apiRoute}/api/StatuteFineTblSpeed`, speedingAmount)
                .then((res) => {
                    setRows([...rows, { ...res.data, id: res.data.id }])
                    toast.success('Speeding Amount added successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while adding speeding amount!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error adding speedingAmount!');
                })
                .finally(() => { setLoading(false); getRows() })
        } else {
            const speedingAmount = {
                ...data,
                startRange: state?.startRange,
                endRange: state?.endRange,
                increaseBy: state?.increaseBy,
            }
            axios.put(`${apiRoute}/api/StatuteFineTblSpeed/${data.id}`, speedingAmount)
                .then((res) => {
                    const updatedRows = rows?.map(row => {
                        if (row.id === data.id) {
                            return { ...row, ...res.data };
                        }
                        return row;
                    });
                    setRows(updatedRows);
                    toast.success('Speeding Amount updated successfully!', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    handleClose();
                })
                .catch((err) => {
                    toast.error(`Error ${err?.response?.status} while updating speeding amount!`, {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'colored',
                    });
                    console.log(err, 'Error updating speedingAmount');
                })
                .finally(() => { setLoading(false); getRows() })
        }
    }

    return (
        <>
            <Paper elevation={10}>
                <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', p: '.5vh', mb: '2vh', textAlign: 'center' }} elevation={10}>
                    <h1>
                        <strong>
                            {modalType === 'new' ? 'Add Speeding Amount' : 'Edit Speeding Amount'}
                        </strong>
                    </h1>
                </Paper>
                <form onSubmit={handleSubmit}>
                    <Box p={1}>
                        <Box width={1} flexWrap="wrap" display="flex">
                            <Box width={1} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    required
                                    sx={ts}
                                    id="startRange"
                                    label="Start Range"
                                    name="startRange"
                                    type="number"
                                    value={state.startRange}
                                    onChange={(e) => {
                                        handleFieldChange('startRange', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('startRange', e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: 0, max: 9999 }}
                                />
                            </Box>
                            <Box width={1} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="endRange"
                                    label="End Range"
                                    name="endRange"
                                    type="number"
                                    value={state.endRange}
                                    onChange={(e) => {
                                        handleFieldChange('endRange', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('endRange', e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: 0, max: 9999 }}
                                />
                            </Box>
                            <Box width={1} mt={3} pr={3}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="increaseBy"
                                    label="Increase By"
                                    name="increaseBy"
                                    type="number"
                                    value={state.increaseBy}
                                    onChange={(e) => {
                                        handleFieldChange('increaseBy', e.target.value);
                                    }}
                                    onBlur={(e) => handleBlur('increaseBy', e.target.value)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ min: 0, max: 99999999 }}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="right" gap={2} p={1}>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            type="submit"
                        >
                            Save
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'steelblue',
                                color: 'white',
                                mt: '2vh',
                            }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Box>
                </form>
            </Paper>
            {
                loading && (
                    <LinearProgress sx={{ width: '99.5%', m: '0 auto' }} />
                )
            }
        </>
    );
};

export default SpeedingAmountForm;
