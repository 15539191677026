import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, TextField, Paper, InputAdornment } from '@mui/material'
import { MdEditOff } from "react-icons/md";
import { SearchContext } from '../../../context/SearchContext';

const SearchModals = (props) => {
    const { modalType, ts, handleClose } = props;

    const { quickAssessTotal, quickNetTotal, caseId } = useContext(SearchContext);

    const navigate = useNavigate();

    const openCase = () => {
        navigate(`/view/${caseId}`);
    };

    return (
        <>
            {
                modalType === 'quickCaseView' && (
                    <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh', m: 'auto', p: '.5vh' }}>
                            <h5>
                                <strong>Case Quick View</strong>
                            </h5>
                        </Paper>
                        <Box sx={{ width: "95%", margin: "1vh auto", display: "flex", gap: "1rem" }}>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="assessed"
                                    label="Assessed"
                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(quickAssessTotal)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdEditOff />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="netTotal"
                                    label="Net Total"
                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(quickNetTotal)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdEditOff />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                            <Box mt={1.5} mb={1.5}>
                                <TextField
                                    fullWidth
                                    sx={ts}
                                    id="balance"
                                    label="Balance"
                                    value={new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(quickNetTotal)}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{ readOnly: true }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdEditOff />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'end', pb: '1vh', mr: '1rem' }}>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={openCase}>Open Case</Button>
                            <Button variant='contained' sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Close</Button>
                        </Box>
                    </Paper>
                )
            }
        </>
    )
}

export default SearchModals;