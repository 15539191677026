import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios'
import { LinearProgress, Box } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { CaseContext } from '../../../../context/CaseContext';
import { apiRoute } from '../../../../App';

const CaseTypeFeesTable = (props) => {
    const { setSelectedRow, feeRules, entityId, setFeeRules, violationDate, addFees } = props;

    const { loadingCaseTypeFees, chargeTypeFees } = useContext(CaseContext);

    const [tableText, setTableText] = useState('');

    useEffect(() => {
        if (loadingCaseTypeFees) {
            setTableText("Loading Fees...")
        } else {
            setTableText("No Valid FeeRules for Violation Date")
        };
    }, [loadingCaseTypeFees]);

    const getRow = (params) => {
        setSelectedRow(params.row);
    };

    const columns = [
        {
            field: 'code',
            headerName: 'Charge Type',
            width: 300
        },
        {
            field: 'description',
            headerName: 'Description',
            width: 500
        },
    ];

    const minHeight = chargeTypeFees.length < 1 ? '30vh' : 'inherit';

    useEffect(() => {
        if (entityId === '2') {
            axios.get(`${apiRoute}/api/FeeRuleTbls/ouachita/${violationDate}`).then(({ data }) => setFeeRules(data)).catch(e => console.log(e));
        }
    }, [entityId]);


    return (
        <Box sx={{ height: minHeight }}>
            <DataGrid
                rows={chargeTypeFees}
                columns={columns}
                disableMultipleRowSelection
                onRowClick={getRow}
                onCellDoubleClick={addFees}
                loading={loadingCaseTypeFees}
                localeText={{ noRowsLabel: tableText }}
                density="compact"
                pageSizeOptions={[25, 50, 100]}
                initialState={{
                    pagination: { paginationModel: { pageSize: 15 } },
                }}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress
                }}
                disableColumnFilter
                disableColumnSelector
                disableExporting
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
                sx={{
                    ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel": {
                        "marginTop": "1em",
                        "marginBottom": "1em"
                    },
                    maxHeight: "43.29vh",
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                    }, "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                        outline: "none !important",
                    },
                }}
            />
        </Box>
    )
}

export default CaseTypeFeesTable;