import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Box, Modal, Button, TextField, Paper, LinearProgress } from '@mui/material/';

const SuspendModal = (props) => {
    const {
        modalType,
        suspendBalanceModal,
        handleSuspendBalanceModal,
        ts,
        balance,
        formatMoney,
        reason,
        suspendCaseBalance,
        suspending,
        changesModal,
        handleChangesModal,
        updateCitation,
        setChanges,
        setModalType,
        resetState,
        from,
        setFrom,
    } = props;

    const navigate = useNavigate();

    // handle close for saveChanges modal
    const handleClose = () => {
        if (from[0] === 'nav') {
            setChanges(false);
            handleChangesModal();
            setModalType(null)
            resetState();
            navigate(from[1])
            setFrom([]);
        } else {
            setFrom([])
            setChanges(false);
            handleChangesModal();
            setModalType(null)
            resetState();
        }
    }

    /* temp variable to hold initial reason state
     only submitting 'r' and setting the state of it to allow for 
     cancelling the component and keep initial state*/
    const [r, setR] = useState(reason);

    return (
        modalType === 'balance' ?
            <Modal open={suspendBalanceModal} onClose={handleSuspendBalanceModal} sx={{ width: "30%", margin: "10vh auto" }}>
                <Paper elevation={10}>
                    <Paper className="border-radius-bottom" sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh' }} elevation={10}>
                        <h2>
                            <strong>Suspend Entire Case Balance?</strong>
                        </h2>
                    </Paper>
                    <Box sx={{ p: '1vh' }}>
                        <Box sx={{ mb: '2vh' }}>
                            <h5 sx={{ lineHeight: '3em', display: 'block', mb: '1vh' }}>Current balance: <span style={{ color: 'red' }}>{formatMoney(balance) || '$0.00'}</span></h5>
                            <TextField
                                sx={{ ...ts, mt: '1vh' }}
                                multiline
                                rows={7}
                                value={r}
                                onChange={(e) => setR(e.target.value)}
                                fullWidth
                                label="Suspended Reason"
                                variant="outlined"
                                inputProps={{ maxLength: 255 }}
                                InputLabelProps={{ shrink: true }}
                            />
                            {/*<h5>Clicking yes will confirm that you want to suspend all fees and fines attached to this Case.</h5>*/}
                        </Box>
                        <hr />
                        <Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                            <Button sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={(e) => suspendCaseBalance(e, r)} disabled={!r.length}>Yes</Button>
                            <Button sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleSuspendBalanceModal}>Cancel</Button>
                        </Box>
                    </Box>
                    {suspending && <LinearProgress />}
                </Paper>
            </Modal>
            :
            modalType === "saveChanges" &&
            <Modal open={changesModal} onClose={handleChangesModal} sx={{ width: "30%", margin: "10vh auto" }}>
                <Paper elevation={10}>
                        <Paper className="border-radius-bottom" sx={{ textAlign: 'center', backgroundColor: 'steelblue', color: 'white', p: '1vh' }} elevation={10}>
                        <h2>
                            <strong>Pending Changes</strong>
                        </h2>
                    </Paper>
                    <Box sx={{ p: '1vh' }}>
                        <Box sx={{ mb: '2vh' }}>
                            <h5 sx={{ lineHeight: '3em', display: 'block', mb: '2vh' }}>Looks like you have unsaved changes still pending!</h5>
                            <h6>Click <strong>Save</strong> to save your changes, or discard by clicking <strong>Discard Changes</strong>.</h6>
                        </Box>
                        <hr />
                        <Box sx={{ display: 'flex', justifyContent: 'right', gap: '1rem' }}>
                            <Button sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={updateCitation}>Save</Button>
                            <Button sx={{ backgroundColor: 'steelblue', color: 'white' }} onClick={handleClose}>Discard Changes</Button>
                        </Box>
                    </Box>
                    {suspending && <LinearProgress />}
                </Paper>
            </Modal>
    )
}

export default SuspendModal;