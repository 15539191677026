import React from 'react';
import { Box, Button } from '@mui/material';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { RiDeleteBinLine } from "react-icons/ri";
import { Scanner } from '@mui/icons-material';

const AddFileBox = (props) => {
    const {
        fileLimit,
        handleFileEvent,
        selectedFiles,
        removeSelectedFile,
        addFile,
        scanHandler,
        clicked,
    } = props;


    return (
        <Box sx={{ width: '50%', m: '0 auto', p: '1vh' }}>
            <Box display="flex" gap="1rem" justifyContent="center" alignItems="center">
                <Box>
                    <label htmlFor='fileUpload'>
                        <Button
                            variant='contained'
                            component='span'
                            sx={{ backgroundColor: 'steelblue', color: 'white' }}
                            disabled={fileLimit}
                        >
                            <Box mt={.25}>
                                Select Files &nbsp;
                            </Box>
                            <Box mb={.25}>
                                <AiOutlineFileAdd />
                            </Box>
                        </Button>
                    </label>
                    <input
                        id='fileUpload'
                        type='file'
                        multiple
                        accept='application/pdf, image/png, image/jpeg, application'
                        onChange={handleFileEvent}
                        disabled={fileLimit}
                        style={{ display: 'none' }}
                    />
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        component="span"
                        sx={{ backgroundColor: 'steelblue', color: 'white' }}
                        disabled={fileLimit || clicked}
                        onClick={scanHandler}
                    >
                        <Box mt={0.25}>Scan Files &nbsp;</Box>
                        <Box mb={0.25}>
                            <Scanner />
                        </Box>
                    </Button>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}>
                <Box mt={1}>
                    {/* Using id vs index to generate unique key */ }
                    {selectedFiles?.map(({id, name}) => (
                        <Box key={id} sx={{ display: 'flex', gap: '1rem', justifyContent: 'center', alignItems: 'center', mt: '1vh' }}>
                            <Box pt={1}>
                                <h6>{name}</h6>
                            </Box>
                            <Box>
                                <Button
                                    sx={{ backgroundColor: 'steelblue', color: 'white', height: '3vh' }}
                                    onClick={addFile}
                                    variant="contained"
                                >
                                    Upload File
                                </Button>
                            </Box>
                            <Box>
                                <Button
                                    sx={{ backgroundColor: 'steelblue', width: '25%', color: 'white', height: '3vh' }}
                                    onClick={removeSelectedFile}
                                    variant="contained"
                                >
                                    <RiDeleteBinLine />
                                </Button>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}

export default AddFileBox;